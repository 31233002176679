import { ExtraValidator } from '@nstep-common/utils';
import { AddReportModel } from '@nstep-internal/pages';

export class AddReportModelValidator extends ExtraValidator<AddReportModel> {
	constructor() {
		super();

		this.ruleFor('name')
			.must(name => !!name)
			.withMessage('Name is required');

		this.ruleFor('nationId')
			.must(nationId => !!nationId)
			.withMessage('Nation is required');

		this.ruleFor('description')
			.matches(/^.{2,100}$/)
			.withMessage('A valid input is required with the length between 2-100 characters!');

		this.ruleFor('availableRolesIds')
			.must(availableRolesIds => !!availableRolesIds && availableRolesIds.length > 0)
			.withMessage('Atleast one role is required');

		this.ruleFor('templateId')
			.must(templateId => !!templateId)
			.withMessage('Template is required');
	}
}