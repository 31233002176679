import { ValidationErrors } from 'fluentvalidation-ts';
import { chain, flatten } from 'lodash';
import { Component, OnInit, ViewChild } from '@angular/core';
import { createProxy, toast } from '@nstep-common/utils';
import { DropdownOption, ModalComponent } from '@nstep-common/semantic-ui';
import { BaseComponent, PagedQueryParameter, PaginationModel, TableColumn } from '@nstep-common/core';
import { EntitlementCodeModel, EntitlementCodeModelValidator } from '@nstep-internal/pages';
import { EntitlementCodeService, HeadquarterService } from '@nstep-internal/shared';

@Component({
	selector: 'app-entitlement-codes',
	templateUrl: './entitlement-codes.component.html',
})
export class EntitlementCodesComponent extends BaseComponent implements OnInit {
	@ViewChild('entitlementCodesModal') entitlementCodesModal!: ModalComponent;

	tableData: any[] = [];
	tableDataReady = false;

	hqReady = false;
	hqDropdownValues: DropdownOption[] = [];

	errors: string[] = [];

	editModal: boolean = false;

	modalIsLoading = false;

	tableColumns: TableColumn[] = [
		{ name: 'Code', key: 'code', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Is Dependent', key: 'isDependent', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Headquarter', key: 'headquarter', isHeaderCentered: true },
		{ name: 'Group', key: 'group', isHeaderCentered: true },
		{ name: 'Description', key: 'description', isHeaderCentered: true }
	];

	pagedQueryModel = new PagedQueryParameter({
		itemsPerPage: 10,
		page: 1,
		orderField: '',
		searchBy: '',
		isMultiWordSerch: false
	});

	pagination = new PaginationModel();

	validation: ValidationErrors<EntitlementCodeModel> = {};
	isValid: boolean = false;

	entitlementCode: EntitlementCodeModel = createProxy(new EntitlementCodeModel(), {
		set: () => this.validate(this.entitlementCode)
	});

	validate(value: EntitlementCodeModel): void {
		const validator = new EntitlementCodeModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	constructor(private entitlementCodeService: EntitlementCodeService, private hqService: HeadquarterService) {
		super();
	}

	ngOnInit(): void {
		this.initializeTable();

		this.hqService.getHeadquarters()
			.subscribe(r => {
				this.hqDropdownValues = chain(r)
					.map(r => new DropdownOption({
						value: r.id,
						name: r.name
					}))
					.value();

				this.hqReady = true;
			});
	}

	initializeTable(): void {
		this.subscriptions.push(
			this.entitlementCodeService
				.getEntitlementCodes(this.pagedQueryModel)
				.subscribe({
					next: response => {

						this.pagination = response.page;
						this.tableData = chain(response.results)
							.map(e => ({
								id: e.id,
								code: `${e.majorCode}.${e.minorCode}`,
								majorCode: e.majorCode.toString(),
								minorCode: e.minorCode.toString(),
								isDependent: e.isDependent,
								group: e.group,
								description: e.description,
								headquarterId: e.headquarterId,
								headquarter: e.headquarter
							}))
							.value();

						this.tableDataReady = true;
						this.modalIsLoading = false;
					},
					error: () => {
						this.tableDataReady = true;
						this.modalIsLoading = false;
					}
				})
		);
	}

	selectHq() {
		setTimeout(() => {
			this.hqDropdownValues = chain(this.hqDropdownValues)
				.map(h => (new DropdownOption({
					name: h.name,
					value: h.value
				})))
				.orderBy(h => h.name)
				.value();
		});
	}

	resetHq() {
		setTimeout(() => {
			this.hqDropdownValues = chain(this.hqDropdownValues)
				.map(h => (new DropdownOption({
					name: h.name,
					value: h.value
				})))
				.orderBy(h => h.name)
				.value();
		});
	}

	openAddCodeModal(): void {
		this.editModal = false;

		this.resetHq();
		this.entitlementCode = createProxy(new EntitlementCodeModel(), {
			set: () => this.validate(this.entitlementCode)
		});

		this.validate(this.entitlementCode);

		this.entitlementCodesModal.toggle();
	}

	search(): void {
		this.pagedQueryModel.page = 1;
		this.initializeTable();
	}

	onEditAction(item: any): void {
		this.editModal = true;

		const selectedItem: EntitlementCodeModel = {
			id: item.id,
			majorCode: item.majorCode,
			minorCode: item.minorCode,
			group: item.group,
			description: item.description,
			isDependent: item.isDependent,
			headquarterId: item.headquarterId
		};

		this.selectHq();

		this.entitlementCode = createProxy(selectedItem, {
			set: () => this.validate(this.entitlementCode)
		});

		this.validate(this.entitlementCode);

		this.entitlementCodesModal.toggle();
	}

	close(): void {
		this.entitlementCodesModal.toggle();
		this.errors = [];
	}

	save(): void {
		this.errors = [];
		this.modalIsLoading = true;

		if (this.editModal) {
			this.subscriptions.push(
				this.entitlementCodeService.updateEntitlementCode(this.entitlementCode).subscribe({
					next: () => {
						this.tableDataReady = false;
						this.tableData = [];

						this.entitlementCodeService.clearGetEntitlementCodesCache();
						this.initializeTable();

						toast('Success', `Entitlement Code ${this.entitlementCode.majorCode}.${this.entitlementCode.minorCode} successfully modified!`, 'green');
						this.entitlementCodesModal.toggle();
					},
					error: (response) => {
						this.modalIsLoading = false;

						toast('Error', `Entitlement Code ${this.entitlementCode.majorCode}.${this.entitlementCode.minorCode} could not be modified!`, 'red');
						this.errors = flatten(Object.values(response));
					}
				})
			);
		} else {
			this.subscriptions.push(
				this.entitlementCodeService.createEntitlementCode(this.entitlementCode).subscribe({
					next: () => {
						this.tableDataReady = false;
						this.tableData = [];

						this.entitlementCodeService.clearGetEntitlementCodesCache();
						this.initializeTable();

						toast('Success', `Entitlement Code ${this.entitlementCode.majorCode}.${this.entitlementCode.minorCode} saved!`, 'green');
						this.entitlementCodesModal.toggle();
					},
					error: (response) => {
						this.modalIsLoading = false;

						toast('Error', `Entitlement Code ${this.entitlementCode.majorCode}.${this.entitlementCode.minorCode} could not be saved!`, 'red');
						this.errors = flatten(Object.values(response));
					}
				})
			);
		}
	}

	pageChange(page: PaginationModel): void {
		this.pagedQueryModel.page = page.currentPage;
		this.pagedQueryModel.itemsPerPage = page.pageSize;

		this.tableDataReady = false;
		this.tableData = [];

		this.initializeTable();
	}
}
