import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthService } from '@nstep-common/core';

@Directive({
	selector: '[appAccessHider]'
})
export class AcccessHiderDirective implements OnInit {
	@Input('appAccessHider') permissions: string[] = [];
	@Input() fullAccessRequired: boolean = true;

	constructor(private el: ElementRef, private authService: AuthService) { }

	ngOnInit() {
		const hide = this.fullAccessRequired
			? !this.authService.hasAccess(...this.permissions)
			: !this.authService.hasPartialAccess(...this.permissions);
		
		if (hide) {
			this.el.nativeElement.style.display = 'none';
		}
	}
}