import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { NationWithHqDtos } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NationService {

	constructor(private apiService: ApiService) { }

	getNations(): Observable<NationWithHqDtos[]> {
		return this.apiService.get([NationWithHqDtos], 'nation');
	}
}
