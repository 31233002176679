import { JsonProperty } from "@nstep-common/utils";

export class BulkAdvanceSettings {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	headquarterId!: number;

	@JsonProperty({ type: Date })
	createDate = new Date();

	@JsonProperty({ type: Number })
	month = this.createDate.getMonth() + 2;

	@JsonProperty({ type: Number })
	issueMonth = this.createDate.getMonth() + 1;

	@JsonProperty({ type: Boolean })
	enabled = true;

	constructor(data: Partial<BulkAdvanceSettings> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class BulkAdvanceAmisSettings extends BulkAdvanceSettings {
	@JsonProperty({ type: String })
	amisCards = '';

	@JsonProperty({ type: String })
	amisSearchTerm = '';

	constructor(data: Partial<BulkAdvanceAmisSettings> | null = null) {
		if (data) {
			super(data);
			Object.assign(this, data);
		}
	}
}