import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { DropdownOption } from '@nstep-common/semantic-ui';
import { arrayHasChanged, valueHasChanged } from '@nstep-common/utils';

@Component({
	selector: 'sm-dropdown',
	template: `<ng-content></ng-content>`
})
export class DropdownComponent implements AfterViewInit, OnDestroy, OnChanges {
	jQueryElement: any;

	@Input() clearable = false;

	@Input() value: any | null = null;
	@Output() valueChange = new EventEmitter<any>();

	@Input() options: DropdownOption[] = [];

	noOptionsAvailable: DropdownOption[] = [
		new DropdownOption({
			name: 'No option available',
			disabled: true
		})
	]

	preventCycle = false;

	constructor(private elementRef: ElementRef) {
	}

	ngAfterViewInit(): void {
		if (this.jQueryElement) {
			return;
		}

		for (const option of this.options) {
			option.selected = option.value == this.value;
		}

		setTimeout(() => {
			this.jQueryElement = $(this.elementRef.nativeElement).dropdown({
				clearable: this.clearable,
				values: this.options && this.options.length ? this.options : this.noOptionsAvailable,
				showOnFocus: false,
				onChange: (value: string) => {
					const opt = this.options.find(o => o.value == value);

					if (opt && this.value != opt.value) {
						this.value = opt.value;

						setTimeout(() => {
							this.preventCycle = true;
							this.valueChange.emit(this.value);
						});
					}
				}
			});
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.jQueryElement) {
			this.ngAfterViewInit();
		}
		else {
			if (this.preventCycle) {
				this.preventCycle = false;
				return;
			}

			if (arrayHasChanged(changes['options'])) {
				this.jQueryElement.dropdown('clear', true);
				this.jQueryElement.dropdown('change values', this.options && this.options.length ? this.options : this.noOptionsAvailable);
			}

			if (valueHasChanged(changes['value']) || arrayHasChanged(changes['options'])) {
				if (!this.value) {
					this.jQueryElement.dropdown('clear', true);
				}
				else {
					this.jQueryElement.dropdown('set selected', this.value, true);
				}
			}
		}
	}

	ngOnDestroy(): void {
		this.jQueryElement?.dropdown('destroy');
	}
}