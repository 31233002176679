import { AfterViewInit, Component, ElementRef, Input, OnDestroy } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: '[sm-simple-dropdown]',
	template: '<ng-content></ng-content>'
})
export class SimpleDropdownComponent implements AfterViewInit, OnDestroy {
	jQueryElement: any;
	@Input() settings: any = {};

	constructor(private elementRef: ElementRef) {
	}

	ngAfterViewInit(): void {
		if (this.jQueryElement) {
			return;
		}

		setTimeout(() => {
			this.jQueryElement = $(this.elementRef.nativeElement).dropdown(this.settings);
		});
	}

	hide(): void {
		this.jQueryElement.dropdown('hide');
	}
	
	ngOnDestroy(): void {
		this.jQueryElement?.dropdown('destroy');
	}
}

