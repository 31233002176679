import { EventEmitter, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class LayoutService {
	layoutHasChanged = new ReplaySubject<{
		isMobile: boolean;
		isTablet: boolean;
		isSmallMonitor: boolean;
		isRegularMonitor: boolean;
		isLargeMonitor: boolean;
	}>(1);
}