import { Component } from '@angular/core';
import { BaseComponent } from '@nstep-common/core';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent {
	constructor() {
		super();
	}
}
