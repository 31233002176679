import { Injectable } from '@angular/core';
import { ApiService, PagedQueryParameter, PagedResponse, ReportSettingsDto, ReportSettingsShortDto } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { NonrationedTranasctionsDto } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ReportService {

	constructor(private apiService: ApiService) { }

	getNonRationedTransactions(startDate: string, endDate: string, model: PagedQueryParameter): Observable<PagedResponse<NonrationedTranasctionsDto>> {
		return this.apiService.getPage([NonrationedTranasctionsDto], `reports/${startDate}/${endDate}`, model);
	}

	exportNonRationedTransactionsReport(startDate: string, endDate: string): Observable<any> {
		return this.apiService.get(Any, `reports/export/${startDate}/${endDate}`, { observe: 'response', responseType: 'blob' });
	}

	getReportSettings(reportId: number): Observable<ReportSettingsDto> {
		return this.apiService.get(ReportSettingsDto, `reports/${reportId}`);
	}

	getReports(model: PagedQueryParameter): Observable<PagedResponse<ReportSettingsShortDto>> {
		return this.apiService.getPage([ReportSettingsShortDto], 'reports', model);
	}
}