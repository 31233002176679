<div class="ui modal"
	[class.larger]="isLarger"
	[class.fullscreen]="isFullscreen"
	[class.tiny]="isTiny"
	[class.big]="isBig"
	[class.mini]="isMini"
	[class.small]="isSmall">

	<div *ngIf="isLoading"
		class="ui active inverted dimmer">
		<div class="ui text loader">{{loadingText}}</div>
	</div>

	<i *ngIf="!isLoading && !noClose"
		class="close icon"
		[ngClass]="closeIconColor"
		(click)="this.closeEvent.emit()"></i>

	<div class="header">
		<ng-content select="[header]"></ng-content>
	</div>
	<div class="content"
		[class.scrolling]="isScrolling">
		<ng-content select="[content]"></ng-content>
	</div>
	<div class="actions">
		<ng-content select="[actions]"></ng-content>
	</div>
</div>