import { JsonProperty } from "@nstep-common/utils";

export class PasswordSettingsModel {
	@JsonProperty({ type: Number })
	MaximumLength!: number;
	
	@JsonProperty({ type: Number })
	MinimumLength!: number;

	@JsonProperty({ type: Number })
	MinimumLowercaseCharacters!: number;

	@JsonProperty({ type: Number })
	MinimumNumericCharacters!: number;
	
	@JsonProperty({ type: Number })
	MinimumUppercaseCharacters!: number;

	@JsonProperty({ type: Number })
	MinimumSpecialCharacters!: number;

	@JsonProperty({ type: String })
	SpecialCharacters!: string;
}