import { JsonProperty } from "@nstep-common/utils";

export class PagedQueryParameter {
	page!: number;
	itemsPerPage!: number;
	orderField!: string | null;
	searchBy!: string | null;
	isMultiWordSerch!: boolean;
	sortList!: SortDescriptor[];
	filterList!: FilterDescriptor[];

	constructor(data: Partial<PagedQueryParameter> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class SortDescriptor {
	columnName!: string;
	sortDirection: SortDirection = SortDirection.ASC;

	constructor(data: Partial<SortDescriptor> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export enum SortDirection {
	ASC = 'ASC',
	DESC = 'DESC'
}


export class FilterDescriptor {
	columnName!: string;
	agregateOperator!: FilterOperator;
	filterValue: FilterValue[] = [];

	constructor(data: Partial<FilterDescriptor> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export enum BaiscFilterSection {
	First = 0,
	Second = 1
}

export enum FilterOperator {
	AND = 'AND',
	OR = 'OR'
}

export enum FilterComparisonOperator {
	IsLessThan = 'IsLessThan',
	IsLessThanOrEqualTo = 'IsLessThanOrEqualTo',
	IsEqualTo = 'IsEqualTo',
	IsNotEqualTo = 'IsNotEqualTo',
	IsGreaterThanOrEqualTo = 'IsGreaterThanOrEqualTo',
	IsGreaterThan = 'IsGreaterThan',
	StartsWith = 'StartsWith',
	EndsWith = 'EndsWith',
	Contains = 'Contains',
	DoesNotContain = 'DoesNotContain',
	IsNull = 'IsNull',
	IsNotNull = 'IsNotNull',
	IsEmpty = 'IsEmpty',
	IsNotEmpty = 'IsNotEmpty'
}

export class FilterValue {
	operator!: FilterComparisonOperator;
	value!: string;

	constructor(data: Partial<FilterValue> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class PaginationModel {

	@JsonProperty({ type: Number, name: "TotalCount" })
	totalCount!: number;

	@JsonProperty({ type: Number, name: "PageSize" })
	pageSize!: number;

	@JsonProperty({ type: Number, name: "CurrentPage" })
	currentPage!: number;

	@JsonProperty({ type: Number, name: "TotalPages" })
	totalPages!: number;

	constructor(data: Partial<PaginationModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class PagedResponse<TModel> {
	page!: PaginationModel;
	results: TModel[] = [];

	constructor(data: Partial<PagedResponse<TModel>> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}
