import { Component } from '@angular/core';
import { BaseComponent } from '@nstep-common/core';
import { EntitlementMatrixView, EntitlementMatrixViewChangeModel } from '@nstep-internal/pages';

@Component({
	selector: 'app-entitlement-matrix',
	templateUrl: './entitlement-matrix.component.html',
})
export class EntitlementMatrixComponent extends BaseComponent {
	dataPropagation = new EntitlementMatrixViewChangeModel({
		entitlementMatrixView: EntitlementMatrixView.Main
	});

	onViewChange(model: EntitlementMatrixViewChangeModel): void {
		this.dataPropagation = { ...model };
	}
}
