import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';

import { AuthInterceptor, CoreModule, UpdateDateHttpInterceptor } from '@nstep-common/core';
import { SemanticUiModule } from '@nstep-common/semantic-ui';
import { Enviroment } from '@nstep-common/utils';

import { environment } from '@nstep-internal/environments/environment';
import { AppComponent } from '@nstep-internal/app/app.component';
import { routes } from '@nstep-internal/app/app.routes';

import { LayoutComponent, SearchComponent } from '@nstep-internal/shared';

import {
	AdminAdjustmentsComponent,
	ArchiveComponent,
	BalanceHistoryComponent,
	EntitlementCodesComponent,
	MainMatrixComponent,
	EntitlementTypesComponent,
	EntitlementUnitsComponent,
	ExportComponent,
	ImportHistoryComponent,
	LoginComponent,
	NonRationedTransactionsComponent,
	PosDevicesComponent,
	PosLocationsComponent,
	RolesComponent,
	EntitlementMatrixComponent,
	MatrixAdministrationComponent,
	TypeAssociationModalComponent,
	UpdateTransactionsComponent,
	UsersComponent,
	ClientsComponent,
	BulkAdvanceSettingsComponent,
	ReportingComponent,
	ReportsManagementComponent
} from '@nstep-internal/pages';

@NgModule({
	declarations: [
		LayoutComponent,
		AppComponent,
		LoginComponent,
		PosDevicesComponent,
		PosLocationsComponent,
		UsersComponent,
		ClientsComponent,
		RolesComponent,
		BalanceHistoryComponent,
		EntitlementCodesComponent,
		EntitlementTypesComponent,
		EntitlementUnitsComponent,
		NonRationedTransactionsComponent,
		AdminAdjustmentsComponent,
		UpdateTransactionsComponent,
		ExportComponent,
		ImportHistoryComponent,
		EntitlementMatrixComponent,
		TypeAssociationModalComponent,
		MainMatrixComponent,
		MatrixAdministrationComponent,
		ArchiveComponent,
		SearchComponent,
		BulkAdvanceSettingsComponent,
		ReportingComponent,
		ReportsManagementComponent
	],
	imports: [
		FormsModule,
		CommonModule,
		BrowserModule,
		CoreModule,
		SemanticUiModule,
		RouterModule.forRoot(routes),
		JwtModule.forRoot({
			config: {
				tokenGetter: () => {
					return localStorage.getItem('accessToken');
				}
			}
		})
	],
	exports: [
		SearchComponent
	],
	providers: [
		{
			provide: APP_BASE_HREF,
			useValue: environment.angularRouterBaseHref
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UpdateDateHttpInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: Enviroment,
			useValue: environment
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
