import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseComponent, PagedQueryParameter, PaginationModel, TableColumn } from '@nstep-common/core';
import { SystemService } from '@nstep-internal/shared';
import { chain } from 'lodash';

@Component({
	selector: 'app-import-history',
	templateUrl: './import-history.component.html'
})
export class ImportHistoryComponent extends BaseComponent implements OnInit {

	tableData: any[] = [];
	tableDataReady = true;

	pagedQueryModel = new PagedQueryParameter({
		itemsPerPage: 10,
		page: 1,
		orderField: '',
		searchBy: '',
		isMultiWordSerch: false
	});

	pagination = new PaginationModel();

	tableColumns: TableColumn[] = [
		{ name: 'Import File Name', key: 'fileName', sortAsc: true, isCellCentered: true, isHeaderCentered: true },
		{ name: 'Date', key: 'date', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Time', key: 'time', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Type', key: 'type', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Size (kB)', key: 'size', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Status', key: 'displayStatus', isCellCentered: true, isHeaderCentered: true },
		{ name: 'Duration (ms)', key: 'duration', isCellCentered: true, isHeaderCentered: true }
	];

	constructor(private systemConfigurationService: SystemService) {
		super();
	}

	ngOnInit(): void {
		this.initializeTableData();
	}

	initializeTableData(): void {
		this.tableDataReady = false;
		this.tableData = [];

		this.subscriptions.push(
			this.systemConfigurationService.getImportHistory(this.pagedQueryModel).subscribe({
				next: response => {

					this.pagination = response.page;
					this.tableData = chain(response.results)
						.map(e => ({
							id: e.id,
							fileName: e.fileName,
							date: formatDate(e.date, 'dd/MM/yyyy', 'en'),
							time: formatDate(e.date, 'h:mm a', 'en'),
							size: e.size,
							isManual: e.isManual,
							type: e.isManual ? 'Manually' : 'Automatically',
							status: e.status,
							displayStatus: e.status ? 'Completed' : 'Failed',
							duration: e.duration
						}))
						.value();

					this.tableDataReady = true;
				},
				error: () => {
					this.tableDataReady = true;
				}
			})
		);
	}

	pageChange(page: PaginationModel): void {
		this.pagedQueryModel.page = page.currentPage;
		this.pagedQueryModel.itemsPerPage = page.pageSize;

		this.initializeTableData();
	}
}
