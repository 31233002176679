import { JsonProperty } from "@nstep-common/utils";

export class FilterOption {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	value!: string
}

export class FilterOptions {
	@JsonProperty({ type: Number, array: true })
	years!: number[];

	@JsonProperty({ type: FilterOption, array: true })
	posLocations!: FilterOption[];

	@JsonProperty({ type: FilterOption, array: true })
	posOperators!: FilterOption[];

	@JsonProperty({ type: FilterOption, array: true })
	entitlementTypes!: FilterOption[];

	@JsonProperty({ type: FilterOption, array: true })
	entitlementCodes!: FilterOption[];
}