import { Component, HostListener, ViewChild } from '@angular/core';
import { AccountService, AuthService, BaseComponent, LayoutService, PasswordSettingsModel, SettingsService, UserSetingsValidator, UserSettings } from '@nstep-common/core';
import { ModalComponent } from '@nstep-common/semantic-ui';
import { createProxy } from '@nstep-common/utils';
import { flatten } from 'lodash';
import { EMPTY, catchError, finalize, forkJoin, tap } from 'rxjs';

@Component({
	selector: 'app-base-layout',
	templateUrl: './base-layout.component.html'
})
export class BaseLayoutComponent extends BaseComponent {
	@ViewChild('settingsModal') settingsModal!: ModalComponent;

	protected isMobile = true;
	protected isTablet = true;
	protected isSmallMonitor = true;
	protected isRegularMonitor = true;
	protected isLargeMonitor = true;

	protected currentUser: string | null = null;

	settings: UserSettings = createProxy(new UserSettings(), {
		set: () => {
			if (!this.passwordSettings) {
				return;
			}

			const validator = new UserSetingsValidator(this.passwordSettings);

			this.validations = validator.validate(this.settings);
			this.isValid = !Object.keys(this.validations).length;
		}
	});


	passwordSettings: PasswordSettingsModel | null = null;

	validations = {};
	isValid = false;
	askFor2F = false;
	waitingForServer = false;

	errors: string[] = [];

	constructor(private layoutService: LayoutService,
		private accountService: AccountService,
		private authService: AuthService,
		private settingsService: SettingsService) {
		super();

		this.currentUser = this.authService.JWT != null ? this.authService.JWT.name : null;

		this.initSettings();
	}

	@HostListener('window:load', ['$event'])
	@HostListener('window:resize', ['$event'])
	onResize(event: Event) {
		const width = (event.currentTarget as Window).innerWidth;

		this.isMobile = width < 768;
		this.isTablet = width >= 768 && width <= 991;
		this.isSmallMonitor = width >= 992 && width <= 1920;
		this.isRegularMonitor = width > 1920 && width <= 2560;
		this.isLargeMonitor = width > 2560;

		this.layoutService.layoutHasChanged.next({
			isMobile: this.isMobile,
			isTablet: this.isTablet,
			isSmallMonitor: this.isSmallMonitor,
			isRegularMonitor: this.isRegularMonitor,
			isLargeMonitor: this.isLargeMonitor
		});
	}

	initSettings(): void {
		this.validations = {};
		this.isValid = false;
		this.askFor2F = false;

		this.errors = [];

		const getContact = this.accountService.getContact()
			.pipe(tap(r => {
				this.settings.email = r.email;
				this.settings.telephone = r.telephone;
			}));

		const getTwoFactor = this.accountService.getTwoFactor()
			.pipe(tap(r => {
				this.settings.wasTwoFactorEnabled = r.isTwoFactorEnabled;
				this.settings.isTwoFactorEnabled = r.isTwoFactorEnabled;
				this.settings.hasAuthenticator = r.hasAuthenticator;
			}));

		const getAuthenticator = this.accountService.getAuthenticator()
			.pipe(tap(r => {
				this.settings.authenticatorUri = r.authenticatorUri;
				this.settings.sharedKey = r.sharedKey.toUpperCase().split(' ');
			}));

		const getPasswordSettings = this.settingsService.getPasswordSettings()
			.pipe(tap(settings => {
				this.passwordSettings = settings;
			}));

		this.subscriptions.push(forkJoin([getContact, getTwoFactor, getAuthenticator, getPasswordSettings]).subscribe());
	}

	resetAuthenticator(): void {
		this.subscriptions.push(this.accountService.resetAuthenticator()
			.subscribe(r => {
				this.settings.authenticatorUri = r.authenticatorUri;
				this.settings.sharedKey = r.sharedKey.toUpperCase().split(' ');
			}));
	}

	saveSettings(): void {
		this.errors = [];

		this.askFor2F = !this.settings.authenticatorCode &&
			(this.settings.isTwoFactorEnabled != this.settings.wasTwoFactorEnabled ||
				(!!this.settings.newPassword && this.settings.wasTwoFactorEnabled));

		if (this.askFor2F) {
			return;
		}

		this.waitingForServer = true;

		const body: any = {
			email: this.settings.email,
			telephone: this.settings.telephone,
			authenticatorCode: this.settings.authenticatorCode,
			newPassword: this.settings.newPassword
		};

		if (this.settings.isTwoFactorEnabled != this.settings.wasTwoFactorEnabled) {
			body.isTwoFactorEnabled = this.settings.isTwoFactorEnabled;
		}

		this.subscriptions.push(this.accountService
			.updateSettings(body)
			.pipe(
				tap(() => {
					if (this.settings.authenticatorCode || this.settings.newPassword) {
						this.authService.logOut();
					}
					else {
						this.closeSaveSettings();
					}
				}),
				catchError(errors => {
					this.errors = flatten(Object.values(errors));
					return EMPTY;
				}),
				finalize(() => {
					this.settings.authenticatorCode = null;
					this.waitingForServer = false;
				})
			)
			.subscribe());
	}

	closeSaveSettings(): void {
		this.settingsModal.toggle();
		this.initSettings();
	}

	logout(): void {
		this.authService.logOut();
	}
}
