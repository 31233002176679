import { JsonProperty } from "@nstep-common/utils";

export class EntitlementCodeModel {
	id!: number;
	majorCode!: string | null;
	minorCode!: string | null;
	group!: string | null;
	description!: string;
	isDependent!: boolean;
	headquarterId!: number;
}

export class CodeDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	majorCode!: number;

	@JsonProperty({ type: Number })
	minorCode!: number;

	@JsonProperty({ type: String })
	fullCode: string = '';

	@JsonProperty({ type: String })
	headquarter: string = '';

	@JsonProperty({ type: Number })
	headquarterId!: number;

	@JsonProperty({ type: String })
	group: string | null = null;

	@JsonProperty({ type: String })
	description: string = '';

	@JsonProperty({ type: Boolean })
	isDependent!: boolean;

	constructor(data: Partial<CodeDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class HeadquarterDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name: string = '';
}