import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent, TableColumn } from '@nstep-common/core';
import { valueHasChanged, toast } from '@nstep-common/utils';
import { BulkAdvanceAmisSettings, BulkAdvanceSettings } from '@nstep-internal/pages';
import { BulkAdvanceSettingsService } from '@nstep-internal/shared';
import { chain, flatten, uniq } from 'lodash';
import { EMPTY, Observable, catchError, finalize, tap } from 'rxjs';

@Component({
	selector: 'app-bulk-advance-settings',
	templateUrl: './bulk-advance-settings.component.html'
})
export class BulkAdvanceSettingsComponent extends BaseComponent implements OnChanges {
	@Input() headquarterId: number | null = null;

	tableData: BulkAdvanceSettings[] = [];
	tableDataReady = false;

	tableColumns: TableColumn[] = [
		{ name: 'Issue On', key: 'issueMonth', isHeaderCentered: true, isCellCentered: true },
		{ name: 'For Month', key: 'month', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Enabled', key: 'enabled', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Actions', isHeaderCentered: true, isCellCentered: true }
	];

	errors = [];

	showAmis = false;
	selectedSetting: BulkAdvanceAmisSettings | null = null;

	userValue: string = '';
	amisCards: string[] = [];

	backupTableData: BulkAdvanceSettings[] = [];
	byValue = true;

	constructor(private bulkAdvanceSettingsService: BulkAdvanceSettingsService) {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!valueHasChanged(changes['headquarterId'])) {
			return;
		}


		this.errors = [];

		this.showAmis = false;
		this.selectedSetting = null;

		this.userValue = '';
		this.amisCards = [];

		this.backupTableData = [];
		this.byValue = true;

		this.subscriptions.push(this.initTable().subscribe());
	}

	changeTab(showAmis: boolean) {
		this.showAmis = showAmis;

		this.tableData = [];
		this.subscriptions.push(this.initTable().subscribe());
	}

	initTable(): Observable<any> {
		this.tableDataReady = false;

		const getBulkAdvanceSettings = !this.showAmis
			? this.bulkAdvanceSettingsService.getBulkAdvanceSettings(this.headquarterId!)
			: this.bulkAdvanceSettingsService.getBulkAdvanceAmisSettings(this.headquarterId!);

		return getBulkAdvanceSettings.pipe(
			tap(data => {
				this.tableData = chain(data)
					.map(item => {
						item.createDate = new Date(item.createDate);
						return item;
					})
					.value();
			}),
			finalize(() => {
				this.tableDataReady = true;
			})
		);

	}

	addSetting(): void {
		this.tableData = [
			!this.showAmis
				? new BulkAdvanceSettings({
					headquarterId: this.headquarterId!
				})
				: new BulkAdvanceAmisSettings({
					headquarterId: this.headquarterId!
				}),
			...this.tableData
		];
	}

	toggleSetting(setting: BulkAdvanceSettings): void {
		setting.enabled = !setting.enabled;
		this.saveSetting(setting);
	}

	saveSetting(setting: any): void {
		this.errors = [];

		const upsertClient$ = setting.id
			? !this.showAmis
				? this.bulkAdvanceSettingsService.updateBulkAdvanceSettings(setting)
				: this.bulkAdvanceSettingsService.updateBulkAdvanceAmisSettings(setting)
			: !this.showAmis
				? this.bulkAdvanceSettingsService.createBulkAdvanceSettings(setting)
				: this.bulkAdvanceSettingsService.createBulkAdvanceAmisSettings(setting);

		//this.editCards(null);

		const updateClient = upsertClient$
			.pipe(
				tap(resp => {
					setting.id = resp.id;
					toast('Success', `Setting has been ${setting.id ? 'updated' : 'created'}.`, 'green');
				}),
				catchError(errors => {
					this.errors = flatten(Object.values(errors));
					toast('Error', 'Setting could not be updated.', 'red');

					return EMPTY;
				})
			)
			.subscribe();

		this.subscriptions.push(updateClient);
	}

	deleteSetting(setting: BulkAdvanceSettings, index: number) {
		const deleteClient$ = (!this.showAmis
			? this.bulkAdvanceSettingsService.deleteBulkAdvanceSettings(setting.id)
			: this.bulkAdvanceSettingsService.deleteBulkAdvanceAmisSettings(setting.id))
			.pipe(
				tap(() => {
					this.tableData.splice(index, 1);
					this.tableData = [... this.tableData];

					toast('Success', 'Setting has been deleted.', 'green');
				}),
				catchError(() => {
					toast('Error', 'Setting could not be deleted.', 'red');

					return EMPTY;
				})
			)
			.subscribe();

		this.subscriptions.push(deleteClient$);
	}

	resetSetting() {
		this.errors = [];
	}

	editCards(setting: BulkAdvanceAmisSettings | null) {
		if (setting) {
			this.selectedSetting = setting;

			this.backupTableData = this.tableData;
			this.tableData = [setting];

			this.amisCards = setting.amisCards ? setting.amisCards.split(';') : [];
		}
		else {
			this.selectedSetting = null;

			this.tableData = [...this.backupTableData];
			this.backupTableData = [];

			this.amisCards = [];
			this.errors = [];
		}

		this.searchTypeChanged(true);
	}

	searchTypeChanged(init = false) {
		if (!this.selectedSetting) {
			return;
		}

		if (init) {
			if (this.selectedSetting.amisSearchTerm) {
				this.byValue = false;

				this.userValue = this.selectedSetting.amisSearchTerm;
				this.searchCards();
			}
			else {
				this.byValue = true;

				this.userValue = '';
				this.amisCards = this.selectedSetting.amisCards ? this.selectedSetting.amisCards.split(';') : [];
			}
		}
		else {
			if (!this.byValue && this.selectedSetting.amisSearchTerm) {
				this.userValue = this.selectedSetting.amisSearchTerm;
				this.searchCards();
			}
			else {
				this.userValue = '';

				if (!this.amisCards.length) {
					this.amisCards = this.amisCards = this.selectedSetting.amisCards ? this.selectedSetting.amisCards.split(';') : [];
				}
			}
		}
	}

	addCard() {
		if (!this.userValue || !this.selectedSetting) {
			return;
		}

		this.amisCards = uniq([... this.amisCards, this.userValue]);
		this.userValue = '';

		this.selectedSetting.amisCards = this.amisCards.join(';');
		this.selectedSetting.amisSearchTerm = '';
	}

	removeCard(card: string) {
		if (!this.selectedSetting) {
			return;
		}

		this.amisCards = this.amisCards.filter(c => c != card);
		this.selectedSetting.amisCards = this.amisCards.join(';');
	}

	searchCards() {
		if (!this.userValue || !this.selectedSetting) {
			return;
		}

		this.selectedSetting.amisCards = '';

		const searchCards$ = this.bulkAdvanceSettingsService.searchAmisCards(this.headquarterId!, this.userValue)
			.pipe(
				tap(r => {
					const amisCards = r as string[];

					if (amisCards.length) {
						this.amisCards = amisCards;
						this.selectedSetting!.amisSearchTerm = this.userValue;
					}
				}),
				catchError(errors => {
					this.amisCards = [];

					this.errors = flatten(Object.values(errors));
					toast('Error', 'Could not search using the specified term.', 'red');

					return EMPTY;
				})
			)
			.subscribe();

		this.subscriptions.push(searchCards$);
	}
}
