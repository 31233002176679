<h3 class="ui header">
	<i class="sync icon"></i>
	<div class="content">
		Update Transactions
		<div class="sub header">System</div>
	</div>
</h3>
<br />

<div app-loading [enabled]="loading" class="ui form">
	<div class="field">
		<label>AMIS Card Number:</label>
		<div class="fields">
			<div class="four wide field">
				<div class="field" app-field [error]="validation | path: 'amisCardNumber'">
					<input type="text" [(ngModel)]="updateModel.amisCardNumber">
				</div>
			</div>
			<div class="field">
				<button class="ui fluid primary button" [disabled]="!isValid" (click)="onUpdateTransactions()">Update</button>
			</div>
		</div>
	</div>
</div>