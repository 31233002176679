import { PasswordSettingsModel, ResetPasswordModel } from "@nstep-common/core";
import { ExtraValidator } from "@nstep-common/utils";

export class ResetPasswordModelValidator extends ExtraValidator<ResetPasswordModel> {
	constructor(settings: PasswordSettingsModel) {
		super()

		this.ruleFor('userName')
			.notEmpty()
			.withMessage('The user name is required.');

		this.ruleFor('oldPassword')
			.notEmpty()
			.withMessage('The old password is required.');

		this.ruleFor('newPassword')
			.matches(new RegExp('^.{' + settings.MinimumLength + ',' + settings.MaximumLength + '}$'))
			.withMessage(`Length must be between ${settings.MinimumLength}-${settings.MaximumLength} characters!`)
			.matches(new RegExp('^(?:[^\\d]*\\d){' + settings.MinimumNumericCharacters + ',}'))
			.withMessage(`Minimum digits required: ${settings.MinimumNumericCharacters}`)
			.matches(new RegExp('^(?:[^a-z]*[a-z]){' + settings.MinimumLowercaseCharacters + ',}'))
			.withMessage(`Minimum lowercase letters: ${settings.MinimumLowercaseCharacters}`)
			.matches(new RegExp('^(?:[^A-Z]*[A-Z]){' + settings.MinimumUppercaseCharacters + ',}'))
			.withMessage(`Minimum uppercase letters: ${settings.MinimumUppercaseCharacters}`)
			.matches(new RegExp('^(?:[^' + settings.SpecialCharacters.replace('-', '\\-') + ']*[' + settings.SpecialCharacters.replace('-', '\\-') + ']){' + settings.MinimumSpecialCharacters + ',}'))
			.withMessage(`Minimum special characters: ${settings.MinimumSpecialCharacters} from ${settings.SpecialCharacters}`)
			.must((_, model) => model.oldPassword !== model.newPassword)
			.withMessage('The current password must be different than the old password.');

		this.ruleFor('confirmPassword').must((_, model) => model.newPassword === model.confirmPassword)
			.withMessage('The new password and confirm password must match.');
	}
}