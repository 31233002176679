import { Any, JsonProperty } from "@nstep-common/utils";
import 'reflect-metadata';

export class CardBalanceOnline {
	@JsonProperty({ type: Number })
	amisCardId!: number;

	@JsonProperty({ type: String })
	amisCardNumber!: string;

	@JsonProperty({ type: Number })
	headquarterId!: number;

	@JsonProperty({ type: String })
	headquarter!: string;

	@JsonProperty({ type: Number })
	entitlementCodeId!: number;

	@JsonProperty({ type: String })
	entitlementCode!: string;

	@JsonProperty({ type: Number })
	entitlementTypeId!: number;

	@JsonProperty({ type: String })
	entitlementType!: string;

	@JsonProperty({ type: Number, nullable: true })
	measurementUnitId: number | null = null;

	@JsonProperty({ type: String })
	measurementUnit!: string;

	@JsonProperty({ type: Boolean })
	rationed!: boolean;

	@JsonProperty({ type: String })
	balance!: string;

	@JsonProperty({ type: Any, nullable: true })
	issuance: any | null = null;
}

export class GroupedCardBalanceOnline {
	@JsonProperty({ type: String })
	cardNumber!: string;

	@JsonProperty({ type: CardBalanceOnline, array: true })
	entitlements!: CardBalanceOnline[];
}

export class UserCardBalanceModel {
	@JsonProperty({ type: GroupedCardBalanceOnline, array: true })
	items: GroupedCardBalanceOnline[] = [];

	@JsonProperty({ type: String })
	expirationDate!: string;

	@JsonProperty({ type: Number })
	expirationDays!: number;
}
