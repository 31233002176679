import { Injectable } from '@angular/core';
import { ApiService, PagedQueryParameter, PagedResponse, ReportSettingsShortDto } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { ManageReportSettingsDto } from '@nstep-internal/pages';
import { Observable, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ReportsManagementService {

	constructor(private apiService: ApiService) { }

	getDefaultData(): Observable<any> {
		return this.apiService.get(Any, 'reports/manage/default-data');
	}

	getReports(model: PagedQueryParameter): Observable<PagedResponse<ReportSettingsShortDto>> {
		return this.apiService.getPage([ReportSettingsShortDto], 'reports/manage', model);
	}

	createReportSettings(model: ManageReportSettingsDto): Observable<ReportSettingsShortDto> {
		return this.apiService.post(ReportSettingsShortDto, 'reports/manage', model)
			.pipe(tap(() => this.invalidateCache()));
	}

	updateReporSettings(reportId: number, model: ManageReportSettingsDto): Observable<ReportSettingsShortDto> {
		return this.apiService.put(ReportSettingsShortDto, `reports/manage/${reportId}`, model)
			.pipe(tap(() => this.invalidateCache()));
	}

	copyReport(reportId: number): Observable<ReportSettingsShortDto> {
		return this.apiService.post(ReportSettingsShortDto, `reports/manage/${reportId}`)
			.pipe(tap(() => this.invalidateCache()));
	}

	deleteReport(reportId: number): Observable<any> {
		return this.apiService.deleteNoContent(`reports/manage/${reportId}`)
			.pipe(tap(() => this.invalidateCache()));
	}

	getReportSettings(reportId: number): Observable<any> {
		return this.apiService.get(Any, `reports/manage/${reportId}`);
	}

	//getReportSettings(reportId: number): Observable<ReportSettingsDto> {
	//	return this.apiService.get(ReportSettingsDto, `reports/manage/${reportId}`);
	//}

	invalidateCache() {
		this.apiService.invalidateCache('reports/manage');
	}
}
