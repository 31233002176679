import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent, ContextMenuAction, ContextMenuItem } from '@nstep-common/core';

@Component({
	selector: 'app-context-menu',
	templateUrl: './context-menu.component.html',
})
export class ContextMenuComponent extends BaseComponent {
	@Input() contextMenuItems: ContextMenuItem[] = [];
	@Input() contextMenuStyle: {} | null = null;
	@Output() contextMenuItemClick = new EventEmitter<ContextMenuAction>();

	onContextMenuItemClick(item: ContextMenuItem): void {
		this.contextMenuItemClick.emit(item.actionValue!);
	}
}
