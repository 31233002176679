export enum AppSource {
	Public = 'Public',
	Internal = 'Internal'
}

export class Enviroment {
	target!: string;
	disableConsoleLogs!: boolean;
	logRequests!: boolean;
	expandedLogRequests!: boolean;
	angularRouterBaseHref!: string;
	appName!: AppSource;
	apiUrl!: string; // use relative or full url
}