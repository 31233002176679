import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class UpdateDateHttpInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		if (req.method === 'POST' || req.method === 'PUT') {
			UpdateDateHttpInterceptor.formatDates(req.body);
		}

		return next.handle(req);
	}

	static formatDates(body: any) {
		if (body === null || body === undefined) {
			return body;
		}

		if (typeof body !== 'object') {
			return body;
		}

		for (const key of Object.keys(body)) {
			const value = body[key];
			if (value instanceof Date) {
				body[key] = body[key].toISOString();

			} else if (typeof value === 'object') {
				this.formatDates(value);
			}
		}
	}
}