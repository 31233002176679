import { Pipe, PipeTransform } from '@angular/core';
import { chain } from 'lodash';

@Pipe({
	name: 'searchText'
})
export class SearchTextPipe implements PipeTransform {
	transform(items: any[], keyword: string, prop: string | null = null): any[] {
		if (!items) {
			return [];
		}

		if (!keyword) {
			return items;
		}

		keyword = keyword.toLowerCase();

		const filteredItems = chain(items)
			.filter(i => (prop ? i[prop] : i).toLowerCase().indexOf(keyword.toLowerCase()) != - 1)
			.value();

		return filteredItems;
	}
}