import { chain, concat, includes } from "lodash";

export function fullOuterJoin<T, U, TResult>(
	leftArray: T[],
	rightArray: U[],
	leftSelector: (item: T) => any,
	rightSelector: (item: U) => any,
	mapping: (itemLeft: T | null, itemRight: U | null) => TResult
): TResult[] {
	const temp: {
		leftIndex: number,
		match: boolean,
		rightIndex: number
	}[] = [];

	for (let i = 0; i < leftArray.length; i++) {
		for (let j = 0; j < rightArray.length; j++) {
			temp.push({
				leftIndex: i,
				match: leftSelector(leftArray[i]) == rightSelector(rightArray[j]),
				rightIndex: j
			});
		}
	}

	const middle = chain(temp)
		.filter(t => t.match)
		.map(t => mapping(leftArray[t.leftIndex], rightArray[t.rightIndex]))
		.value();

	const leftMatch = chain(temp)
		.filter(t => t.match)
		.map(t => t.leftIndex)
		.uniq()
		.value();

	const left = chain(temp)
		.filter(t => !t.match && !includes(leftMatch, t.leftIndex))
		.map(t => t.leftIndex)
		.uniq()
		.map(idx => mapping(leftArray[idx], null))
		.value();

	const rightMatch = chain(temp)
		.filter(t => t.match)
		.map(t => t.rightIndex)
		.uniq()
		.value();

	const right = chain(temp)
		.filter(t => !t.match && !includes(rightMatch, t.rightIndex))
		.map(t => t.rightIndex)
		.uniq()
		.map(idx => mapping(null, rightArray[idx]))
		.value();

	return concat(middle, left, right);
}

export function nthOrDefault<T>(arr: T[], index: number, defaultValue: T | null = null): T | null {
	if (index < 0) {
		index = arr.length + index; // Convert negative index to positive index
	}

	return arr[index] !== undefined ? arr[index] : defaultValue;
}
