export class DropdownOption {
	name!: string | null;
	value!: any;
	description!: string | null;
	descriptionVertical: boolean = false;
	icon!: string | null;
	disabled: boolean = false;
	selected: boolean = false;

	constructor(data: Partial<DropdownOption> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}