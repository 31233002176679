import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
	template: ''
})
export class BaseComponent implements OnDestroy {
	protected subscriptions: Subscription[] = [];

	ngOnDestroy(): void {
		this.subscriptions.forEach((item) => item.unsubscribe());
	}

	hasKeys(value: any): boolean {
		if (!value) {
			return false;
		}

		return Array.isArray(value) ? value.length > 0 : Object.keys(value).length > 0;
	}
}
