import { Injectable } from '@angular/core';
import { ApiService, PagedQueryParameter, PagedResponse } from '@nstep-common/core';
import { EntitlementUnitCreateDto, EntitlementUnitModel } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EntitlementUnitService {

	constructor(private apiService: ApiService) { }

	getEntitlementUnits(model: PagedQueryParameter): Observable<PagedResponse<EntitlementUnitModel>> {
		return this.apiService.getPage([EntitlementUnitModel], 'entitlement-unit', model);
	}

	getEnabledEntitlementUnits(): Observable<EntitlementUnitModel[]> {
		return this.apiService.get([EntitlementUnitModel], 'entitlement-unit/enabled');
	}

	createEntitlementUnit(model: EntitlementUnitModel): Observable<EntitlementUnitModel> {
		return this.apiService.post(EntitlementUnitModel, 'entitlement-unit', new EntitlementUnitCreateDto({ name: model.name, disabled: model.disabled }));
	}

	updateEntitlementUnit(model: EntitlementUnitModel): Observable<EntitlementUnitModel> {
		return this.apiService.put(EntitlementUnitModel, `entitlement-unit/${model.id}`, new EntitlementUnitCreateDto({ name: model.name, disabled: model.disabled }));
	}

	enableEntitlementUnit(id: number): Observable<any> {
		return this.apiService.patchNoContent(`entitlement-unit/${id}`);
	}

	disableEntitlementUnit(id: number): Observable<any> {
		return this.apiService.deleteNoContent(`entitlement-unit/${id}`);
	}

	clearGetEntitlementUnitsCache(): void {
		this.apiService.invalidateCache('entitlement-unit');
	}
}
