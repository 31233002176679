import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { HeadquarterDto, HeadquarterExtendedModel } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HeadquarterService {

	constructor(private apiService: ApiService) { }

	getHeadquarters(): Observable<HeadquarterDto[]> {
		return this.apiService.get([HeadquarterDto], 'hq');
	}

	getHeadquartersEx(): Observable<HeadquarterExtendedModel[]> {
		return this.apiService.get([HeadquarterExtendedModel], 'hq/ex');
	}

	clearHeadquartersExCache(): void {
		this.apiService.invalidateCache('hq/ex');
	}
}
