export class ContextMenuItem {
	actionName: string | null = null;
	actionValue: ContextMenuAction | null = null;

	constructor(data: Partial<ContextMenuItem> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export enum ContextMenuAction {
	Copy = 0,
	Delete = 1
}