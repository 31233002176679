import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { RoleModel, RoleWithClaimsDto } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RolesService {

	constructor(private apiService: ApiService) { }

	getRoles(): Observable<RoleWithClaimsDto[]> {
		return this.apiService.get([RoleWithClaimsDto], 'roles');
	}

	getRolePermissions(id: number): Observable<any> {
		return this.apiService.get(Any, `roles/${id}/permissions`);
	}

	getRoleUsers(id: number): Observable<any> {
		return this.apiService.get(Any, `roles/${id}/users`);
	}

	createRole(model: RoleModel): Observable<RoleWithClaimsDto> {
		return this.apiService.post(RoleWithClaimsDto, 'roles', model);
	}

	updateRole(model: RoleModel): Observable<RoleWithClaimsDto> {
		return this.apiService.put(RoleWithClaimsDto, 'roles', model);
	}

	enableRole(id: number): Observable<any> {
		return this.apiService.putNoContent(`roles/${id}/enable`);
	}

	disableRole(id: number): Observable<any> {
		return this.apiService.putNoContent(`roles/${id}/disable`);
	}

	deleteRole(id: number): Observable<any> {
		return this.apiService.deleteNoContent(`roles/${id}`);
	}

	getInternalApiPermissions(): Observable<any> {
		return this.apiService.get(Any, 'permissions/internal-api');
	}

	getPublicApiPermissions(): Observable<any> {
		return this.apiService.get(Any, 'permissions/public-api');
	}

	getClientApiPermissions(): Observable<any> {
		return this.apiService.get(Any, 'permissions/client-api');
	}

	getInternalWebAppPermissions(): Observable<any> {
		return this.apiService.get(Any, 'permissions/internal-web-app');
	}

	getPublicWebAppPermissions(): Observable<any> {
		return this.apiService.get(Any, 'permissions/public-web-app');
	}

	invalidateCache() {
		this.apiService.invalidateCache('roles');
	}
}
