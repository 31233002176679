import { HttpClient } from "@angular/common/http";
import { AppSource, Enviroment, LogEntry, LogLevel, LogModel, LogPublisher } from "@nstep-common/utils";

import { Observable } from "rxjs";

export class LogWebApi extends LogPublisher {
	private apiUrl: string;

	constructor(private httpClient: HttpClient,
		private environment: Enviroment) {
		super();

		let url = this.environment.apiUrl;

		if (!url.startsWith('http')) {
			const hostUrl = window.location.protocol + '//' + window.location.host;
			url = `${hostUrl}${this.environment.apiUrl}`;
		}

		this.apiUrl = url;
	}

	log(record: LogEntry): Observable<any> {

		const model = new LogModel();

		model.methodName = this.getCaller(5);
		model.parameters = record.buildLogString();
		model.deviceIdentifier = record.source == AppSource.Public ? 'NSTEP Public Web App' : 'NSTEP Internal Web App';
		model.timeStamp = record.entryDate;
		model.isError = record.level === LogLevel.Error;

		return this.httpClient.post<LogModel>(`${this.apiUrl}/account/post-logs`, model);
	}
}