import { ExtraValidator } from '@nstep-common/utils';
import { TypeAssociationModel } from '@nstep-internal/pages';

export class TypeAssociationModelValidator extends ExtraValidator<TypeAssociationModel> {
	constructor() {
		super();

		this.ruleFor('entitlementTypeId')
			.must(entitlementTypeId => !!entitlementTypeId)
			.withMessage('Entitlement type is required');

		this.ruleFor('issuanceTypeId')
			.must(issuanceTypeId => !!issuanceTypeId)
			.withMessage('Issuance type is required')
			.when(model => (!model.fromAdministerView || model.isEdit) && model.rationingTypeId === 1);

		this.ruleFor('periodCycle')
			.must(periodCycle => !!periodCycle)
			.withMessage('Value is required')
			.inclusiveBetween(0, 999)
			.withMessage('Value must be a positive number of maximum 3 digits')
			.when(model => (!model.fromAdministerView || model.isEdit) && model.rationingTypeId === 1 && [1, 2].includes(model.issuanceTypeId!));

		this.ruleFor('periodsUntilExpiry')
			.must(periodsUntilExpiry => !!periodsUntilExpiry)
			.withMessage('Value is required')
			.inclusiveBetween(0, 999)
			.withMessage('Value must be a positive number of maximum 3 digits')
			.when(model => (!model.fromAdministerView || model.isEdit) && model.rationingTypeId === 1 && model.issuanceTypeId === 1);

		this.ruleFor('periodsIssuedUpfront')
			.must(periodsIssuedUpfront => !!periodsIssuedUpfront)
			.withMessage('Value is required')
			.inclusiveBetween(0, 999)
			.withMessage('Value must be a positive number of maximum 3 digits')
			.when(model => (!model.fromAdministerView || model.isEdit) && model.rationingTypeId === 1 && model.issuanceTypeId === 1);

		this.ruleFor('measurementUnitId')
			.must(measurementUnitId => !!measurementUnitId)
			.withMessage('Value is required')
			.when(model => model.rationingTypeId === 1);

		this.ruleFor('unitDescription')
			.must(unitDescription => !!unitDescription)
			.withMessage('Unit description is required')
			.matches(/^.{2,100}$/)
			.withMessage('A valid input is required with the length between 2-100 characters!')
			.when(model => model.rationingTypeId === 1);
	}
}