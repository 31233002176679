export class UserSettings {
	email: string | null = null;
	telephone: string | null = null;

	newPassword!: string;
	repeatPassword: string | null = null;

	wasTwoFactorEnabled!: boolean;
	isTwoFactorEnabled!: boolean;
	hasAuthenticator!: boolean;

	authenticatorUri!: string;
	sharedKey: string[] = [];
	authenticatorCode: string | null = null;

	constructor(data: Partial<UserSettings> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}
