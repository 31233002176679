import { merge } from "lodash";

import { JsonErrorFormatter } from "./json-error";
import { JsonMapperSettings } from "./json-settings";

export class JsonSerializer {
	private settings = new JsonMapperSettings();
	private errorFormatter: JsonErrorFormatter;

	constructor(settings: JsonMapperSettings) {
		merge(this.settings, settings);
		this.errorFormatter = new JsonErrorFormatter(this.settings.showErrorTips);
	}

	// private toJsonValue<T>(value: any, type: JsonType, expectedType: JsonType): any | undefined {
	// 	if (type.isAny) {
	// 		return value;
	// 	}

	// 	if (!type.isPrimitive) {
	// 		return undefined;
	// 	}

	// 	if (expectedType.name == 'date' ? type.name != 'object' : type.name != expectedType.name) {
	// 		return undefined;
	// 	}

	// 	switch (expectedType.name) {
	// 		case 'string':
	// 		case 'number':
	// 		case 'boolean':
	// 			{
	// 				return value;
	// 			}
	// 		case 'date':
	// 			{
	// 				return value.toISOString();
	// 			}
	// 	}

	// 	return undefined;
	// }

	// private _serializeArray<T>(source: any[], type: { new(): T }, errors: any[]): any[] | undefined {
	// 	if (type == Any) {
	// 		return source;
	// 	}

	// 	if (source === undefined) {
	// 		return undefined;
	// 	}

	// 	const target: any[] = [];

	// 	const isArray = this.isArray(type);
	// 	const isPrimitive = this.isPrimitive(type);

	// 	for (let i = 0; i < source.length; i++) {
	// 		const item = source[i];
	// 		const itemType = this.getTypeName(item);

	// 		if (isArray) {
	// 			throw 'Feature not supported';
	// 		}
	// 		else if (!isPrimitive) {
	// 			const sourceKeyErrors = {};

	// 			const obj = this._serializeObject(item, type, sourceKeyErrors);
	// 			if (!isEmpty(sourceKeyErrors)) {
	// 				errors[i] = sourceKeyErrors;
	// 			}

	// 			target.push(obj);
	// 		}
	// 		else {
	// 			const newValue = this.toJsonValue(item, type);

	// 			if (newValue === undefined) {
	// 				errors[i] = `Primitive type missmatch or invalid value (source property is [${i}]: ${itemType} = ${itemType == 'string' ? '\'' + item + '\'' : item})`;
	// 				continue;
	// 			}

	// 			target.push(newValue);
	// 		}
	// 	}

	// 	return target;
	// }

	// private _serializeObject<T>(source: any, type: { new(): T }, errors: any): any {
	// 	if (type == Any) {
	// 		return source;
	// 	}

	// 	const target: any = {};

	// 	if (this.isArray(type) || this.isPrimitive(type)) {
	// 		throw "Provided type is not an object type.";
	// 	}

	// 	for (const sourceKey of Object.keys(source)) {
	// 		const sourcePropMetadata = getPropertyMetdata(source, sourceKey);

	// 		if (!sourcePropMetadata) {
	// 			continue;
	// 		}

	// 		const sourceDesignType = getPropertyType(source, sourceKey);
	// 		const sourcePropType = sourcePropMetadata.type ?? sourceDesignType;

	// 		const targetKey = sourcePropMetadata.name ?? sourceKey;
	// 		const value = (source as any)[sourceKey];
	// 		const valueType = this.getTypeName(value);

	// 		if (this.isArray(sourceDesignType)) {
	// 			const sourceKeyErrors: any[] = [];
	// 			target[targetKey] = this._serializeArray(value, sourcePropType, sourceKeyErrors);

	// 			if (!isEmpty(sourceKeyErrors)) {
	// 				let itemType = this.getTypeName(sourcePropType);
	// 				if (this.isPrimitive(sourcePropType)) {
	// 					itemType = itemType.toLowerCase();
	// 				}

	// 				errors[`${targetKey}: ${itemType}[]`] = sourceKeyErrors;
	// 			}
	// 		}
	// 		else if (!this.isPrimitive(sourcePropType)) {
	// 			const sourceKeyErrors = {};
	// 			target[targetKey] = this._serializeObject(value, sourcePropType, sourceKeyErrors);

	// 			if (!isEmpty(sourceKeyErrors)) {
	// 				errors[`${targetKey}: ${this.getTypeName(sourcePropType)}`] = sourceKeyErrors;
	// 			}
	// 		}
	// 		else {
	// 			const newValue = this.toJsonValue(value, sourcePropType);

	// 			if (newValue === undefined) {
	// 				errors[`${targetKey}: ${this.getTypeName(sourcePropType)}`] = `Primitive type missmatch or invalid value (source property is ${sourceKey}: ${valueType} = ${valueType == 'string' ? '\'' + value + '\'' : value})`;
	// 				continue;
	// 			}

	// 			target[targetKey] = newValue;
	// 		}
	// 	}

	// 	return target;
	// }
}