import { Injectable } from '@angular/core';
import { ApiService, PagedQueryParameter, PagedResponse } from '@nstep-common/core';
import { PosDeviceModel } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PosDeviceService {

	constructor(private apiService: ApiService) { }

	getPosDevices(model: PagedQueryParameter): Observable<PagedResponse<PosDeviceModel>> {
		return this.apiService.getPage([PosDeviceModel], 'pos-device', model);
	}

	createPosDevice(model: PosDeviceModel): Observable<PosDeviceModel> {
		return this.apiService.post(PosDeviceModel, 'pos-device', model);
	}

	updatePosDevice(model: PosDeviceModel): Observable<PosDeviceModel> {
		return this.apiService.put(PosDeviceModel, 'pos-device', model);
	}

	clearGetPosDevicesCache(): void {
		this.apiService.invalidateCache('pos-device');
	}
}
