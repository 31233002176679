import { JsonProperty } from "@nstep-common/utils";

export class RoleDto {
	@JsonProperty({ type: Number })
	roleId!: number;

	@JsonProperty({ type: String, nullable: true })
	roleName: string | null = null;

	@JsonProperty({ type: String, nullable: true })
	description: string | null = null;

	constructor(data: Partial<RoleDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}