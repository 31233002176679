<ng-container *ngIf="errors.length">
	<div class="ui divider"></div>
	<div class="ui icon error message">
		<i class="exclamation icon"></i>
		<div class="content">
			<div class="header">There were some errors with your submission</div>
			<ul class="list">
				<li *ngFor="let error of errors">{{ error }}</li>
			</ul>
		</div>
	</div>
</ng-container>