export class PageItem {
	header: any | null = null;
	items: PageItem[] = [];
}

export class TreeNode {
	key: string | null = null;
	collapsed = true;
	level = 0;
	data: any | null = null;
	children: TreeNode[] = [];
}