import { ExtraValidator } from '@nstep-common/utils';
import { EntitlementUnitModel } from '@nstep-internal/pages';

export class EntitlementUnitModelValidator extends ExtraValidator<EntitlementUnitModel> {
	constructor() {
		super();

		this.ruleFor('name')
			.must(name => !!name)
			.withMessage('Unit name is required')
			.notEmpty()
			.matches(/^[0-9a-zA-Z_()\-\* ]{3,20}$/)
			.withMessage('A valid input is required with the length between 3-20 characters!');
	}
}