import 'reflect-metadata';
import { merge } from "lodash";

enum JsonMetadataKey {
	JSON_PROPERTY
}

export function JsonProperty<T>(metadata: Partial<JsonPropertyMetadata<T>> | null = null): any {
	metadata = merge(new JsonPropertyMetadata(), metadata);
	return Reflect.metadata(JsonMetadataKey.JSON_PROPERTY, metadata);
}

export class JsonReflect<T> {
	designType!: any;
	metadata!: JsonPropertyMetadata<T>;

	constructor(data: Partial<JsonReflect<T>>) {
		Object.assign(this, data);
	}

	static fromTarget<T>(target: any, key: string): JsonReflect<T> {
		return new JsonReflect({
			designType: JsonReflect.getPropertyType(target, key),
			metadata: JsonReflect.getPropertyMetdata(target, key)
		});
	}

	private static getPropertyType(target: any, key: string): any {
		return Reflect.getMetadata('design:type', target, key);
	}

	private static getPropertyMetdata<T>(target: any, key: string): JsonPropertyMetadata<T> {
		return Reflect.getMetadata(JsonMetadataKey.JSON_PROPERTY, target, key);
	}
}

export class JsonMetadata<T> {
	name: string | null = null;
	type: { new(): T } | null = null;
	nullable: boolean | null = null;
	undefinable: boolean | null = null;

	constructor(data: Partial<JsonMetadata<T>> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class JsonPropertyMetadata<T> extends JsonMetadata<T> {
	array: boolean | null = null

	constructor(data: Partial<JsonPropertyMetadata<T>> | null = null) {
		super();

		if (data) {
			Object.assign(this, data);
		}
	}
}