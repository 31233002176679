import { isEmpty, merge } from "lodash";

import { JsonErrorFormatter, JsonError } from "./json-error";
import { JsonMetadata, JsonPropertyMetadata, JsonReflect } from "./json-reflect";
import { JsonMapperSettings } from "./json-settings";
import { JsonType, JsonTypeCategory } from "./json-type";

export class JsonDeserializer {
	private settings = new JsonMapperSettings();
	private errorFormatter: JsonErrorFormatter;

	constructor(settings: JsonMapperSettings) {
		merge(this.settings, settings);
		this.errorFormatter = new JsonErrorFormatter(this.settings.showErrorTips);
	}

	deserializeObject<T>(source: any, type: { new(): T }, errors: any): T {
		const targetType = JsonType.fromType(type);

		if (targetType.isAny) {
			return source;
		}

		if (!targetType.isObject) {
			throw this.errorFormatter.get(JsonError.INVALID_PROVIDED_TYPE);
		}

		const target = new type() as any;

		for (const targetKey of Object.keys(target)) {
			const jsonReflect = JsonReflect.fromTarget(target, targetKey);

			if (!jsonReflect.metadata) {
				continue;
			}

			const targetPropDesignType = JsonType.fromType(jsonReflect.designType, targetKey);
			const targetPropType = jsonReflect.metadata.type ? JsonType.fromType(jsonReflect.metadata.type, targetKey) : targetPropDesignType;

			const sourceKey = jsonReflect.metadata.name ?? targetKey;
			const sourcePropValue = source[sourceKey];
			const sourcePropType = JsonType.fromValue(sourcePropValue, sourceKey);

			if (sourcePropValue === undefined) {
				if (this.settings.ignoreMissingProperties) {
					continue;
				}

				errors[targetKey] = this.errorFormatter.get(JsonError.MISSING_SOURCE_PROPERTY, targetPropType, sourcePropType);

				if (this.settings.stopOnError) break;
				else continue;
			}

			const result = this.deserializeValue(sourcePropValue, sourcePropType, targetPropDesignType, targetPropType, jsonReflect);

			if (result.passable === true) {
				target[targetKey] = result.value;
			}

			if (result.error) {
				errors[targetKey] = result.error;

				if (this.settings.stopOnError) break;
				else continue;
			}
		}

		return target;
	}

	deserializeArray<T>(source: any[], metadata: JsonMetadata<T>, errors: any[]): T[] {
		const target = [];

		if (!metadata.type) {
			throw this.errorFormatter.get(JsonError.UNKNOWN_ARRAY_ITEM_TYPE);
		}

		for (let i = 0; i < source.length; i++) {
			const sourceValue = source[i];
			const sourceValueType = JsonType.fromValue(sourceValue, `[${i}]`);

			if (sourceValueType.isAny) {
				target.push(sourceValue);
				continue;
			}

			const itemType = JsonType.fromType(metadata.type, `[${i}]`);

			const jsonReflect = new JsonReflect<T>({
				designType: metadata.type,
				metadata: new JsonPropertyMetadata(metadata)
			});

			const result = this.deserializeValue(sourceValue, sourceValueType, itemType, itemType, jsonReflect);

			if (result.passable === true) {
				target.push(result.value);
			}

			if (result.error) {
				errors[i] = result.error;

				if (this.settings.stopOnError) break;
				else continue;
			}
		}

		return target;
	}

	private deserializeValue<T>(value: any, valueType: JsonType, targetDesignType: JsonType, targetType: JsonType, jsonReflect: JsonReflect<T>): { value?: any, error?: any, passable?: boolean } {
		if (value == null || value == undefined) {
			const isPassable = value == null
				? jsonReflect.metadata.nullable || this.settings.allowNullValues
				: jsonReflect.metadata.undefinable || this.settings.allowUndefinedValues;

			const isIgnored = value == null
				? this.settings.ignoreNullValues
				: this.settings.ignoreUndefinedValues;

			const err = value == null
				? JsonError.NULL_VALUE_NOT_ALLOWED
				: JsonError.UNDEFINED_VALUE_NOT_ALLOWED;

			if (isPassable) {
				return { value, passable: true };
			}
			else if (isIgnored) {
				return {};
			}
			else {
				return { error: this.errorFormatter.get(err, targetType, valueType) };
			}
		}

		const targetTypeCategory = targetDesignType.isArray ? JsonTypeCategory.ARRAY : targetType.category;

		if (!targetDesignType.isGenericObject && !targetDesignType.isArray && targetDesignType.name != targetType.name) {
			return { error: this.errorFormatter.get(JsonError.PROPERTY_ATTR_TYPE_MISSMATCH, targetType, targetDesignType) };
		}

		switch (targetTypeCategory) {
			case JsonTypeCategory.PRIMITIVE: {
				const result = this.parsePrimitive(value, valueType, targetType);

				if (result.error) {
					return { error: this.errorFormatter.get(result.error, targetType, valueType) };
				}

				return { value: result.value, passable: true };
			}
			case JsonTypeCategory.OBJECT: {
				if (targetType.isGenericObject) {
					return { error: this.errorFormatter.get(JsonError.UNKNOWN_PROPERTY_TYPE, targetType, valueType) };
				}

				if (!valueType.isGenericObject) {
					return { error: this.errorFormatter.get(JsonError.OBJECT_TYPE_MISSMATCH, targetType, valueType) };
				}

				const errors = {};
				const obj = this.deserializeObject(value, jsonReflect.metadata.type ?? jsonReflect.designType, errors);

				return { value: obj, error: !isEmpty(errors) ? errors : undefined, passable: true };
			}
			case JsonTypeCategory.ARRAY: {
				if (!jsonReflect.metadata.type) {
					return { error: this.errorFormatter.get(JsonError.UNKNOWN_ARRAY_ITEM_TYPE, targetType) };
				}

				if (!jsonReflect.metadata.array) {
					return { error: this.errorFormatter.get(JsonError.INVALID_ARRAY_TYPE, targetType) };
				}

				if (!valueType.isArray) {
					return { error: this.errorFormatter.get(JsonError.ARRAY_TYPE_MISSMATCH, null, valueType) };
				}

				const errors: any[] = [];
				const arr = this.deserializeArray(value, jsonReflect.metadata, errors);

				return { value: arr, error: !isEmpty(errors) ? errors : undefined, passable: true };
			}
		}
	}

	private parsePrimitive<T>(value: any, type: JsonType, expectedType: JsonType): { value?: T, error?: JsonError } {
		if (type.isAny) {
			return { value };
		}

		if (!type.isPrimitive) {
			return { error: JsonError.INVALID_PRIMITIVE_VALUE };
		}

		if (!this.settings.ignorePrimitiveTypeChecks && (expectedType.name == 'date' ? type.name != 'string' : expectedType.name != type.name)) {
			return { error: JsonError.PRIMITIVE_TYPE_MISSMATCH };
		}

		switch (expectedType.name) {
			case 'boolean':
				{
					const isTrue = value == true;
					const isFalse = value == false;

					if (!isTrue && !isFalse) {
						return { error: JsonError.INVALID_PRIMITIVE_VALUE };
					}


					return { value: value as unknown as T };
				}
			case 'number':
				{
					let newValue = Number.parseFloat(value);

					if (Number.isNaN(newValue)) {
						return { error: JsonError.INVALID_PRIMITIVE_VALUE };
					}

					return { value: value as unknown as T };
				}
			case 'string':
				{
					return { value };
				}
			case 'date':
				{
					let newValue = value.toUpperCase().endsWith('Z') ? value.substring(0, value.length - 1) : value;
					var parsedValue = Date.parse(newValue);

					if (Number.isNaN(parsedValue)) {
						return { error: JsonError.INVALID_PRIMITIVE_VALUE };
					}

					return { value: new Date(newValue) as unknown as T };
				}
		}

		return { error: JsonError.INVALID_PRIMITIVE_VALUE };
	}
}