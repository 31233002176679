import { Injectable } from '@angular/core';
import { ApiService, PagedQueryParameter, PagedResponse } from '@nstep-common/core';
import { CodeDto, EntitlementCodeModel } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EntitlementCodeService {

	constructor(private apiService: ApiService) { }

	getEntitlementCodes(model: PagedQueryParameter): Observable<PagedResponse<CodeDto>> {
		return this.apiService.getPage([CodeDto], 'entitlement-code', model);
	}

	createEntitlementCode(model: EntitlementCodeModel): Observable<CodeDto> {
		const postModel = new CodeDto({
			id: model.id,
			majorCode: Number(model.majorCode),
			minorCode: Number(model.minorCode),
			group: model.group,
			description: model.description,
			isDependent: model.isDependent,
			headquarterId: model.headquarterId
		});

		return this.apiService.post(CodeDto, 'entitlement-code', postModel);
	}

	updateEntitlementCode(model: EntitlementCodeModel): Observable<CodeDto> {
		const putModel = new CodeDto({
			id: model.id,
			majorCode: Number(model.majorCode),
			minorCode: Number(model.minorCode),
			group: model.group,
			description: model.description,
			isDependent: model.isDependent,
			headquarterId: model.headquarterId
		});

		return this.apiService.put(CodeDto, `entitlement-code/${model.id}`, putModel);
	}

	clearGetEntitlementCodesCache(): void {
		this.apiService.invalidateCache('entitlement-code');
	}
}
