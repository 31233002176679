export class JsonMapperResult<T> {
	value!: T;
	errors = {};

	constructor(data: Partial<JsonMapperResult<T>> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	hasErrors(): boolean {
		return Object.keys(this.errors).length > 0;
	}
}

export class JsonArrayMapperResult<T> {
	values!: T[];
	errors: {}[] = [];

	constructor(data: Partial<JsonArrayMapperResult<T>> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	hasErrors(index: number | null = null): boolean {
		return this.errors.filter((error, i) => (index === null || index === i) && Object.keys(error).length > 0).length > 0;
	}
}