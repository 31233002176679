import { LogEntry } from "projects/common/utils/logger/log-entry";
import { Observable } from "rxjs";

export abstract class LogPublisher {
	abstract log(record: LogEntry): Observable<boolean>;

	protected getCaller(index: number, ignoreLinks = true): string {
		const err = new Error();
		const frames = err.stack ? err.stack.split('\n') : [];

		for (let i = index; i > 0; i--) {
			const caller = frames[i].trim().split(' ')[1];

			if ((!ignoreLinks || !caller.startsWith('http')) && !caller.startsWith('Object.next')) {
				return caller;
			}
		}

		return 'N/A';
	}
	
	protected removePrototype(arg: any) {
		if (typeof arg === 'object' && !arg.length) {
			const tempObj = JSON.parse(JSON.stringify(arg));
			tempObj.__proto__ = null;

			return tempObj;
		}

		return arg;
	}
}