import { ValidationErrors, Validator } from 'fluentvalidation-ts';

import { chain, debounce, difference } from 'lodash';
import { flattenObject, createProxy} from '@nstep-common/utils';

export class ExtraValidator<TModel> extends Validator<TModel> {
	constructor() {
		super();
	}

	process(model: TModel, callback: (extra: string[]) => void): ValidationErrors<TModel> {
		const validationErrors = this.validate(model);

		const accessed: string[] = [];
		
		const update = debounce(() => {
			const flatErrors = flattenObject(validationErrors);

			const extra = chain(difference(Object.keys(flatErrors), accessed))
				.map(k => flatErrors[k])
				.value();

			callback(extra);
		}, 300);

		const proxy = createProxy(validationErrors, {
			get: (target: any, key, path) => {
				if (typeof target[key] == 'string' && !accessed.includes(path)) {
					accessed.push(path);
					update();
				}

				return target[key];
			}
		});

		return proxy;
	}
}