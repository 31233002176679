import { Pipe, PipeTransform } from '@angular/core';
import { startCase } from 'lodash';

@Pipe({
	name: 'startCase'
})
export class StartCasePipe implements PipeTransform {

	transform(value: string): string {
		if (value === undefined || value === null || value === '') {
			return '-';
		}

		return startCase(value);
	}
}