import { SimpleChange } from "@angular/core";
import { isArray } from "lodash";

export function valueHasChanged(change: SimpleChange, notEmpy = false): boolean {
	return change && change.currentValue != change.previousValue && (!notEmpy || !!change.currentValue);
}

export function arrayHasChanged(change: SimpleChange): boolean {
	if (!change) {
		return false;
	}

	let hasChanged = (!change.previousValue && change.currentValue) || (change.previousValue && !change.currentValue);

	if (!hasChanged && isArray(change.currentValue) && isArray(change.previousValue)) {
		hasChanged = change.currentValue.length != change.previousValue.length;

		if (!hasChanged) {
			hasChanged = JSON.stringify(change.currentValue) != JSON.stringify(change.previousValue);
		}
	}

	return hasChanged;
}
