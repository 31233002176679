import { FilterDescriptor, SortDescriptor } from "@nstep-common/core";

export class ExportDataRequest {
	reportName: string = '';
	columns: string[] = [];
	sortList: SortDescriptor[] = [];
	filterList: FilterDescriptor[] = [];

	constructor(data: Partial<ExportDataRequest> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}