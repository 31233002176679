import { Injectable } from '@angular/core';
import { LogPublishersService } from '@nstep-common/core';
import { Enviroment } from "@nstep-common/utils";
import { LogEntry, LogLevel, LogPublisher } from "@nstep-common/utils";

@Injectable({
	providedIn: 'root'
})
export class LogService {

	level: LogLevel = LogLevel.All;
	logWithDate: boolean = true;

	publishers: LogPublisher[];

	constructor(private publishersService: LogPublishersService, private environment: Enviroment) {
		this.publishers = this.publishersService.publishers;
	}

	debug(msg: string, ...optionalParams: any[]) {
		this.writeToLog(msg, LogLevel.Debug, optionalParams);
	}

	info(msg: string, ...optionalParams: any[]) {
		this.writeToLog(msg, LogLevel.Info, optionalParams);
	}

	warn(msg: string, ...optionalParams: any[]) {
		this.writeToLog(msg, LogLevel.Warn, optionalParams);
	}

	error(msg: string, ...optionalParams: any[]) {
		this.writeToLog(msg, LogLevel.Error, optionalParams);
	}

	fatal(msg: string, ...optionalParams: any[]) {
		this.writeToLog(msg, LogLevel.Fatal, optionalParams);
	}

	log(msg: string, ...optionalParams: any[]) {
		this.writeToLog(msg, LogLevel.All, optionalParams);
	}

	private writeToLog(msg: string, level: LogLevel, params: any[]) {
		if (this.shouldLog(level)) {
			let entry: LogEntry = new LogEntry();
			entry.message = msg;
			entry.level = level;
			entry.extraInfo = params;
			entry.logWithDate = this.logWithDate;
			entry.source = this.environment.appName;

			for (let logger of this.publishers) {
				logger.log(entry).subscribe();
			}
		}
	}

	private shouldLog(level: LogLevel): boolean {
		let ret: boolean = false;
		if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
			ret = true;
		}
		return ret;
	}
}
