export enum EntitlementMatrixView {
	Main = 1,
	MatrixAdministration = 2,
}

export class EntitlementMatrixViewChangeModel {
	entitlementMatrixView: EntitlementMatrixView | null = null;
	headquarterId: number | null = null;
	headquarterName: string | null = null;
	selectedMatrixVersionId: number | null = null;
	matrixVersionIsActive: boolean = false;
	matrixVersionExpiresToday: boolean = false;
	generateDependentRations: boolean = false;

	constructor(data: Partial<EntitlementMatrixViewChangeModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}