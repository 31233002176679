import { JsonProperty } from '@nstep-common/utils';

export class PosLocation {
	id!: number;
	name!: string | null;
	isChecked!: boolean;
}

export class PosLocationGroup {
	headquarterId!: number;
	headquarterName!: string | null;
	isChecked!: boolean;
	posLocations!: PosLocation[];
}

export class Headquarter {
	id!: number;
	name!: string | null;
	isChecked!: boolean;
	disabled!: boolean;
}

export class ExportModel {
	from!: Date | null;
	to!: Date | null;
}

export class HeadquarterMatrixVersionModel {
	headquarterId!: number;
	matrixVersionId!: number | null;
}

export class MatrixVersionHistoryModel {
	@JsonProperty({ type: Number })
	headquarterId!: number;

	@JsonProperty({ type: Number })
	matrixVersionId!: number;

	@JsonProperty({ type: String })
	matrixVersionDisplayName!: string;
}

export class ImportExportHistoryDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Boolean })
	isImport!: boolean;

	@JsonProperty({ type: Date })
	date!: Date;

	@JsonProperty({ type: Number, nullable: true })
	size!: number | null;

	@JsonProperty({ type: Boolean })
	isManual!: boolean;

	@JsonProperty({ type: Boolean })
	status!: boolean;

	@JsonProperty({ type: Number })
	duration!: number;

	@JsonProperty({ type: String })
	fileName!: string;

	@JsonProperty({ type: Boolean })
	isTransaction!: boolean;
}

export class NationDtoModel {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	nationName: string = '';
}

export class PosLocationDtoModel {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name: string = '';

	@JsonProperty({ type: String, nullable: true })
	buildingNumber: string | null = null;

	@JsonProperty({ type: String })
	streetName: string = '';

	@JsonProperty({ type: String })
	city: string = '';

	@JsonProperty({ type: String })
	postalCode: string = '';

	@JsonProperty({ type: String, nullable: true })
	telephone: string | null = null;

	@JsonProperty({ type: Date })
	startDate!: Date;

	@JsonProperty({ type: Date })
	endDate!: Date;

	@JsonProperty({ type: Number })
	headquarterId!: number;

	@JsonProperty({ type: NationDtoModel, nullable: true })
	nation: NationDtoModel | null = null;
}

export class MatrixVersionHistoryDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Date })
	startDate!: Date;

	@JsonProperty({ type: Date })
	endDate!: Date;

	@JsonProperty({ type: String, nullable: true })
	version!: string | null;

	constructor(data: Partial<MatrixVersionHistoryDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class HeadquarterExtendedModel {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name: string = '';

	@JsonProperty({ type: Number })
	nationId!: number;

	@JsonProperty({ type: Boolean })
	generateDependentRations!: boolean;

	@JsonProperty({ type: PosLocationDtoModel, array: true })
	posLocations: PosLocationDtoModel[] = [];

	@JsonProperty({ type: MatrixVersionHistoryDto, nullable: true })
	matrixVersion: MatrixVersionHistoryDto | null = null;

	@JsonProperty({ type: MatrixVersionHistoryDto, array: true })
	futureMatrixes: MatrixVersionHistoryDto[] = [];
}

