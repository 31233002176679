import { JsonProperty } from "@nstep-common/utils";

export class TypeAssociationModel {
	entitlementTypeId: number | null = null;
	rationingTypeId: number | null = null;
	issuanceTypeId: number | null = null;
	isBuyable!: boolean;
	isNotProRated!: boolean;
	periodCycle: number | null = null;
	periodsUntilExpiry: number | null = null;
	periodsIssuedUpfront: number | null = null;
	typeAssociationStartDate: Date | null = null;
	typeAssociationEndDate: Date | null = null;
	measurementUnitId: number | null = null;
	unitDescription: string | null = null;

	fromAdministerView: boolean = false;
	isEdit: boolean = false;

	constructor(data: Partial<TypeAssociationModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class UpdateAssociationResponseModel {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	entitlementTypeId!: number;

	@JsonProperty({ type: Number, nullable: true })
	entitlementMeasurementUnitAssociationId: number | null = null;
}