import { JsonProperty } from "@nstep-common/utils";

export class NonrationedTranasctionsDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	amisCardId!: number;

	@JsonProperty({ type: String })
	amisCardNumber!: string;

	@JsonProperty({ type: Number })
	operatorAmisCardId!: number;

	@JsonProperty({ type: String })
	operatorAmisCardNumber!: string;

	@JsonProperty({ type: Number })
	entitlementTypeId!: number;

	@JsonProperty({ type: String })
	entitlementTypeName!: string;

	@JsonProperty({ type: Number })
	posLocationId!: number;

	@JsonProperty({ type: String })
	posLocationName!: string;

	@JsonProperty({ type: Boolean })
	validity!: boolean;

	@JsonProperty({ type: Date })
	timestamp!: Date;
}

export class NonRationedTransactionModel {
	from!: Date | null;
	to!: Date | null;
}
