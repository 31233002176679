import { Injectable } from '@angular/core';
import { ApiService, PagedQueryParameter, PagedResponse } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import {
	EntitlementAssignmentExtendedDto,
	EntitlementAssignmentModel,
	EntitlementCodeDto,
	EntitlementMatrixVersionHistoryDto,
	EntitlementTypeAssociationDto,
	MatrixAdministrationCodeDto,
	MatrixDeltaModel
} from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AssignmentService {

	periodCycles = [{ Value: 7, Text: 'Weekly' }, { Value: 31, Text: 'Monthly' }, { Value: 365, Text: 'Yearly' }];

	constructor(private apiService: ApiService) { }

	getCodes(matrixVersionId: number, model: PagedQueryParameter): Observable<PagedResponse<EntitlementCodeDto>> {
		return this.apiService.getPage([EntitlementCodeDto], `entitlement-assignment/${matrixVersionId}/codes`, model);
	}

	getTypes(matrixVersionId: number, model: PagedQueryParameter): Observable<PagedResponse<EntitlementTypeAssociationDto>> {
		return this.apiService.getPage([EntitlementTypeAssociationDto], `entitlement-assignment/${matrixVersionId}/types`, model);
	}

	getUnassignedCodes(matrixVersionId: number): Observable<EntitlementCodeDto[]> {
		return this.apiService.get([EntitlementCodeDto], `entitlement-assignment/${matrixVersionId}/codes/unassigned`);
	}

	getAssignmentsForCodeAndHeadquarter(matrixVersionId: number, codeId: number): Observable<EntitlementAssignmentExtendedDto[]> {
		return this.apiService.get([EntitlementAssignmentExtendedDto], `entitlement-assignment/${matrixVersionId}/${codeId}`);
	}

	createMatrixElement(headquarterId: number, codeId: number, matrixVersionId: number, assignments: EntitlementAssignmentModel[]): Observable<EntitlementMatrixVersionHistoryDto> {
		return this.apiService.post(EntitlementMatrixVersionHistoryDto, `entitlement-assignment/${headquarterId}/${codeId}/${matrixVersionId}`, assignments);
	}

	updateMatrixElement(headquarterId: number, codeId: number, matrixVersionId: number, assignments: EntitlementAssignmentModel[]): Observable<EntitlementMatrixVersionHistoryDto> {
		return this.apiService.put(EntitlementMatrixVersionHistoryDto, `entitlement-assignment/${headquarterId}/${codeId}/${matrixVersionId}`, assignments);
	}

	getActiveCards(headquarterId: number, codeId: number): Observable<any> {
		return this.apiService.get(Any, `entitlement-assignment/active-cards/${headquarterId}/${codeId}`);
	}

	clearActiveCardsCache(headquarterId: number, codeId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/active-cards/${headquarterId}/${codeId}`);
	}

	disableMatrixElement(headquarterId: number, codeId: number, matrixVersionId: number | null = null): Observable<EntitlementMatrixVersionHistoryDto> {
		return this.apiService.delete(EntitlementMatrixVersionHistoryDto, `entitlement-assignment/${headquarterId}/${codeId}/${matrixVersionId}`);
	}

	getAllTypes(matrixVersionId: number): Observable<EntitlementTypeAssociationDto[]> {
		return this.apiService.get([EntitlementTypeAssociationDto], `entitlement-assignment/${matrixVersionId}/all-types`);
	}

	clearAllTypesCache(matrixVersionId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/${matrixVersionId}/all-types`);
	}

	getAllCodes(matrixVersionId: number): Observable<MatrixAdministrationCodeDto[]> {
		return this.apiService.get([MatrixAdministrationCodeDto], `entitlement-assignment/${matrixVersionId}/all-codes`);
	}

	clearAllCodesCache(matrixVersionId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/${matrixVersionId}/all-codes`);
	}

	updateMatrix(headquarterId: number, matrixVersionId: number, model: MatrixDeltaModel): Observable<EntitlementMatrixVersionHistoryDto> {
		return this.apiService.patch(EntitlementMatrixVersionHistoryDto, `entitlement-assignment/${headquarterId}/${matrixVersionId}`, model);
	}

	clearCodesCache(matrixVersionId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/${matrixVersionId}/codes`);
	}

	clearAssignmentsForCodeAndHeadquarterCache(matrixVersionId: number, codeId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/${matrixVersionId}/${codeId}`);
	}

	clearTypesCache(matrixVersionId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/${matrixVersionId}/types`);
	}

	clearUnassignedCodes(matrixVersionId: number): void {
		this.apiService.invalidateCache(`entitlement-assignment/${matrixVersionId}/codes/unassigned`);
	}

	getActiveCardsBatches(activeCards: string[]): string[][][] {
		const columns: string[][] = [];
		const impactedActiveCardsBatches: string[][][] = [];

		const rowSize = 10;
		const columnSize = 3;

		while (activeCards.length > 0) {
			columns.push(activeCards.splice(0, rowSize));
		}

		while (columns.length > 0) {
			impactedActiveCardsBatches.push(columns.splice(0, columnSize));
		}

		return impactedActiveCardsBatches;
	}
}
