import { JsonProperty } from "@nstep-common/utils";

export class ClientModel {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	type: number = 1;

	@JsonProperty({ type: String })
	name: string = '';

	@JsonProperty({ type: String, nullable: true })
	description: string | null = null;

	@JsonProperty({ type: String })
	clientId: string = '';

	@JsonProperty({ type: String })
	clientSecret: string = '';

	@JsonProperty({ type: Date, nullable: true })
	validFrom: Date | null = null;

	@JsonProperty({ type: Date, nullable: true })
	validTo: Date | null = null;

	@JsonProperty({ type: String, nullable: true })
	whitelistIp: string | null = null;

	@JsonProperty({ type: Number, nullable: true })
	posLocationId: number | null = null;

	@JsonProperty({ type: Boolean, nullable: true })
	allowUserImpersonation: boolean | null = null;

	@JsonProperty({ type: String, nullable: true })
	registerUrl: string | null = null;

	@JsonProperty({ type: String, nullable: true })
	loginCallbackUrl: string | null = null;

	@JsonProperty({ type: Boolean })
	isDisabled!: boolean;

	@JsonProperty({ type: String, array: true })
	claims: string[] = [];

	constructor(data?: Partial<ClientModel> | null) {
		if (data) {
			Object.assign(this, data);
		}
		else {
			this.clientId = crypto.randomUUID();
			this.clientSecret = crypto.randomUUID();
		}
	}
}

export class ClientSummaryDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: String, nullable: true })
	description: string | null = null;

	@JsonProperty({ type: Number })
	clientType!: number;

	@JsonProperty({ type: Boolean })
	isDisabled!: boolean;

	@JsonProperty({ type: Date, nullable: true })
	validFrom: Date | null = null;

	@JsonProperty({ type: Date, nullable: true })
	validTo: Date | null = null;
}