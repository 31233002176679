import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GridService {

	constructor(private apiService: ApiService) { }

	getHeadquarters(): Observable<any> {
		return this.apiService.get(Any, 'grid/headquarters');
	}

	getCodes(): Observable<any> {
		return this.apiService.get(Any, 'grid/entitle/codes');
	}

	getTypes(): Observable<any> {
		return this.apiService.get(Any, 'grid/entitle/types');
	}

	getUnits(): Observable<any> {
		return this.apiService.get(Any, 'grid/entitle/units');
	}

	getLocations(): Observable<any> {
		return this.apiService.get(Any, 'grid/pos/locations');
	}

	getTransactionStates(): Observable<any> {
		return this.apiService.get(Any, 'grid/transaction/states');
	}

	getTransactionTypes(): Observable<any> {
		return this.apiService.get(Any, 'grid/transaction/types');
	}

	getRationedTypes(): Observable<any> {
		return this.apiService.get(Any, 'grid/rationedtypes');
	}
}
