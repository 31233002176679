import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationModel, JwtTokenModel, AccountService, LogService, ResetPasswordModel, AccountModel } from '@nstep-common/core';
import { AppSource, JsonMapper, toast } from '@nstep-common/utils';
import { environment } from '@nstep-internal/environments/environment';
import { difference } from 'lodash';
import { EMPTY, Observable, catchError, map, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private timeout: any;
	private jwt: JwtTokenModel | null = null;

	get JWT(): JwtTokenModel | null {
		if (this.isAccessTokenExpired()) {
			return null;
		}

		return this.jwt;
	}

	constructor(private jwtHelper: JwtHelperService,
		private logger: LogService,
		private accountService: AccountService,
		private router: Router) {
		this.tryLogIn();
	}

	private decodeJwt(accesToken: string): JwtTokenModel | null {
		if (!accesToken) {
			return null;
		}

		const jwt = this.jwtHelper.decodeToken(accesToken);

		const jsonMapper = new JsonMapper();
		const mapResult = jsonMapper.deserializeObject(jwt, JwtTokenModel);

		if (Object.keys(mapResult.errors).length) {
			return null;
		}

		return mapResult.value;
	}

	private getRefreshToken(): string {
		return localStorage.getItem('refreshToken') || '';
	}

	private storeSecurityTokens(refreshToken: string, accessToken: string): void {
		localStorage.setItem('refreshToken', refreshToken);
		localStorage.setItem('accessToken', accessToken);
	}

	private isAccessTokenExpired(): boolean {
		return this.jwtHelper.isTokenExpired(this.getAccessToken());
	}

	private startAutoLogout(): void {
		const expirationDate = new Date(this.jwt!.exp * 1000);
		const dateNow = new Date();

		const milisecondsLeft = Math.max(0, expirationDate.getTime() - dateNow.getTime() - 1000);

		this.timeout = setTimeout(() => this.refreshSession().subscribe(), milisecondsLeft);
	}

	tryLogIn(): void {
		this.jwt = this.decodeJwt(this.getAccessToken());

		if (this.jwt) {
			this.startAutoLogout();
		}
	}

	logIn(model: AuthenticationModel): Observable<JwtTokenModel | AccountModel> {
		return this.accountService.logIn(model)
			.pipe(map(r => {
				if (r.accessToken && r.refreshToken) {
					this.storeSecurityTokens(r.refreshToken, r.accessToken);
					this.jwt = this.decodeJwt(r.accessToken);

					this.startAutoLogout();

					this.logger.log(`User ${this.jwt!.nameIdentifier} successfully logged in.`);

					return this.jwt!;
				}
				else {
					return r;
				}
			}));
	}

	logOut(): void {
		let redirectUrl = 'login';

		if (this.jwt != null) {
			if (environment.appName == AppSource.Public && this.jwt.role != 'User') {
				redirectUrl += '/local';
			}

			this.accountService.logOut();

			this.jwt = null;
		}

		localStorage.clear();
		clearTimeout(this.timeout);

		this.router.navigate([redirectUrl]);
	}

	resetPassword(model: ResetPasswordModel): Observable<JwtTokenModel | AccountModel> {
		return this.accountService.passwordReset(model)
			.pipe(map(r => {
				if (r.accessToken && r.refreshToken) {
					this.storeSecurityTokens(r.refreshToken, r.accessToken);
					this.jwt = this.decodeJwt(r.accessToken);

					this.startAutoLogout();

					this.logger.log(`User ${this.jwt!.nameIdentifier} successfully logged in.`);

					return this.jwt!;
				}
				else {
					return r;
				}
			}));
	}

	getAccessToken(): string {
		return localStorage.getItem('accessToken') || '';
	}

	hasAccess(...permisisons: string[]): boolean {
		if (!permisisons.length) {
			return true;
		}

		const access = this.jwt ? this.jwt.access : [];
		return difference(permisisons, access).length == 0;
	}

	hasPartialAccess(...permisisons: string[]): boolean {
		if (!permisisons.length) {
			return true;
		}

		const access = this.jwt ? this.jwt.access : [];
		const diff = difference(permisisons, access).length;

		return diff < permisisons.length;
	}

	refreshSession(): Observable<any> {
		return this.accountService
			.refresh({
				accessToken: this.getAccessToken(),
				refreshToken: this.getRefreshToken()
			})
			.pipe(
				tap(r => {
					if (!r.accessToken || !r.refreshToken) {
						throw ['Security tokens unavailable'];
					}

					this.storeSecurityTokens(r.refreshToken, r.accessToken);
					this.jwt = this.decodeJwt(r.accessToken);

					this.startAutoLogout();

					this.logger.log(`User access for ${this.jwt!.nameIdentifier} has been refreshed.`);
				}),
				catchError(() => {
					this.logOut();
					toast('', 'Your session has expired.', 'orange');

					return EMPTY;
				})
			);
	}
}
