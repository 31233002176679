import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { AdminAdjustmentsPostModel, AdminAdjustmentsModel, GroupedTransactionDto } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AdminAdjustmentService {

	constructor(private apiService: ApiService) { }

	getTransactions(adminAjustmentsModel: AdminAdjustmentsModel): Observable<GroupedTransactionDto[]> {
		const formatedFromDate = formatDate(adminAjustmentsModel.from!, 'yyyy-MM-dd', 'en');
		const formatedToDate = formatDate(adminAjustmentsModel.to!, 'yyyy-MM-dd', 'en');

		const model = {
			card: adminAjustmentsModel.amisCardNumber,
			entitleTypeId: adminAjustmentsModel.entitlementTypeId,
			startDate: formatedFromDate,
			endDate: formatedToDate
		};

		return this.apiService.get([GroupedTransactionDto], 'transactions/manage/bulk', { params: model });
	}

	createTransactionAdjustments(adjustments: AdminAdjustmentsPostModel[]): Observable<any> {
		return this.apiService.postNoContent('transactions/manage/bulk', adjustments);
	}

	clearGetTransactionsCache(): void {
		this.apiService.invalidateCache('transactions/manage/bulk');
	}
}
