import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: 'path',
	pure: true
})
export class PathPipe implements PipeTransform {
	split: string[] = [];

	transform(value: any, path: string): any {
		if (!value || !path) {
			return null;
		}

		if (!this.split.length) {
			this.split = path.replace(/\[(\d+)\]/g, '.$1').split('.');
		}

		return this.split.reduce((acc, c) => acc && acc[c], value);
	}
}
