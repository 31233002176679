import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hasKeys',
	pure: true // for perforamnce reasons
})
export class HasKeysPipe implements PipeTransform {
	transform(value: any): boolean {
		if (!value) {
			return false;
		}

		return Array.isArray(value) ? value.length > 0 : Object.keys(value).length > 0;
	}
}