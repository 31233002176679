export class EntitlementTypeModel {
	id!: number;
	name!: string;
	rationed: boolean = false;
	rationingTypeId!: number;
	disabled!: boolean;
	minBuyAmount!: number;

	constructor(data: Partial<EntitlementTypeModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class EntitlementTypeCreateDto {
	name!: string;
	rationingTypeId!: number;
	disabled!: boolean;
	minBuyAmount!: number;

	constructor(data: Partial<EntitlementTypeCreateDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}