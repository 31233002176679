import { JsonProperty } from '@nstep-common/utils';

export class AdminAdjustmentsModel {
	amisCardNumber!: string;
	entitlementTypeId!: number;
	from!: Date | null;
	to!: Date | null;
}

export class AdminAdjustmentsPostModel {
	transactionId!: number | null;
	transactionAmount!: number;
	notes!: string;
}

export class EntitlementType {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: Number })
	rationingTypeId!: number;

	@JsonProperty({ type: Boolean })
	disabled!: boolean;

	@JsonProperty({ type: Number })
	minBuyAmount!: number;
}

export class CardTransactionDto {
	@JsonProperty({ type: Number })
	transactionId!: number;

	@JsonProperty({ type: Number, nullable: true })
	transactionBulkId: number | null = null;

	@JsonProperty({ type: Number })
	cardId!: number;

	@JsonProperty({ type: String })
	cardNumber!: string;

	@JsonProperty({ type: Number })
	headquarterId!: number;

	@JsonProperty({ type: String })
	headquarterName!: string;

	@JsonProperty({ type: Number })
	entitlementCodeId!: number;

	@JsonProperty({ type: String, nullable: true })
	entitlementCodeExplicit: string | null = null;

	@JsonProperty({ type: Number })
	entitlementTypeId!: number;

	@JsonProperty({ type: String })
	entitlementTypeName!: string;

	@JsonProperty({ type: Number })
	transactionAmount!: number;

	@JsonProperty({ type: Number })
	transactionTypeId!: number;

	@JsonProperty({ type: String, nullable: true })
	transactionTypeName: string | null = null;

	@JsonProperty({ type: Number })
	transactionStatusId!: number;

	@JsonProperty({ type: String })
	transactionStatusName!: string;

	@JsonProperty({ type: Number, nullable: true })
	measurementUnitAssociationId: number | null = null;

	@JsonProperty({ type: String })
	measurementUnitName!: string;

	@JsonProperty({ type: String, nullable: true })
	measurementUnitDescription: string | null = null;

	@JsonProperty({ type: Date })
	timeStamp!: Date;

	@JsonProperty({ type: Date, nullable: true })
	startDate: Date | null = null;

	@JsonProperty({ type: Date, nullable: true })
	expirationDate: Date | null = null;

	@JsonProperty({ type: Number, nullable: true })
	posLocationId: number | null = null;

	@JsonProperty({ type: String, nullable: true })
	posLocationName: string | null = null;

	@JsonProperty({ type: Number, nullable: true })
	posOperatorId: number | null = null;

	@JsonProperty({ type: String, nullable: true })
	deviceTransactionId: string | null = null;

	@JsonProperty({ type: String, nullable: true })
	notes: string | null = null;
}

export class BulkTransactionDto extends CardTransactionDto {
	@JsonProperty({ type: Number })
	transactionBalance!: number;
}

export class GroupedTransactionDto {
	@JsonProperty({ type: BulkTransactionDto })
	bulk!: BulkTransactionDto;

	originalBalance!: number;

	@JsonProperty({ type: CardTransactionDto, array: true })
	attached!: CardTransactionDto[];
}

export enum AdminAdjustmentsViews {
	EditAdjustments = 0,
	ConfirmAdjustments = 1
}

export class AttachmentData {
	cardNumber!: string;
	transactionBulkId: number | null = null;
	transactionId!: number;
	transactionDate!: string;
	headquarterName!: string;
	entitlementTypeName!: string;
	transactionBalance!: number;
	transactionAmount: number | null = null;
	measurementUnitName!: string;
	transactionTypeName: string | null = null;
	transactionStatusName!: string;
	purchaseIndex!: number | null;
	attachmentIndex!: number | null;
	notes!: string;
	attached: AttachmentData[] = [];
	hasPurchase: boolean | null = null;
	expanded: boolean | null = null;
	originalBalance: number | null = null;

	constructor(data: Partial<AttachmentData> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}