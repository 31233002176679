import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { EntitlementIssuanceTypeDto, EntitlementTypeAssociationDto, UpdateAssociationResponseModel } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EntitlementTypeAssociationService {

	constructor(private apiService: ApiService) { }

	getIssuanceTypes(): Observable<EntitlementIssuanceTypeDto[]> {
		return this.apiService.get([EntitlementIssuanceTypeDto], 'entitlement-type-association/issuance-types');
	}

	createTypeAssociation(headquarterId: number, matrixVersionId: number | null = null, model: EntitlementTypeAssociationDto): Observable<any> {
		return this.apiService.post(Any, `entitlement-type-association/${headquarterId}/${matrixVersionId}`, model);
	}

	updateTypeAssociation(headquarterId: number, matrixVersionId: number | null = null, model: EntitlementTypeAssociationDto): Observable<UpdateAssociationResponseModel> {
		return this.apiService.put(UpdateAssociationResponseModel, `entitlement-type-association/${headquarterId}/${matrixVersionId}`, model);
	}

	revokeTypeAssociation(headquarterId: number, typeAssociationId: number, matrixVersionId: number | null = null): Observable<UpdateAssociationResponseModel> {
		return this.apiService.delete(UpdateAssociationResponseModel, `entitlement-type-association/${headquarterId}/${typeAssociationId}/${matrixVersionId}`);
	}
}
