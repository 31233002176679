import { HttpErrorResponse } from '@angular/common/http';
import { debounce } from 'lodash';

const messages: any = {};

export function toast(summary: string, details: string, toastClass: string) {
	const key = summary + details;
	
	let showToast = messages[key];

	if (!showToast) {
		showToast = messages[key] = debounce(() => {
			$('body')
				.toast({
					displayTime: 'auto',
					showProgress: 'bottom',
					class: toastClass,
					position: 'bottom right',
					newestOnTop: true,
					title: summary,
					message: details
				});
			
			setTimeout(() => {				
				delete messages[key];
			}, 200);
		}, 5000, {
			leading: true,
			trailing: false
		});
	}

	showToast();
}

export function toastHttpError(httpErr: HttpErrorResponse) {
	const isClientError = httpErr.error instanceof ErrorEvent;
	toastGenericError(isClientError, httpErr.status);
}

export function toastRequestDenied(message: string) {
	toast('Request Denied', message, 'orange');
}

export function toastGenericError(isClientError = true, status: number | null = null) {
	const summary = `Error${status ? ` (status: ${status})` : ''}`;
	const details = `There is a problem with the ${isClientError ? 'client' : 'server'}, we are sorry for the inconvenience.`;

	toast(summary, details, 'red');
}

export function toastRequestUnauthorized(status: number) {
	const summary = `Error (${status})`;
	const details = `The user does not have permissions to access the requested resources.`;

	toast(summary, details, 'red');
}
