import { ExtraValidator } from '@nstep-common/utils';
import { EntitlementTypeModel } from '@nstep-internal/pages';

export class EntitlementTypeModelValidator extends ExtraValidator<EntitlementTypeModel> {
	constructor() {
		super();

		this.ruleFor('name')
			.must(name => !!name)
			.withMessage('Type is required')
			.notEmpty()
			.matches(/^[0-9a-zA-Z_\*\-() ]{3,50}$/)
			.withMessage('A valid input is required with the length between 3-50 characters!');

		this.ruleFor('minBuyAmount')
			.must(minBuyAmount => Number.isInteger(minBuyAmount))
			.withMessage('Minimum buy ammount is required')
			.exclusiveBetween(0, 10000)
			.withMessage('Number between 1-9999')
			.when(model => model.rationed);
	}
}