import { PasswordSettingsModel, UserSettings } from "@nstep-common/core";
import { ExtraValidator } from "@nstep-common/utils";

export class UserSetingsValidator extends ExtraValidator<UserSettings> {
	constructor(settings: PasswordSettingsModel) {
		super();

		this.ruleFor('email')
			.emailAddress()
			.withMessage("Invalid email format.")
			.when(m => !!m.email);

		this.ruleFor('telephone')
			.matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
			.withMessage('Phone number is not valid')
			.when(m => !!m.telephone)

		this.ruleFor('newPassword')
			.matches(new RegExp('^.{' + settings.MinimumLength + ',' + settings.MaximumLength + '}$'))
			.withMessage(`Length must be between ${settings.MinimumLength}-${settings.MaximumLength} characters!`)
			.matches(new RegExp('^(?:[^\\d]*\\d){' + settings.MinimumNumericCharacters + ',}'))
			.withMessage(`Minimum digits required: ${settings.MinimumNumericCharacters}`)
			.matches(new RegExp('^(?:[^a-z]*[a-z]){' + settings.MinimumLowercaseCharacters + ',}'))
			.withMessage(`Minimum lowercase letters: ${settings.MinimumLowercaseCharacters}`)
			.matches(new RegExp('^(?:[^A-Z]*[A-Z]){' + settings.MinimumUppercaseCharacters + ',}'))
			.withMessage(`Minimum uppercase letters: ${settings.MinimumUppercaseCharacters}`)
			.matches(new RegExp('^(?:[^' + settings.SpecialCharacters.replace('-', '\\-') + ']*[' + settings.SpecialCharacters.replace('-', '\\-') + ']){' + settings.MinimumSpecialCharacters + ',}'))
			.withMessage(`Minimum special characters: ${settings.MinimumSpecialCharacters} from ${settings.SpecialCharacters}`)		
			.when(m => !!m.newPassword);

		this.ruleFor('repeatPassword')
			.must((value, model) => model.newPassword == value)
			.withMessage("Passwords do not match")
			.when(m => !!m.newPassword);
	}
}