import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { catchError, EMPTY, finalize, tap } from 'rxjs';
import { ValidationErrors } from 'fluentvalidation-ts';

import { createProxy } from "@nstep-common/utils";
import { AccountModel, AuthenticationModel, AuthService, BaseComponent, JwtTokenModel, PasswordSettingsModel, ResetPasswordModel, ResetPasswordModelValidator, SettingsService } from "@nstep-common/core";
import { flatten } from 'lodash';

@Component({
	selector: 'app-authentication',
	templateUrl: './authentication.component.html',
	styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent extends BaseComponent implements OnInit {
	@Input() userNamePlaceHolder: string = '';
	@Input() passwordPlaceHolder: string = '';
	@Input() userNameMaxLength: number | null = null;
	@Input() passwordMaxLength: number | null = null;

	@Input() onValidation: ((model: AuthenticationModel) => ValidationErrors<AuthenticationModel>) | null = null;
	@Output() onLoginCompleted = new EventEmitter<JwtTokenModel>();

	requiresPasswordReset = false;
	requiresTwoFactor = false;
	loading = false;

	loginValidation: ValidationErrors<AuthenticationModel> = {};
	loginInvalid = false;
	loginModel = createProxy(new AuthenticationModel(), {
		set: () => {
			this.errors = [];

			this.resetModel.userName = this.loginModel.userName;
			this.resetModel.oldPassword = this.loginModel.password;

			if (!this.onValidation) {
				return;
			}

			this.loginValidation = this.onValidation(this.loginModel);
			this.loginInvalid = this.hasKeys(this.loginValidation);
		}
	});

	passwordSettings: PasswordSettingsModel = new PasswordSettingsModel();
	resetValidation: ValidationErrors<ResetPasswordModel> = {};
	resetInvalid = false;
	resetModel: ResetPasswordModel = createProxy(new ResetPasswordModel(), {
		set: () => {
			this.errors = [];

			const validator = new ResetPasswordModelValidator(this.passwordSettings);
			this.resetValidation = validator.validate(this.resetModel);
			this.resetInvalid = this.hasKeys(this.resetValidation);
		}
	});

	errors: string[] = [];

	constructor(private authService: AuthService,
		private settingsService: SettingsService) {
		super();
	}

	ngOnInit(): void {
		if (this.authService.JWT) {
			this.onLoginCompleted.emit(this.authService.JWT);
		}

		this.subscriptions.push(this.settingsService.getPasswordSettings()
			.subscribe(settings => this.passwordSettings = settings));
	}

	onSubmit() {
		if (!this.requiresPasswordReset ? this.loginInvalid : this.resetInvalid) {
			return;
		}

		this.loading = true;

		const submit = (!this.requiresPasswordReset
			? this.authService.logIn(this.loginModel)
			: this.authService.resetPassword(this.resetModel))
			.pipe(
				tap(response => {
					if (response instanceof AccountModel) {
						if (!this.requiresPasswordReset && response.requiresPasswordReset) {
							this.requiresPasswordReset = true;
						}

						this.requiresTwoFactor = response.requiresTwoFactor;
					}

					if (response instanceof JwtTokenModel) {
						this.onLoginCompleted.emit(response);
					}

					this.errors = [];
				}),
				catchError((resp: any) => {
					const errors = flatten(Object.values(resp)) as string[];
					this.errors = errors;

					return EMPTY;
				}),
				finalize(() => {
					this.loading = false;
				})
			)
			.subscribe()

		this.subscriptions.push(submit);
	}
}
