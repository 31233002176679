import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { isString } from 'lodash';
import { Editor } from 'ngx-editor';

@Component({
	selector: 'app-text-area',
	templateUrl: './text-area.component.html',
	styleUrls: ['./text-area.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class TextAreaComponent implements OnInit, OnDestroy, OnChanges {
	@Input() value: string | null = null;
	@Output() valueChange = new EventEmitter<string>();

	@Input() disabled = false;
	@Input() placeholder = '';

	html = '';
	editor!: Editor;
	valueHasChanged = false;

	ngOnInit(): void {
		this.editor = new Editor();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['value'] &&
			changes['value'].currentValue &&
			changes['value'].currentValue != changes['value'].previousValue &&
			this.value) {

			const value = changes['value'].currentValue;
			if (!isString(value)) {
				return;
			}

			const text = value.trim();

			if (this.valueHasChanged) {
				this.valueHasChanged = false;
			}
			else if (text.startsWith('<p>')) {
				this.html = text;
			}
			else {
				setTimeout(() => this.editor.commands.insertText(text).exec());
			}
		}
	}

	ngOnDestroy(): void {
		this.editor.destroy();
	}

	htmlHasChanged(value: string) {
		this.html = value;
		this.valueHasChanged = true;
				
		this.valueChange.emit(this.html);
	}
}