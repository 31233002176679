import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, TableColumn } from '@nstep-common/core';
import { ModalComponent } from '@nstep-common/semantic-ui';
import { createProxy, toast } from '@nstep-common/utils';
import { RoleModel, RoleValidator } from '@nstep-internal/pages';
import { RolesService } from '@nstep-internal/shared';
import { chain, clone, filter, flatten, isArray } from 'lodash';
import { EMPTY, Observable, catchError, finalize, forkJoin, tap } from 'rxjs';

@Component({
	selector: 'app-roles',
	templateUrl: './roles.component.html'
})
export class RolesComponent extends BaseComponent implements OnInit {
	@ViewChild('upsertModal') upsertModal!: ModalComponent;
	@ViewChild('confirmModal') confirmModal!: ModalComponent;

	tableData: any[] = [];
	tableDataReady = false;

	tableColumns: TableColumn[] = [
		{ name: 'Role Name', key: 'name', sortAsc: true, isHeaderCentered: true, isCellCentered: true },
		{ name: 'Description', key: 'description', isHeaderCentered: true, isCellCentered: true },		
		{ name: 'Disabled', key: 'description', isHeaderCentered: true, isCellCentered: true },
		{ name: 'Actions', isHeaderCentered: true, isCellCentered: true }
	];

	validation = {};
	isValid = false;

	role: RoleModel = createProxy(new RoleModel(), {
		set: () => this.validate()
	});

	errors: string[] = [];
	confirmMessage = '';

	targets: any[] = [{}, {}, {}, {}];
	state: any = {};
	dependants: any = {};

	users: any[] = [];

	waitingForServer = true;

	constructor(private rolesService: RolesService) {
		super();
	}

	ngOnInit(): void {
		this.waitingForServer = true;

		this.subscriptions.push(forkJoin([this.initTable(), ...this.getPermissions()])
			.pipe(finalize(() => {
				this.waitingForServer = false;
			}))
			.subscribe());
	}


	validate(): void {
		const validator = new RoleValidator();
		this.validation = validator.validate(this.role);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	initTable(): Observable<any> {
		this.tableDataReady = false;

		const getRoles$ = this.rolesService
			.getRoles()
			.pipe(
				tap(r => {
					this.tableData = chain(r)
						.map(i => i)
						.value();
				}),
				finalize(() => {
					this.tableDataReady = true;
				})
			);

		return getRoles$;
	}

	getPermissions(): Observable<any>[] {
		const getInternalWebAppPermissions$ = this.rolesService
			.getInternalWebAppPermissions()
			.pipe(tap(r => {
				this.targets[0] = {
					id: r.id,
					name: 'Internal Web App',
					icon: 'chrome icon',
					permissions: r['children'],
					hasDependencies: true,
					onToggled: (event: boolean) => {
						const apiId = this.targets[1].id;

						if (event) {
							this.state[apiId] = true;
						}
					}
				};
			}));

		const getInternalApiPermissions$ = this.rolesService
			.getInternalApiPermissions()
			.pipe(tap(r => {
				this.targets[1] = {
					id: r.id,
					name: 'Internal API',
					icon: 'server icon',
					permissions: r['children'],
					hasDependencies: false,
					onToggled: () => { }
				};
			}));

		const getPublicWebAppPermissions$ = this.rolesService
			.getPublicWebAppPermissions()
			.pipe(tap(r => {
				this.targets[2] = {
					id: r.id,
					name: 'Public Web App',
					icon: 'chrome icon',
					permissions: r['children'],
					hasDependencies: true,
					onToggled: (event: boolean) => {
						const apiId = this.targets[3].id;

						if (event) {
							this.state[apiId] = true;
						}
					}
				};
			}));

		const getPublicApiPermissions$ = this.rolesService
			.getPublicApiPermissions()
			.pipe(tap(r => {
				this.targets[3] = {
					id: r.id,
					name: 'Public API',
					icon: 'server icon',
					permissions: r['children'],
					hasDependencies: false,
					onToggled: () => { }
				};
			}));

		return [getInternalWebAppPermissions$, getInternalApiPermissions$, getPublicWebAppPermissions$, getPublicApiPermissions$];
	}

	resetState(): void {
		this.state = {};
		this.dependants = {};

		this.errors = [];
		this.confirmMessage = '';

		this.users = [];
	}

	createRole(): void {
		this.resetState();
		this.role = createProxy(new RoleModel(), {
			set: () => this.validate()
		});

		this.upsertModal.toggle();
	}

	editRole(role: RoleModel): void {
		this.waitingForServer = true;

		this.resetState();
		this.role = createProxy(clone(role), {
			set: () => this.validate()
		});

		this.upsertModal.toggle();

		const getRolePermissions$ = this.rolesService
			.getRolePermissions(this.role.id)
			.pipe(tap((permissions) => {
				this.role.claims = permissions;

				for (const permission of permissions) {
					this.state[permission] = true;

					for (const target of this.targets) {
						this.showDependencies(permission, target.permissions);
					}
				}
			}));

		const getRoleUsers$ = this.rolesService.getRoleUsers(this.role.id)
			.pipe(tap((users) => this.users = filter(users, u => u)));

		this.subscriptions.push(forkJoin([getRolePermissions$, getRoleUsers$])
			.pipe(finalize(() => this.waitingForServer = false))
			.subscribe());
	}

	showDependencies(key: string, permissions: any[]): void {
		for (const perm of permissions) {
			if (perm.children && perm.children.length) {
				this.showDependencies(key, perm.children);
			}

			if (perm.value && perm.value.length) {
				for (const dep of perm.value) {
					if (dep != key) {
						continue;
					}

					if (!this.dependants[dep]) {
						this.dependants[dep] = 0;
					}

					this.dependants[dep] += 1;
				}
			}
		}
	}

	toggleDepedencies(toggled: any, perm: any) {
		for (const dep of perm.value) {
			if (!this.dependants[dep]) {
				this.dependants[dep] = 0;
			}

			this.dependants[dep] += toggled ? 1 : -1;
			this.state[dep] = toggled;

			if (this.dependants[dep] <= 0) {
				this.dependants[dep] = 0;
				this.state[dep] = false;
			}
		}
	}

	toggleAll(toggle: boolean, permissions: any[]) {
		for (const perm of permissions) {
			if (perm.children && perm.children.length) {
				this.toggleAll(toggle, perm.children);
			}

			if (perm.id) {
				this.state[perm.id] = toggle;
			}

			if (perm.value) {
				if (isArray(perm.value)) {
					this.toggleDepedencies(toggle, perm);
				}
				else if (!this.dependants[perm.value]) {
					this.state[perm.value] = toggle;
				}
			}
		}
	}

	toggleRole(role: RoleModel): void {
		this.waitingForServer = true;

		const toggleRole = (role.isDisabled ? this.rolesService.disableRole(role.id) : this.rolesService.enableRole(role.id))
			.pipe(
				tap(() => {
					toast('Success', `Role ${role.name} has been ${role.isDisabled ? 'disabled' : 'enabled'}.`, 'green');
				}),
				catchError(r => {					
					role.isDisabled = !role.isDisabled;

					const err: string[] = flatten(Object.values(r));
					toast('Error', err[0], 'red');

					return EMPTY;
				}),
				finalize(() => {
					this.waitingForServer = false;
				})
			)
			.subscribe();

		this.subscriptions.push(toggleRole);
	}

	confirm(action: string, item?: any): void {
		if (action == 'ask-delete') {
			this.role = item;

			this.confirmMessage = `Are you sure you want to delete role "${item.name}"?`;
			this.confirmModal.toggle();
		}

		if (action == 'delete-role') {
			this.deleteRole(this.role);
		}
	}

	deleteRole(role: RoleModel): void {
		this.waitingForServer = true;

		const deleteRole$ = this.rolesService.deleteRole(role.id)
			.pipe(
				tap(() => {
					this.confirmModal.toggle();

					this.rolesService.invalidateCache();
					this.subscriptions.push(this.initTable().subscribe());

					toast('Success', `Role ${role.name} has been deleted.`, 'green');
				}),
				catchError(() => {
					this.confirmModal.toggle();
					toast('Error', `Role ${role.name} could not be deleted.`, 'red');

					return EMPTY;
				}),
				finalize(() => {
					this.waitingForServer = false;
				})
			)
			.subscribe();

		this.subscriptions.push(deleteRole$);
	}

	saveRole(): void {
		this.waitingForServer = true;

		this.role.claims = chain(Object.keys(this.state))
			.filter(k => this.state[k])
			.value();

		const saveRole = (this.role.id ? this.rolesService.updateRole(this.role) : this.rolesService.createRole(this.role))
			.pipe(
				tap(() => {
					this.upsertModal.toggle();
					toast('Success', `Role has been ${this.role.id ? 'updated' : 'created'}.`, 'green');

					this.rolesService.invalidateCache();
					this.subscriptions.push(this.initTable().subscribe());
				}),
				catchError(r => {
					this.errors = flatten(Object.values(r));
					toast('Error', 'Role could not be updated.', 'red');

					return EMPTY;
				}),
				finalize(() => {
					this.waitingForServer = false;
				})
			)
			.subscribe();

		this.subscriptions.push(saveRole);
	}

}
