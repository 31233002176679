import { Injectable } from '@angular/core';
import { LogPublisher, LogConsole, LogWebApi, Enviroment } from "@nstep-common/utils";
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class LogPublishersService {

	constructor(private httpClient: HttpClient,
		private environment: Enviroment) {
		this.buildPublishers();
	}

	publishers: LogPublisher[] = [];

	buildPublishers(): void {
		this.publishers.push(new LogConsole());
		this.publishers.push(new LogWebApi(this.httpClient, this.environment));
	}
}
