import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { chain, startCase, camelCase, flatten } from 'lodash';
import { ValidationErrors } from 'fluentvalidation-ts';

import { DropdownOption, ModalComponent } from '@nstep-common/semantic-ui';
import {
	ExportDataRequest,
	FilterChangeModel,
	FilterDto,
	FilterOperator,
	FilterType,
	LogService,
	PagedQueryParameter,
	PaginationModel,
	ReportBaseComponent,
	ReportColumn,
	ReportColumnSortDirection,
	ReportSettingDefaultDataDto,
	ReportSettingsColumnDto,
	ReportSettingsDetailsDto,
	ReportSettingsDto,
	TableColumn
} from '@nstep-common/core';
import { createProxy, toast } from '@nstep-common/utils';
import {
	AddReportModel,
	AddReportModelValidator,
	AvailableRoleModel,
	GridFeature,
	ManageReportSettingsDto,
	ReportingTableItem,
	ReportManagementViews,
	ReportsManagementActions
} from '@nstep-internal/pages';
import { GridService, ReportingService, ReportsManagementService } from '@nstep-internal/shared';


@Component({
	selector: 'app-reports-management',
	templateUrl: './reports-management.component.html',
})
export class ReportsManagementComponent extends ReportBaseComponent implements OnInit {
	@ViewChild('reportConfirmationModal') reportConfirmationModal!: ModalComponent;
	modalIsLoading = false;

	loading = false;

	tableData: any[] = [];
	tableDataReady = false;
	selectedTableItem = new ReportingTableItem();
	currentReportAction: ReportsManagementActions | null = null;

	tableColumns: TableColumn[] = [
		{ name: 'Name', key: 'name', sortAsc: true, isHeaderCentered: true },
		{ name: 'Available', key: 'availableRoles', isHeaderCentered: true },
		{ name: 'Country', key: 'country', isHeaderCentered: true },
		{ name: 'Description', key: 'description', isHeaderCentered: true },
		{ name: 'Actions', isHeaderCentered: true }
	];

	mainPagedQueryModel = new PagedQueryParameter({
		itemsPerPage: 10,
		page: 1,
		orderField: '',
		searchBy: '',
		isMultiWordSerch: false
	});

	mainPagination = new PaginationModel();

	reportSettingsDataReady = false;

	reportData: any[][] | null = null;
	reportDataReady = false;
	reportColumns: ReportColumn[] = [];

	reportsPagedQueryModel = new PagedQueryParameter({
		itemsPerPage: 10,
		page: 1,
		filterList: [],
		sortList: []
	});

	reportsPagination = new PaginationModel();

	currentReportView = ReportManagementViews.Main;

	//flag for showing report view from add report after clicking on Customize Report button
	showReportView = false;

	nationsDropdownValues: DropdownOption[] = [];
	roles: AvailableRoleModel[] = [];
	reportTemplatesDropdownValues: DropdownOption[] = [];
	reportTemplateColumns: { [key: number]: ReportColumn[] } = {};

	gridFeatures: { [key: number]: GridFeature } = {
		0: new GridFeature({ key: 0, name: 'Sortable' }),
		1: new GridFeature({ key: 1, name: 'Filterable' }),
		2: new GridFeature({ key: 2, name: 'Hide/Unhide Columns' }),
		3: new GridFeature({ key: 3, name: 'Exportable' }),
		4: new GridFeature({ key: 4, name: 'Column Reorder' })
	};

	collectionFilters: any[] = [];

	validation: ValidationErrors<AddReportModel> = {};
	isValid: boolean = false;

	addReportModel: AddReportModel = createProxy(new AddReportModel(), {
		set: () => this.validate(this.addReportModel)
	});

	validate(value: AddReportModel): void {
		const validator = new AddReportModelValidator();
		this.validation = validator.validate(value);
		this.isValid = Object.keys(this.validation).length === 0;
	}

	constructor(private reportingService: ReportingService,
		private reportsManagementService: ReportsManagementService,
		private gridService: GridService,
		protected logger: LogService) {
		super(logger);
	}

	ngOnInit(): void {
		this.getDefaultData();
		this.getTableData();
	}

	search(): void {
		this.mainPagedQueryModel.page = 1;
		this.tableDataReady = false;
		this.tableData = [];

		this.getTableData();
	}

	private getDefaultData(): void {
		const getDefaultDataSubscription$ = this.reportsManagementService.getDefaultData().subscribe({
			next: (response: ReportSettingDefaultDataDto) => {

				this.nationsDropdownValues = Object.keys(response.nations)
					.map(key => new DropdownOption({
						value: Number(key),
						name: response.nations[Number(key)]
					}));

				this.roles = Object.keys(response.roles)
					.map(key => new AvailableRoleModel({
						roleId: Number(key),
						roleName: response.roles[Number(key)]
					}));

				this.reportTemplatesDropdownValues = response.reportTemplates
					.map(item => new DropdownOption({
						value: item.id,
						name: item.name
					}));

				const endpoints = chain(response.reportTemplates)
					.flatMap(d => d.headers)
					.filter(d => d.filterType === FilterType.List)
					.map(d => d.endpoint!)
					.uniq()
					.value();

				const observables = endpoints.map(f => this.getColumnFilterCollection(f, this.gridService));

				const subscriptionForkJoin$ = forkJoin(observables).subscribe(responses => {

					const results: { [key: string]: string[] } = {};
					responses.forEach((values, index) => { results[endpoints[index]] = values; });

					response.reportTemplates.forEach(template => {
						this.reportTemplateColumns[template.id] = template.headers.map((column, index) => new ReportColumn({
							key: camelCase(column.name),
							name: startCase(column.name),
							isDate: column.isDate,
							orderNumber: index + 1,
							filterType: column.filterType,
							validOperators: column.validFilters,
							agregateOperator: column.filterType === FilterType.Basic ? FilterOperator.AND : FilterOperator.OR,
							assignedFilters: this.getDefaultFilters(column.filterType, results[column.endpoint!], camelCase(column.name) === 'timestamp')
						}));
					});
				});

				this.subscriptions.push(subscriptionForkJoin$);
			}
		});

		this.subscriptions.push(getDefaultDataSubscription$);
	}

	private getTableData(): void {
		const getReportsSubscription$ = this.reportsManagementService.getReports(this.mainPagedQueryModel).subscribe({
			next: response => {

				this.mainPagination = response.page;
				this.tableData = chain(response.results)
					.map(e => new ReportingTableItem({
						id: e.id,
						name: e.name,
						availableRoles: e.roles.flatMap(r => r.roleName!),
						country: e.nationName,
						description: e.description,
						actions: [ReportsManagementActions.Copy, ReportsManagementActions.Delete]
					}))
					.value();

				this.tableDataReady = true;
			},
			error: () => {
				this.tableDataReady = true;
			}
		});

		this.subscriptions.push(getReportsSubscription$);
	}

	onUserAction(item: ReportingTableItem, action: string): void {

		this.selectedTableItem = item;

		switch (action) {
			case ReportsManagementActions.Edit:
				this.changeView(ReportManagementViews.EditReport);
				break;
			case ReportsManagementActions.Copy:
				this.currentReportAction = ReportsManagementActions.Copy;
				this.reportConfirmationModal.toggle();
				break;
			case ReportsManagementActions.Delete:
				this.currentReportAction = ReportsManagementActions.Delete;
				this.reportConfirmationModal.toggle();
				break;
		}
	}

	copyReport(reportId: number): void {
		this.modalIsLoading = true;
		this.tableDataReady = false;
		this.tableData = [];

		const copyReportSubscription$ = this.reportsManagementService.copyReport(reportId).subscribe({
			next: () => {
				this.modalIsLoading = false;
				this.reportConfirmationModal.toggle();
				this.getTableData();

				toast('Success', `Report ${this.selectedTableItem.name} successfully copied!`, 'green');
			},
			error: () => {
				this.modalIsLoading = false;
				this.tableDataReady = true;
			}
		});

		this.subscriptions.push(copyReportSubscription$);
	}

	deleteReport(reportId: number): void {
		this.modalIsLoading = true;
		this.tableDataReady = false;
		this.tableData = [];

		const deleteReportSubscription$ = this.reportsManagementService.deleteReport(reportId).subscribe({
			next: () => {
				this.modalIsLoading = false;
				this.reportConfirmationModal.toggle();
				this.getTableData();

				toast('Success', `Report ${this.selectedTableItem.name} successfully deleted!`, 'green');
			},
			error: () => {
				this.modalIsLoading = false;
				this.tableDataReady = true;
			}
		});

		this.subscriptions.push(deleteReportSubscription$);
	}

	openAddTypeModal(): void {
		this.currentReportView = ReportManagementViews.AddReport;
	}

	changeView(view: ReportManagementViews): void {
		switch (view) {
			case ReportManagementViews.Main:
				this.currentReportView = ReportManagementViews.Main;
				this.showReportView = false;

				this.reportsPagedQueryModel = new PagedQueryParameter({
					page: 1,
					itemsPerPage: 10,
					filterList: [],
					sortList: []
				});
				break;
			case ReportManagementViews.AddReport:
				this.currentReportView = ReportManagementViews.AddReport;
				this.reportSettingsDataReady = false;
				this.selectedTableItem = new ReportingTableItem();
				this.addReportModel.name = null;
				this.addReportModel.nationId = null;
				this.addReportModel.description = null;
				this.addReportModel.availableRolesIds = [];

				this.roles.forEach(role => {
					role.isChecked = false;
				});

				this.addReportModel.templateId = null;
				this.reportSettingsDataReady = true;
				break;
			case ReportManagementViews.EditReport:
				this.currentReportView = ReportManagementViews.EditReport;
				this.reportSettingsDataReady = false;
				this.showReportView = true;
				this.reportDataReady = false;
				this.reportData = null;

				this.reportsPagedQueryModel = new PagedQueryParameter({
					page: 1,
					itemsPerPage: 10,
					filterList: [],
					sortList: []
				});

				const getReportSettingsSubscription$ = this.reportsManagementService.getReportSettings(this.selectedTableItem.id!).subscribe({
					next: (response: ReportSettingsDto) => {

						this.addReportModel.name = response.name;
						this.addReportModel.nationId = response.nationId;
						this.addReportModel.templateId = response.templateId;
						this.addReportModel.description = response.description;
						this.addReportModel.availableRolesIds = response.roles.flatMap(r => r.roleId);
						this.roles.forEach(role => {
							role.isChecked = this.addReportModel.availableRolesIds.includes(role.roleId);
						});

						this.gridFeatures[0].initialValue = response.reportSettingsDetails.canSort;
						this.gridFeatures[0].afterSetValue = response.reportSettingsDetails.canSort;

						this.gridFeatures[1].initialValue = response.reportSettingsDetails.canFilter;
						this.gridFeatures[1].afterSetValue = response.reportSettingsDetails.canFilter;

						this.gridFeatures[2].initialValue = response.reportSettingsDetails.canHideColumns;
						this.gridFeatures[2].afterSetValue = response.reportSettingsDetails.canHideColumns;

						this.gridFeatures[3].initialValue = response.reportSettingsDetails.canExport;
						this.gridFeatures[3].afterSetValue = response.reportSettingsDetails.canExport;

						this.gridFeatures[4].initialValue = response.reportSettingsDetails.canReorderColumns;
						this.gridFeatures[4].afterSetValue = response.reportSettingsDetails.canReorderColumns;

						const defaultTemplateColumns = this.reportTemplateColumns[this.addReportModel.templateId!];

						const proccessedReportColumnsSettings = this.getProccessedReportColumnsSettings(defaultTemplateColumns, response.reportSettingsDetails.columns);
						this.reportColumns = proccessedReportColumnsSettings.reportColumns;
						this.reportsPagedQueryModel.sortList.push(...proccessedReportColumnsSettings.sortList);
						this.reportsPagedQueryModel.filterList.push(...proccessedReportColumnsSettings.filterList);

						this.reportSettingsDataReady = true;

						this.getReportTemplate(response.templateId);
					}
				});

				this.subscriptions.push(getReportSettingsSubscription$);
				break;
		}
	}

	onAvailableRolesValueChange(role: AvailableRoleModel): void {
		if (role.isChecked) {
			this.addReportModel.availableRolesIds.push(role.roleId);
		} else {
			const index = this.addReportModel.availableRolesIds.findIndex(d => d === role.roleId);

			if (index !== -1) {
				this.addReportModel.availableRolesIds.splice(index, 1);
			}
		}
	}

	customizeReport(): void {
		this.showReportView = true;
		this.reportDataReady = false;
		this.reportData = null;

		this.reportsPagedQueryModel = new PagedQueryParameter({
			page: 1,
			itemsPerPage: 10,
			filterList: [],
			sortList: []
		});

		this.reportColumns = this.reportTemplateColumns[this.addReportModel.templateId!];
		this.getReportTemplate(this.addReportModel.templateId!);
	}

	setGridFeatures(): void {
		Object.entries(this.gridFeatures).forEach(entry => {
			const [, value] = entry;
			value.afterSetValue = value.initialValue;
		});
	}

	mainPageChange(page: PaginationModel): void {
		this.mainPagedQueryModel.page = page.currentPage;
		this.mainPagedQueryModel.itemsPerPage = page.pageSize;

		this.tableDataReady = false;
		this.tableData = [];

		this.getTableData();
	}

	reportsPageChange(page: PaginationModel): void {
		this.reportsPagedQueryModel.page = page.currentPage;
		this.reportsPagedQueryModel.itemsPerPage = page.pageSize;

		this.reportDataReady = false;
		this.reportData = null;

		this.getReportTemplate(this.addReportModel.templateId!);
	}

	pageSizeChange(pageSize: number): void {
		this.reportsPagedQueryModel.page = 1;
		this.reportsPagedQueryModel.itemsPerPage = pageSize;

		this.reportDataReady = false;
		this.reportData = [];

		this.getReportTemplate(this.addReportModel.templateId!);
	}

	private getReportTemplate(templateId: number): void {
		const getReportTemplateSubscription$ = this.reportingService.getReportTemplate(templateId, this.reportsPagedQueryModel).subscribe({
			next: response => {
				this.reportsPagination = response.page;
				this.reportData = this.getTemplatePaginatedData(response.results);

				this.reportDataReady = true;
			},
			error: () => {
				this.reportDataReady = true;
			}
		});

		this.subscriptions.push(getReportTemplateSubscription$);
	}

	save(): void {

		this.loading = true;

		const model = new ManageReportSettingsDto({
			name: this.addReportModel.name!,
			nationId: this.addReportModel.nationId!,
			description: this.addReportModel.description,
			rolesList: this.addReportModel.availableRolesIds,
			reportTemplateId: this.addReportModel.templateId!,
			reportSettingsDetails: new ReportSettingsDetailsDto({
				canSort: this.gridFeatures[0].afterSetValue,
				canFilter: this.gridFeatures[1].afterSetValue,
				canHideColumns: this.gridFeatures[2].afterSetValue,
				canExport: this.gridFeatures[3].afterSetValue,
				canReorderColumns: this.gridFeatures[4].afterSetValue,
				columns: this.reportColumns
					.map(c => {
						const currentColumnName = c.name.replace(/\s/g, '');
						const currentColumnFilters = this.reportsPagedQueryModel.filterList.find(d => d.columnName === currentColumnName);

						return new ReportSettingsColumnDto({
							name: currentColumnName,
							order: c.orderNumber,
							show: c.isShown,
							sortDirection: c.sortDirection,
							sortOrder: c.sortOrder,
							filterOperator: currentColumnFilters?.agregateOperator,
							filters: currentColumnFilters?.filterValue.flatMap(f => new FilterDto({
								operator: f.operator,
								value: f.value
							}))
						});
					})
			})
		});

		if (!this.selectedTableItem.id) {
			const createReportSettingsSubscription$ = this.reportsManagementService.createReportSettings(model).subscribe({
				next: response => {
					toast('Success', `Report ${response.name} successfully added!`, 'green');
					this.loading = false;

					this.tableDataReady = false;
					this.tableData = [];
					this.changeView(ReportManagementViews.Main);

					this.getTableData();
				},
				error: (response: any) => {
					this.loading = false;

					const errors: string[] = flatten(Object.values(response));
					toast('Error', `Save action encountered a problem! ${errors[0]}!`, 'red');
				}
			});

			this.subscriptions.push(createReportSettingsSubscription$);
		} else {
			const updateReportSettingsSubscription$ = this.reportsManagementService.updateReporSettings(this.selectedTableItem.id, model).subscribe({
				next: response => {
					toast('Success', `Report ${response.name} successfully updated!`, 'green');
					this.loading = false;

					this.tableDataReady = false;
					this.tableData = [];
					this.changeView(ReportManagementViews.Main);

					this.getTableData();
				},
				error: (response: any) => {
					this.loading = false;

					const errors: string[] = flatten(Object.values(response));
					toast('Error', `Update action encountered a problem! ${errors[0]}!`, 'red');
				}
			});

			this.subscriptions.push(updateReportSettingsSubscription$);
		}
	}

	exportExcelTemplate(): void {
		this.loading = true;
		const model = new ExportDataRequest({
			reportName: this.addReportModel.name ? this.addReportModel.name : '',
			columns: this.reportColumns.map(d => d.name.replace(/\s/g, '')),
			filterList: this.reportsPagedQueryModel.filterList,
			sortList: this.reportsPagedQueryModel.sortList
		});

		const exportReportTemplateSubscription$ = this.reportingService.exportReportTemplate(this.addReportModel.templateId!, model).subscribe({
			next: response => {
				const blob: Blob = response.body as Blob;
				const fileName = response.headers.get('Content-Disposition')?.replace(/['"]+/g, '').split(';')[1].trim().split('=')[1];

				const anchor = document.createElement('a');
				anchor.download = fileName;
				anchor.href = window.URL.createObjectURL(blob);
				anchor.click();

				this.loading = false;
				toast('Success', 'File exported successfully!', 'green');
			},
			error: () => {
				this.loading = false;
				toast('Error', 'Failed to export file!', 'red');
			}
		});

		this.subscriptions.push(exportReportTemplateSubscription$);
	}

	sortByCol(column: ReportColumnSortDirection): void {
		this.reportDataReady = false;
		this.reportData = null;
		this.processColumnSortation(column, this.reportColumns, this.reportsPagedQueryModel);

		this.getReportTemplate(this.addReportModel.templateId!);
	}

	filterChange(value: FilterChangeModel): void {
		this.reportDataReady = false;
		this.reportData = null;
		this.proccessColumnFilters(value, this.reportsPagedQueryModel);

		this.getReportTemplate(this.addReportModel.templateId!);
	}
}
