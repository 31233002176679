export class JsonMapperSettings {
	stopOnError = false;
	showErrorTips = true;
	ignoreMissingProperties = false;
	ignorePrimitiveTypeChecks = false;
	allowNullValues = false;
	ignoreNullValues = false;
	allowUndefinedValues = false;
	ignoreUndefinedValues= false;
	
	constructor(data: Partial<JsonMapperSettings> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}