<h3 class="ui header">
	<i class="sliders horizontal icon"></i>
	<div class="content">
		Admin Adjustements
		<div class="sub header">Rations</div>
	</div>
</h3>
<br />

<div class="ui form">
	<div class="fields">
		<div class="three wide field" app-field [error]="adminAdjustmentsValidation | path: 'amisCardNumber'">
			<label>AMIS Card Number:</label>
			<input type="text" [(ngModel)]="adminAjustmentsModel.amisCardNumber">
		</div>

		<div class="five wide field">
			<label>Entitlement Type:</label>
			<sm-dropdown class="ui selection dropdown"				
				[class.loading]="!entitlementTypesDropdownValues.length"
				[(value)]="adminAjustmentsModel.entitlementTypeId"
				[options]="entitlementTypesDropdownValues">
				<div class="text"></div>
				<i class="dropdown icon"></i>
			</sm-dropdown>
		</div>

		<div class="three wide field" app-field [error]="adminAdjustmentsValidation | path: 'from'">
			<label>From:</label>
			<sm-calendar class="ui calendar"
				[settings]="calendarSettings"
				[(value)]="adminAjustmentsModel.from"
				[maxValue]="adminAjustmentsModel.to">
				<div class="ui fluid input left icon">
					<i class="calendar icon"></i>
					<input type="text" placeholder="Date" />
				</div>
			</sm-calendar>
		</div>

		<div class="six wide field" app-field [error]="adminAdjustmentsValidation | path: 'to'">
			<label>To:</label>
			<div class="two fields">
				<div class="field">
					<sm-calendar class="ui calendar"
						[settings]="calendarSettings"
						[(value)]="adminAjustmentsModel.to"
						[minValue]="adminAjustmentsModel.from">
						<div class="ui fluid input left icon">
							<i class="calendar icon"></i>
							<input type="text" placeholder="Date" />
						</div>
					</sm-calendar>
				</div>
				<div class="field">
					<button class="ui primary right labeled icon button"
						[class.loading]="!tableDataReady"
						[disabled]="currentView === 1 || !tableDataReady || !isValid"
						(click)="submit()">
						Submit
						<i class="save icon"></i>
					</button>
				</div>
			</div>
		</div>
	</div>

	<p *ngIf="tableData.length">*To adjust a transaction please click on the corresponding icon from the Transaction Quantity column</p>

	<ng-container *ngIf="purchasedItems.length">
		<div class="field">
			<div class="ui divider"></div>
		</div>

		<div *ngIf="currentView === 0" class="fields">
			<div class="field">
				<button class="ui negative basic button" (click)="cancelAjustments()">Cancel</button>
			</div>
			<div class="field">
				<button class="ui positive basic button" (click)="checkPurchaseAmounts()">Adjust</button>
			</div>
		</div>
		<div *ngIf="currentView === 1" class="fields">
			<div class="field">
				<button class="ui negative basic button" (click)="displayEditAdjustmentsView()">Cancel</button>
			</div>
			<div class="field">
				<button class="ui positive basic button" (click)="confirmAdjustments()">Confirm Adjustments</button>
			</div>
		</div>
	</ng-container>

	<div class="row">
		<div class="column">
			<app-table-view
				[itemTemplate]="adminAdjustmentItemTemplate"
				[data]="tableData"
				[noSearch]="true"
				[dataReady]="tableDataReady"
				[columns]="tableColumns">
			</app-table-view>
		</div>
	</div>
</div>

<ng-template #adminAdjustmentItemTemplate let-item="item">
	<tr style="text-align: center;" class="positive">
		<td>
			<a *ngIf="item.attached.length && item.expanded === true" href="javascript:void(0);" data-tooltip="Collapse">
				<i class="minus icon" (click)="item.expanded = false"></i>
			</a>
			<a *ngIf="item.attached.length && item.expanded === false" href="javascript:void(0);" data-tooltip="Expand">
				<i class="plus icon" (click)="item.expanded = true"></i>
			</a>
			{{item.cardNumber}}
		</td>
		<td>{{item.transactionId}}</td>
		<td>{{item.transactionDate}}</td>
		<td>{{item.headquarterName}}</td>
		<td>{{item.entitlementTypeName}}</td>
		<td>
			<span [ngStyle]="{'color': item.transactionBalance === 0 ? 'red' : 'green'}">
				<b>{{item.transactionBalance}}</b>
			</span>
			<b> / {{item.transactionAmount}}</b>
			<a *ngIf="!item.hasPurchase && item.transactionBalance > 0" href="javascript:void(0);" style="margin-left: 15px;" data-tooltip="Adjust Amount">
				<i class="pen icon" (click)="addAdjustment(item)"></i>
			</a>
		</td>
		<td>{{item.measurementUnitName}}</td>
		<td>{{item.transactionTypeName}}</td>
		<td>{{item.transactionStatusName}}</td>
		<td>{{item.notes}}</td>
	</tr>
	<ng-container *ngIf="item.expanded">
		<tr *ngFor="let adjutment of item.attached" class="warning" style="text-align: center;">
			<td>
				<a *ngIf="currentView === 0 && adjutment.transactionId === 0"
					href="javascript:void(0);"
					data-tooltip="Remove Adjustment">
					<i class="times icon" (click)="removeAdjustment(item, adjutment)"></i>
				</a>
				{{adjutment.cardNumber}}
			</td>
			<td>{{adjutment.transactionId === 0 ? '-': adjutment.transactionId}}</td>
			<td>{{adjutment.transactionDate ? adjutment.transactionDate : '-'}}</td>
			<td>{{adjutment.headquarterName}}</td>
			<td>{{adjutment.entitlementTypeName}}</td>

			<td *ngIf="currentView === 0 && adjutment.transactionId === 0">
				<div class="inline field" app-field [error]="transactionAmountValidations[adjutment.transactionBulkId] | path: 'transactionAmount'">
					<label>-</label>
					<input style="max-width: 85px;" type="number" min="1" [(ngModel)]="adjutment.transactionAmount" (blur)="setPurchaseAmount(item, adjutment)">
				</div>
			</td>
			<td *ngIf="currentView === 0 && adjutment.transactionId !== 0">{{adjutment.transactionAmount}}</td>
			<td *ngIf="currentView === 1">- {{adjutment.transactionAmount}}</td>
			<td>{{adjutment.measurementUnitName}}</td>
			<td>{{adjutment.transactionTypeName}}</td>
			<td>{{adjutment.transactionStatusName}}</td>
			<td *ngIf="currentView === 0 && adjutment.transactionId === 0">
				<textarea style="height: 10px; max-height: 60px; min-height: 10px;"
					[(ngModel)]="adjutment.notes"
					app-trim>
				</textarea>
			</td>
			<td *ngIf="currentView === 1 || currentView === 0 && adjutment.transactionId !== 0">{{adjutment.notes}}</td>
		</tr>
	</ng-container>
</ng-template>


<sm-modal [isLoading]="modalIsLoading" #adjustmentsConfimationModal [isTiny]="true">
	<ng-container header>Confirm Adjustments</ng-container>

	<ng-container content>
		<p>Are you really sure you want to save the adjustments from the preview window?</p>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="close()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			(click)="createAdjustment()">
			Save
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>