import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ValidationErrors } from 'fluentvalidation-ts';

import { AuthenticationModel } from '@nstep-common/core';
import { LoginModelValidator } from '@nstep-internal/pages';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html'
})
export class LoginComponent {
	constructor(private router: Router) {
	}

	validateLogin(model: AuthenticationModel): ValidationErrors<AuthenticationModel> {
		const validator = new LoginModelValidator();
		return validator.validate(model);
	}

	afterLogin() {
		this.router.navigate(['']);
	}
}
