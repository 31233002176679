import { Injectable } from '@angular/core';
import { AccountModel, ApiService, AuthenticationModel, AuthenticatorModel, ContactModel, EnableAuthenticatorModel, RefreshTokensModel, TwoFactorModel, ResetPasswordModel } from "@nstep-common/core";
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AccountService {
	constructor(private apiService: ApiService) { }

	logIn(model: AuthenticationModel): Observable<AccountModel> {
		return this.apiService.post(AccountModel, 'account/log-in', model);
	}

	refresh(model: RefreshTokensModel): Observable<RefreshTokensModel> {
		return this.apiService.post(RefreshTokensModel, 'account/refresh', model);
	}

	logOut(): Observable<any> {
		return this.apiService.postNoContent('account/log-out');
	}

	passwordReset(model: ResetPasswordModel): Observable<AccountModel> {
		return this.apiService.post(AccountModel, 'account/password/reset', model);
	}

	getContact(): Observable<ContactModel> {
		return this.apiService.get(ContactModel, 'account/contact');
	}

	updateSettings(model: any): Observable<any> {
		return this.apiService.postNoContent('account/settings', model);
	}

	getTwoFactor(): Observable<TwoFactorModel> {
		return this.apiService.get(TwoFactorModel, 'account/2f');
	}

	disableTwoFactor(): Observable<any> {
		return this.apiService.postNoContent('account/2f/disable');
	}

	getAuthenticator(): Observable<AuthenticatorModel> {
		return this.apiService.get(AuthenticatorModel, 'account/2f/authenticator');
	}

	resetAuthenticator(): Observable<AuthenticatorModel> {
		return this.apiService.post(AuthenticatorModel, 'account/2f/authenticator/reset');
	}

	enableAuthenticator(model: EnableAuthenticatorModel): Observable<any> {
		return this.apiService.postNoContent('account/2f/authenticator/enable', model);
	}
}
