import { JsonType } from "./json-type";

export enum JsonError {
	INVALID_PROVIDED_TYPE = 'Provided type is not an object type',
	UNKNOWN_PROPERTY_TYPE = 'Unable to determine type for property',
	MISSING_SOURCE_PROPERTY = 'Missing property from source',
	UNKNOWN_ARRAY_ITEM_TYPE = 'Unable to determine type for array item',
	INVALID_ARRAY_TYPE = 'Array type not properly specified',
	ARRAY_TYPE_MISSMATCH = 'Array type missmatch',
	OBJECT_TYPE_MISSMATCH = 'Object type missmatch',
	PROPERTY_ATTR_TYPE_MISSMATCH = 'Property type & JsonProperty type missmatch',
	PRIMITIVE_TYPE_MISSMATCH = 'Primitive type missmatch',
	INVALID_PRIMITIVE_VALUE = 'Primitive value invalid',
	NULL_VALUE_NOT_ALLOWED = 'Null value is not allowed',
	UNDEFINED_VALUE_NOT_ALLOWED = 'Undefined value is not allowed'
}

export class JsonErrorFormatter {
	constructor(private showRecommendations: boolean) {		
	}

	get(error: JsonError, targetType?: JsonType | null, valueType?: JsonType | null): string {
		let tip = '';
	
		switch (error) {
			case JsonError.MISSING_SOURCE_PROPERTY: {
				tip = `Specify name with \`JsonProperty({name: <value>})\``
				break;
			}
			case JsonError.UNKNOWN_PROPERTY_TYPE: {
				tip = `Specify type with \`JsonProperty({type: <value>})\``;
				break;
			}
			case JsonError.UNKNOWN_ARRAY_ITEM_TYPE: {
				tip = `Specify type with \`JsonProperty({type: <value>})\``;
				break;
			}
			case JsonError.INVALID_ARRAY_TYPE: {
				tip = `Specify type with \`JsonProperty({type: [<value>], array: true})\``;
				break;
			}
			case JsonError.NULL_VALUE_NOT_ALLOWED: {
				tip = `Override with \`JsonProperty({nullable: true}) or globally from mapper settings (AllowNullValues = true)\``;
				break;
			}
			case JsonError.UNDEFINED_VALUE_NOT_ALLOWED: {
				tip = `Override with \`JsonProperty({undefinable: true}) or globally from mapper settings (AllowUndefinedValues = true)\``;
				break;
			}
		}

		let erroMessage = `${error}`;
	
		if (valueType) {
			erroMessage += `, source is \`${valueType.alias}\``;
		}
	
		if (targetType) {
			erroMessage += `, target is \`${targetType.alias}\``;
		}
	
		erroMessage += '.';
	
		if (tip && this.showRecommendations) {
			erroMessage += ` TIP: ${tip}.`;
		}
	
		return erroMessage;
	}
}