export enum JsonTypeCategory {
	OBJECT = 'OBJECT',
	ARRAY = 'ARRAY',
	PRIMITIVE = 'PRIMITIVE'
}

export class Any {
}

export class JsonType {
	readonly name!: string;
	readonly category!: JsonTypeCategory;

	readonly alias!: string;

	get isAny() {
		return this.name == 'Any';
	}

	get isGenericObject() {
		return this.name == 'object';
	}

	get isObject() {
		return this.category == JsonTypeCategory.OBJECT;
	}

	get isArray() {
		return this.category == JsonTypeCategory.ARRAY;
	}

	get isPrimitive() {
		return this.category == JsonTypeCategory.PRIMITIVE;
	}

	private static primitiveTypes = [Boolean, Number, String, Date];

	private constructor(data: Partial<JsonType>) {
		Object.assign(this, data);
	}

	static fromValue(value: any, key?: string): JsonType {
		const name = JsonType.getTypeName(value);

		return new JsonType({
			name: name,
			category: JsonType.getCategory(value),
			alias: JsonType.getAlias(name, key ?? '', value, true)
		});
	}

	static fromType(type: { new(): any }, key?: string) {
		const name = JsonType.getTypeName(type);

		return new JsonType({
			name: name,
			category: JsonType.getCategory(type),
			alias: JsonType.getAlias(name, key ?? '', undefined, false)
		});
	}

	private static getTypeName(obj: any): string {
		let name = typeof (obj) == 'function' ? obj.name : typeof obj;

		if (this.isPrimitive(obj) || name == 'Object' || name == 'object' || name == 'undefined') {
			name = name.toLowerCase();
		}
		else if (this.isArray(obj)) {
			name = 'Array';
		}
		else {
			name = name[0].toUpperCase() + name.slice(1);
		}

		return name;
	}

	private static getCategory(obj: any): JsonTypeCategory {
		let category = JsonTypeCategory.OBJECT;

		if (JsonType.isPrimitive(obj)) {
			category = JsonTypeCategory.PRIMITIVE;
		}
		else if (JsonType.isArray(obj)) {
			category = JsonTypeCategory.ARRAY;
		}

		return category;
	}

	private static isPrimitive(obj: any): boolean {
		for (const t of this.primitiveTypes) {
			if (obj == t || typeof obj == t.name.toLowerCase()) {
				return true;
			}
		}

		return false;
	}

	private static isArray(obj: any): boolean {
		if (obj === Array) {
			return true;
		} else if (typeof Array.isArray === 'function') {
			return Array.isArray(obj);
		}
		else {
			return !!(obj instanceof Array);
		}
	}

	private static getAlias(typeName: string, key: string, value: any, showEmptyValues: boolean): string {
		let alias = `${key}`;

		if (typeName != 'undefined'){
			alias += `: ${typeName}`;
		}

		const empty = value === null || value === undefined;
		const exclude = typeName == 'object' || value == undefined;

		if (!exclude || empty && showEmptyValues) {
			alias += ` = ${typeName == 'string' && !empty ? '\'' + value + '\'' : value}`;
		}

		return alias;
	}
}