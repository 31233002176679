import { JsonProperty } from "@nstep-common/utils";

export class EntitlementUnitModel {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: Boolean })
	disabled!: boolean;

	constructor(data: Partial<EntitlementUnitModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class EntitlementUnitCreateDto {
	name!: string;
	disabled!: boolean;

	constructor(data: Partial<EntitlementUnitCreateDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}