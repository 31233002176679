import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'sm-modal',
	templateUrl: 'modal.component.html'
})
export class ModalComponent implements AfterViewInit, OnDestroy {
	jQueryElement: any;
	template: any;

	@Input() noClose = false;
	@Input() isMini = false;
	@Input() isTiny = false;
	@Input() isSmall = false;
	@Input() isLarger = false;
	@Input() isBig = false;
	@Input() isFullscreen = false;
	@Input() isScrolling = false;

	@Input() isLoading = false;
	@Input() loadingText = 'Loading';

	@Input() closeIconColor: string | null = null;
	@Output() closeEvent = new EventEmitter();

	isToggled = false;

	constructor(private elementRef: ElementRef) {
	}

	ngAfterViewInit(): void {
		if (this.jQueryElement) {
			return;
		}

		setTimeout(() => {
			this.jQueryElement = $(this.elementRef.nativeElement).find(' > .ui.modal').modal({
				closable: false,
				allowMultiple: true
			});
		});
	}

	toggle() {
		this.jQueryElement.modal('toggle');
		this.isToggled = !this.isToggled;
	}

	show() {
		this.jQueryElement.modal('show');
		this.isToggled = true;
	}

	ngOnDestroy(): void {
		this.jQueryElement?.modal('hide')?.modal('destroy')?.remove();
	}
}