import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { merge } from 'lodash';

@Component({
	selector: 'sm-search',
	template: `<ng-content></ng-content>`
})
export class SearchComponent implements AfterViewInit, OnDestroy {
	jQueryElement: any;

	@Input() settings: any = {};
	@Output() valueChange = new EventEmitter<any>();

	constructor(private elementRef: ElementRef) {
	}

	ngAfterViewInit(): void {
		if (this.jQueryElement) {
			return;
		}

		const settings = merge({
			onSelect: (result: any) => {
				this.valueChange.emit(result);
			}
		}, this.settings);

		setTimeout(() => {
			this.jQueryElement = $(this.elementRef.nativeElement).search(settings);
		});
	}

	ngOnDestroy(): void {
		this.jQueryElement?.search('destroy');
	}
}
