import { Injectable } from '@angular/core';
import { ApiService, PagedQueryParameter, PagedResponse } from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { ImportExportHistoryDto } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SystemService {

	constructor(private apiService: ApiService) { }

	updateTransactions(amisCardNumber: string): Observable<any> {
		return this.apiService.patchNoContent(`system/update-transactions/${amisCardNumber.toUpperCase()}`);
	}

	getExportHistory(model: PagedQueryParameter): Observable<PagedResponse<ImportExportHistoryDto>> {
		return this.apiService.getPage([ImportExportHistoryDto], 'system/export-history', model);
	}

	exportMatrix(matrixId: number): Observable<any> {
		return this.apiService.get(Any, 'system/export-matrix', { observe: 'response', responseType: 'blob', params: { id: matrixId } });
	}

	getImportHistory(model: PagedQueryParameter): Observable<PagedResponse<ImportExportHistoryDto>> {
		return this.apiService.getPage([ImportExportHistoryDto], 'system/import-history', model);
	}
}