import { AfterViewInit, Component, ElementRef, OnDestroy } from '@angular/core';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'sm-accordion',
	template: '<ng-content></ng-content>'
})
export class AccordionComponent implements AfterViewInit, OnDestroy {
	jQueryElement: any;

	constructor(private elementRef: ElementRef) {
	}

	ngAfterViewInit(): void {
		if (this.jQueryElement) {
			return;
		}

		setTimeout(() => {
			this.jQueryElement = $(this.elementRef.nativeElement).accordion({
				exclusive: false
			});
		});
	}

	ngOnDestroy(): void {
		this.jQueryElement?.accordion('destroy');
	}
}