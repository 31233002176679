<table class="ui table"
	[ngClass]="{ 
		'very basic': isBasic, 
		'compact': isCompact, 
		'striped': isStriped, 
		'celled': isCelled,
		'collapsing': isCollapsing,
		'definition': isDefinition
	}"
	style="font-size: 0.96em;">
	<thead>
		<tr *ngIf="groupedColumns.length">
			<th [colSpan]="ungroupedColumns.length">
				<strong>Group By: &nbsp;</strong>
				<ng-container *ngFor="let col of groupedColumns">
					<div class="ui basic label"
						style="padding: 10px 20px;"
						*ngIf="!colData.get(col)?.group?.header">

						<ng-template
							[ngTemplateOutlet]="colTemplateRef"
							[ngTemplateOutletContext]="{ col }">
						</ng-template>
					</div>
				</ng-container>
			</th>
		</tr>
		<tr>
			<th *ngFor="let col of ungroupedColumns"
				[ngStyle]="{'text-align': col.isHeaderCentered ? 'center' : ''}">

				<ng-template
					[ngTemplateOutlet]="colTemplateRef"
					[ngTemplateOutletContext]="{ col }">
				</ng-template>
			</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngIf="!noSearch">
			<td *ngFor="let col of ungroupedColumns"
				style="padding: 0em !important;">

				<div class="ui right labeled fluid input"
					[class.disabled]="!dataReady"
					*ngIf="col.key">

					<input type="text"
						placeholder="Search"
						[disabled]="!dataReady"
						(keyup)="searchByCol(col, { keyword: $any($event).target.value })"
						[value]="colData.get(col)?.searchKeyword">

					<div sm-dropdown
						class="ui dropdown label">
						<div class="text">
							<i class="search icon"></i>
						</div>

						<div class="menu">
							<div class="item"
								(click)="searchByCol(col, { operator: 1 })">
								<i class="file alternate outline icon"></i>
								<label>Contains</label>
							</div>
							<div class="item"
								(click)="searchByCol(col, { operator: 2 })">
								<i class="file excel outline icon"></i>
								<label>Does not contain</label>
							</div>
							<div class="item"
								(click)="searchByCol(col, { operator: 3 })">
								<i class="angle double right icon"></i>
								<label>Starts with</label>
							</div>
							<div class="item"
								(click)="searchByCol(col, { operator: 4 })">
								<i class="angle double left icon"></i>
								<label>Ends with</label>
							</div>
							<div class="item"
								(click)="searchByCol(col, { operator: 5 })">
								<i class="equals icon"></i>
								<label>Equal</label>
							</div>
							<div class="item"
								(click)="searchByCol(col, { operator: 6 })">
								<i class="not equal icon"></i>
								<label>Does not equal</label>
							</div>
							<div class="item"
								(click)="searchByCol(col, { operator: null })">
								<i class="search icon"></i>
								<label>Reset</label>
							</div>
						</div>
					</div>
				</div>
			</td>
		</tr>

		<ng-container *ngFor="let node of treeData; let i = index">
			<ng-container *ngIf="!itemTemplate else renderItem">
				<ng-template
					[ngTemplateOutlet]="nodeTemplate"
					[ngTemplateOutletContext]="{ node }">
				</ng-template>
			</ng-container>

			<ng-template #renderItem>
				<ng-template *ngTemplateOutlet="itemTemplate; context: { item: node.data, index: i }"></ng-template>
			</ng-template>
		</ng-container>

		<tr *ngIf="!treeData.length">
			<td style="text-align: center; padding: 0"
				[colSpan]="ungroupedColumns.length"
				[ngStyle]="{'padding': dataReady ? '10px' : '0'}"
				app-loading
				[enabled]="!dataReady">
				No data available.
			</td>
		</tr>
	</tbody>
	<tfoot>
		<tr>
			<th [colSpan]="ungroupedColumns.length">
				<div class="ui right floated pagination menu">
					<a class="icon item"
						[class.disabled]="currentPage === 1"
						(click)="changePage(currentPage - 1)">
						<i class="left chevron icon"></i>
					</a>
					<a class="item"
						[class.active]="page === currentPage"
						*ngFor="let page of pages"
						(click)="changePage(page)">
						<span class="ui text"
							[class.primary]="page == currentPage"
							[ngStyle]="{'font-weight': page == currentPage ? 'bolder' : 'lighter' }">
							{{page}}
						</span>
					</a>
					<a class="icon item"
						[class.disabled]="totalPages === 0 || currentPage === totalPages"
						(click)="changePage(currentPage + 1)">
						<i class="right chevron icon"></i>
					</a>
				</div>
			</th>
		</tr>
	</tfoot>
</table>

<ng-template #colTemplateRef let-col="col">
	<strong>{{ col.name }}&nbsp;&nbsp;</strong>

	<ng-container *ngIf="col.key">
		<span class="ui text"
			[class.primary]="colData.get(col)?.sortAsc !== null">
			<i class="arrow icon"
				style="font-size: 1.2em; cursor: pointer; margin-right: 0.25em; width: 1.18em"
				[class.disabled]="!dataReady"
				[ngClass]="{ 
					'down': colData.get(col)?.sortAsc === false, 
					'up': colData.get(col)?.sortAsc === true, 
					'sort': colData.get(col)?.sortAsc === null 
				}"
				(click)="sortByCol(col)">
			</i>
		</span>

		<div class="ui dropdown app-filter-dropdown"
			#dropdown
			[class.disabled]="!dataReady">
			<span class="ui text"
				*ngIf="colData.get(col)?.filter"
				[class.primary]="colData.get(col)?.selectedValues">
				<i class="filter icon" style="margin-right: 3px"></i>
				<small *ngIf="colData.get(col)?.selectedValues">({{colData.get(col)?.selectedValues}})</small>
			</span>

			<div class="menu">
				<div class="ui left icon input">
					<input type="text"
						placeholder="Search"
						ngModel
						#searchColValue="ngModel">
					<i class="search icon"></i>
				</div>

				<div class="scrolling menu"
					*ngIf="((colData.get(col)?.values || []) | searchText: searchColValue.value : 'text') as values">
					<ng-container *ngFor="let value of values, let i = index">
						<div class="item"
							*ngIf="!value.hiddenAt"
							(click)="value.selected = !value.selected; $event.stopPropagation();">
							<div class="ui checkbox">
								<input type="checkbox" [(ngModel)]="value.selected" />
								<label style="cursor: pointer; font-size: 0.96em;">
									{{value.text || '- N/A -'}}
								</label>
							</div>
						</div>
					</ng-container>

					<div class="item" *ngIf="!values.length">
						<label>No results found.</label>
					</div>
				</div>

				<div class="divider"></div>

				<div class="header">
					<button class="ui basic small button"
						(click)="resetCol(col, true)">
						ALL
					</button>
					<button class="ui basic small button"
						(click)="resetCol(col, false)">
						NONE
					</button>
					<button class="ui basic small button"
						style="float: right;"
						(click)="filterByColEl(dropdown, col)">
						OK
					</button>
				</div>
			</div>
		</div>
	</ng-container>
</ng-template>

<ng-template #nodeTemplate let-node="node" let-group="group">
	<ng-container *ngIf="node.key">
		<tr>
			<td style="background-color: rgba(0, 0, 50, 0.07) !important;"
				[colSpan]="ungroupedColumns.length"
				[ngStyle]="{ 'padding-left': 5 + (node.level * 20) + 'px' }">
				<i class="caret icon"
					style="cursor: pointer;"
					[ngClass]="{ 'down': !node.collapsed, 'right': node.collapsed }"
					(click)="node.collapsed = !node.collapsed">
				</i>

				{{ node.key || '- N/A -' }}
			</td>
		</tr>
	</ng-container>

	<ng-container *ngIf="!node.key">
		<tr>
			<ng-container *ngFor="let col of ungroupedColumns, let colIndex = index">
				<ng-container *ngIf="!col.template else renderCell">
					<ng-container *ngIf="col.key && (node.collapsed || colData.get(col)?.group?.keys?.length && colData.get(col)?.group?.keys?.includes(col.key))">
						<td *ngIf="!colData.get(col)?.group || (colData.get(col)?.group?.header && !group); else hiddenCell"
							[attr.rowspan]="!node.collapsed && colData.get(col)?.group?.header ? node.children.length + 1 : null"
							[ngStyle]="{ 
								'padding-left': colIndex || !node.level ? null : (5 + (node.level * 20) + 'px'), 
								'text-align': col.isCellCentered ? 'center' : ''
							}">

							<i class="primary square outline icon"
								style="cursor: pointer;"
								[ngClass]="{ 'minus': !node.collapsed, 'plus': node.collapsed }"
								(click)="node.children.length && node.collapsed = !node.collapsed"
								*ngIf="colIndex == 0 && node.children.length > 1">
							</i>

							{{ node.data[col.key] }}
						</td>

						<ng-template #hiddenCell>
							<td class="rowspanned"></td>
						</ng-template>
					</ng-container>
				</ng-container>

				<ng-template #renderCell>
					<ng-template *ngTemplateOutlet="col.template!; context: { item: node.data }"></ng-template>
				</ng-template>
			</ng-container>
		</tr>
	</ng-container>

	<ng-container *ngIf="!node.collapsed">
		<ng-template ngFor [ngForOf]="node.children" let-child>
			<ng-template [ngTemplateOutlet]="nodeTemplate"
				[ngTemplateOutletContext]="{ node: child, group: !node.key && node.children.length }">
			</ng-template>
		</ng-template>
	</ng-container>
</ng-template>