/* Global */
import 'fomantic-ui-less/definitions/globals/site';

/* Behaviors */
import 'fomantic-ui-less/definitions/behaviors/api';
//import 'fomantic-ui-less/definitions/behaviors/form';
//import 'fomantic-ui-less/definitions/behaviors/state';
//import 'fomantic-ui-less/definitions/behaviors/visibility';

/* Modules */
import 'fomantic-ui-less/definitions/modules/accordion';
import 'fomantic-ui-less/definitions/modules/calendar';
import 'fomantic-ui-less/definitions/modules/checkbox';
import 'fomantic-ui-less/definitions/modules/dimmer';
import 'fomantic-ui-less/definitions/modules/dropdown';
//import 'fomantic-ui-less/definitions/modules/embed';
//import 'fomantic-ui-less/definitions/modules/flyout';
import 'fomantic-ui-less/definitions/modules/modal';
//import 'fomantic-ui-less/definitions/modules/nag';
import 'fomantic-ui-less/definitions/modules/popup';
//import 'fomantic-ui-less/definitions/modules/progress';
//import 'fomantic-ui-less/definitions/modules/rating';
import 'fomantic-ui-less/definitions/modules/search';
//import 'fomantic-ui-less/definitions/modules/shape';
//import 'fomantic-ui-less/definitions/modules/sidebar';
//import 'fomantic-ui-less/definitions/modules/slider';
//import 'fomantic-ui-less/definitions/modules/sticky';
import 'fomantic-ui-less/definitions/modules/tab';
import 'fomantic-ui-less/definitions/modules/toast';
import 'fomantic-ui-less/definitions/modules/transition';