import { AuthenticationModel } from '@nstep-common/core';
import { ExtraValidator } from '@nstep-common/utils';

export class LoginModelValidator extends ExtraValidator<AuthenticationModel> {
	constructor() {
		super();

		this.ruleFor('userName')
			.must(userName => !!userName)
			.withMessage('The User name field is required.');

		this.ruleFor('password')
			.must(password => !!password)
			.withMessage('The Password field is required.');
	}
}