import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "@nstep-common/core";

export function authGuard(routePermissions: { [route: string]: string[] }): CanActivateFn {
	return (_, state: RouterStateSnapshot): boolean => {
		const router = inject(Router);
		const authService = inject(AuthService);

		const permissions = routePermissions[state.url.substring(1)] ?? [];

		let redirectUrl = !authService.JWT
			? 'login'
			: !authService.hasAccess(...permissions) 
				? authService.JWT.role === 'Operator' ? 'login/operator' : 'login'
				: null;

		if (redirectUrl) {
			router.navigate([redirectUrl]);
			return false;
		}

		return true;
	};
}
