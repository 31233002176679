import { ExtraValidator } from '@nstep-common/utils';
import { UpdateTransactionsModel } from '@nstep-internal/pages';

export class UpdateTransactionsModelValidator extends ExtraValidator<UpdateTransactionsModel> {
	constructor() {
		super();

		this.ruleFor('amisCardNumber')
			.matches(/^([A-Za-z]{3})(-[0-9]{7})(-[0-9]{2})$/)
			.withMessage('Please provide a valid card number');
	}
}