import { isArray, isDate, isObject } from 'lodash';

export type ProxyCallbacks = {
	get: (target: object, key: string | symbol, path: string) => any;
	set: (target: object, key: string | symbol, value: any, path: string) => void
};

export function createProxy<T extends object>(model: T, callbacks: Partial<ProxyCallbacks>): T {
	const scope = { init: true };
	const proxy = _createProxy<T>(model, callbacks, '', isArray(model), scope);
	scope.init = false;

	return proxy;
}

function _createProxy<T extends object>(model: T, callbacks: Partial<ProxyCallbacks>, path: string, arrayParent: boolean, scope: any): T {
	for (const key of Object.keys(model)) {
		(model as any)[key] = _createNestedProxy((model as any)[key], key, callbacks, path, arrayParent, scope);
	}

	return new Proxy(model, {
		get(target, key): any {
			if (callbacks.get) {
				return callbacks.get(target, key, path ? (path + '.' + key.toString()) : key.toString());
			}

			return (target as any)[key];
		},
		set(target, key, value): boolean {
			if (scope.init) {
				(target as any)[key] = value;
			}
			else {
				scope.init = true;
				(target as any)[key] = _createNestedProxy(value, key, callbacks, path, arrayParent, scope);
				scope.init = false;
			}

			if (callbacks.set) {
				callbacks.set(target, key, value, path ? (path + '.' + key.toString()) : key.toString());
			}

			return true;
		}
	});
}

function _createNestedProxy<T extends object>(value: T, key: string | symbol, callbacks: Partial<ProxyCallbacks>, path: string, arrayParent: boolean, scope: any): T {
	if (!isObject(value) || isDate(value)) {
		return value;
	}

	let newPath = path;

	if (arrayParent) {
		newPath += `[${key.toString()}]`;
	}
	else if (path) {
		newPath += `.${key.toString()}`;
	}
	else {
		newPath = key.toString();
	}

	return _createProxy(value, callbacks, newPath, isArray(value), scope);
}

