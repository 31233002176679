import { JsonProperty } from '@nstep-common/utils';

export class PosDeviceModel {

	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number })
	posLocationId!: number;

	@JsonProperty({ type: String, nullable: true })
	posLocationName!: string | null;

	@JsonProperty({ type: Boolean })
	isActive!: boolean;

	@JsonProperty({ type: String })
	posDeviceName!: string;

	@JsonProperty({ type: String })
	deviceIdentifier!: string;
}

export class ShortPosLocationInformationDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;
}