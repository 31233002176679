import { Directive, HostListener, ElementRef, OnInit, Optional, Self } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
	selector: '[app-trim]'
})
export class TrimDirective implements OnInit {

	constructor(private el: ElementRef, @Optional() @Self() private model: NgModel) { }

	ngOnInit() {
		this.trimValue();
	}

	@HostListener('input', ['$event.target.value'])
	onInput(value: string) {
		this.trimValue(true);
	}

	@HostListener('blur', ['$event.target.value'])
	onBlur(value: string) {
		this.trimValue();
	}

	private trimValue(start: boolean = false) {
		let value: string = this.el.nativeElement.value;
		if (value !== null && value !== undefined) {
			let trimmedValue = start ? value.trimStart() : value.trim();

			if (value !== trimmedValue) {
				this.el.nativeElement.value = trimmedValue;
				this.model.update.emit(trimmedValue);
			}
		}
	}
}