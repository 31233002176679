export class ReportingTableItem {
	id: number | null = null;
	name: string | null = null;
	availableRoles: string[] | null = [];
	country: string | null = null;
	description: string | null = null;
	actions: ReportsManagementActions[] | null = null;

	constructor(data: Partial<ReportingTableItem> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export enum ReportsManagementActions {
	Edit = 'Edit',
	Copy = 'Copy',
	Delete = 'Delete'
}