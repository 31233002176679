import { TemplateRef } from "@angular/core";
import { TableGroup } from "@nstep-common/core";

export interface TableColumn {
	name: string,
	key?: string,
	isHeaderCentered?: boolean,
	isCellCentered?: boolean,
	sortAsc?: boolean,
	filter?: boolean,
	template?: TemplateRef<any>
}

export class TableColumnData {
	id: number;
	sortAsc: boolean | null = null;
	filter: boolean | null = null;
	values: any[] = [];
	selectedValues = 0;
	searchKeyword: string | null = null;
	searchOperator: number | null = null;
	sortUpdatedAt: Date | null = null;
	filterUpdatedAt: Date | null = null;
	group: TableGroup | null = null;

	constructor(data: Partial<TableColumnData> | null = null) {
		if (data) {
			Object.assign(this, data);
		}

		this.id = Math.floor(Math.random() * Math.pow(10, 15));
	}
}