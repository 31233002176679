<h3 class="ui header">
	<i class="users icon"></i>
	<div class="content">
		Users
		<div class="sub header">Membership</div>
	</div>
</h3>
<br />

<div class="ui stackable grid">
	<div class="row">
		<div class="three wide column">
			<div class="ui icon fluid input">
				<i class="search link icon" (click)="search()"></i>
				<input type="text"
					placeholder="Search..."
					[(ngModel)]="pagedQueryModel.searchBy"
					(keydown.enter)="search()">
			</div>
		</div>
		<div class="thirteen wide column">
			<button class="ui right floated primary button"
				(click)="openUserModal()">
				<i class="plus icon"></i>Add User
			</button>
		</div>
	</div>

	<div class="row">
		<div class="column">
			<app-table-view
				[itemTemplate]="userItemTemplate"
				[data]="tableData"
				[dataReady]="tableDataReady"
				[columns]="tableColumns"
				[noSearch]="true"
				[userServerPagination]="true"
				[pageSize]="pagination.pageSize"
				[currentPage]="pagination.currentPage"
				[totalPages]="pagination.totalPages"
				(pageChangeEvent)="pageChange($event)">
			</app-table-view>
		</div>
	</div>
</div>

<ng-template #userItemTemplate let-item="item">
	<tr style="text-align: center;">
		<td>
			<a href="javascript:void(0);" [ngStyle]="{'color': item.disabled ? 'red' : 'green'}" (click)="onUserAction(item, 'Edit')">{{item.userName | dash}}</a>
		</td>
		<td>{{item.name | dash}}</td>
		<td>{{item.nation | dash}}</td>
		<td>{{item.type | dash}}</td>
		<td>{{item.telephone | dash}}</td>
		<td>{{item.other | dash}}</td>
		<td>{{item.startDate | date: 'dd/MM/yyyy' | dash}}</td>
		<td>{{item.endDate | date: 'dd/MM/yyyy' | dash}}</td>
		<!--<td>
			<div class="ui small toggle checkbox">
				<input type="checkbox" 
					[(ngModel)]="item.requires2F"
					(ngModelChange)="toggle2F(item.id, item.requires2F)" />
				<label>{{item.requires2F ? 'Yes' : 'No'}}</label>
			</div>
		</td>-->
		<td>
			<div class="ui small toggle checkbox">
				<input type="checkbox"
					[(ngModel)]="item.isHardLocked"
					(ngModelChange)="toggleLock(item.id, item.isHardLocked)" />
				<label>{{item.isHardLocked ? 'Yes' : 'No'}}</label>
			</div>
		</td>
		<td>
			<ng-container *ngFor="let action of item.actions; let isLast=last">
				<a href="javascript:void(0);" (click)="onUserAction(item, action)">{{ action | dash }}</a>
				<ng-container *ngIf="!isLast"> &#47; </ng-container>
			</ng-container>
		</td>
	</tr>
</ng-template>

<sm-modal [isLoading]="modalIsLoading" #userModal (closeEvent)="close('userModal')">
	<ng-container header>{{editModal ? 'Modify User: ' + userModel.userName : 'Add User'}}</ng-container>

	<ng-container content>
		<div class="ui form two column grid">
			<div class="column">
				<div class="field">
					<label>Role*</label>
					<sm-dropdown class="ui fluid selection dropdown label"
						[class.loading]="!roleDropdownDataReady"
						[class.disabled]="editModal"
						[(value)]="userModel.roleId"
						[options]="roleDropdownValues"
						(valueChange)="onRoleValueChange()">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<div class="field" *ngIf="userModel.roleId === 4" app-field [error]="validation | path: 'subRolesIds'">
					<label>Sub Roles*</label>
					<sm-multiple-select class="ui fluid multiple selection dropdown label"
						[class.loading]="!subRoleDropdownDataReady"
						[(values)]="userModel.subRolesIds"
						[options]="subRoleDropdownValues">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-multiple-select>
				</div>

				<div class="field" app-field [error]="validation | path: 'userName'">
					<label>Username*</label>
					<div class="ui fluid input">
						<input type="text" [disabled]="editModal" [(ngModel)]="userModel.userName" app-trim>
					</div>
				</div>

				<div class="field" app-field [error]="validation | path: 'name'">
					<label>Name*</label>
					<div class="ui fluid input">
						<input type="text" [(ngModel)]="userModel.name" app-trim>
					</div>
				</div>

				<div class="field" *ngIf="!editModal">
					<h4>
						<span [ngStyle]="{typed: {color: '#428bca', cursor: 'pointer'}, auto: {color: 'black'}}[passwordType]" (click)="changePasswordType('auto', 'userModal')">Auto-generated password</span> |
						<span [ngStyle]="{typed: {color: 'black'}, auto: {color: '#428bca', cursor: 'pointer'}}[passwordType]" (click)="changePasswordType('typed', 'userModal')">Type password</span>
					</h4>

					<div class="ui fluid input" *ngIf="passwordType==='auto'">
						<input type="text" placeholder="Temporary password will be set and sent by email" readonly>
					</div>

					<div class="field" app-field [error]="validation | path: 'password'" *ngIf="passwordType==='typed'">
						<div class="ui fluid input">
							<input type="text"
								style="-text-security:disc; -webkit-text-security:disc;"
								autoComplete="off"
								[(ngModel)]="userModel.password">
						</div>
					</div>
				</div>

				<div class="field" app-field [error]="validation | path: 'email'">
					<label>Email address*</label>
					<div class="ui fluid input">
						<input type="text" [(ngModel)]="userModel.email">
					</div>
				</div>

				<div class="field" app-field [error]="validation | path: 'telephone'">
					<label>Telephone*</label>
					<div class="ui fluid input">
						<input type="text" [(ngModel)]="userModel.telephone">
					</div>
				</div>

				<div class="field" app-field [error]="validation | path: 'startDate'">
					<label>Start Date*</label>
					<sm-calendar class="ui calendar"
						[settings]="calendarSettings"
						[(value)]="userModel.startDate"
						[maxValue]="userModel.endDate">
						<div class="ui fluid input left icon">
							<i class="calendar icon"></i>
							<input type="text" placeholder="Date" />
						</div>
					</sm-calendar>
				</div>

				<div class="field" app-field [error]="validation | path: 'endDate'">
					<label>End Date</label>
					<sm-calendar class="ui calendar"
						[settings]="calendarSettings"
						[(value)]="userModel.endDate"
						[minValue]="userModel.startDate">
						<div class="ui fluid input left icon">
							<i class="calendar icon"></i>
							<input type="text" placeholder="Date" />
						</div>
					</sm-calendar>
				</div>
			</div>

			<div class="column">
				<div class="field" *ngIf="userModel.roleId === 3 || userModel.roleId === 5" app-field [error]="validation | path: 'nationId'">
					<label>Country*</label>
					<sm-dropdown class="ui fluid selection dropdown label"
						[class.loading]="!countryDropdownDataReady"
						[(value)]="userModel.nationId"
						[options]="countryDropdownValues"
						(valueChange)="onCountryValueChange()">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-dropdown>
				</div>

				<div class="field" *ngIf="userModel.roleId === 3 || userModel.roleId === 5" app-field [error]="validation | path: 'headquartersIds'">
					<label>HQs*</label>
					<sm-multiple-select class="ui fluid multiple selection dropdown label"
						[class.disabled]="userModel.nationId == null"
						[(values)]="userModel.headquartersIds"
						[options]="headquartersDropdownValues">
						<div class="text"></div>
						<i class="dropdown icon"></i>
					</sm-multiple-select>
				</div>

				<div class="field" *ngIf="userModel.roleId === 4" app-field [error]="validation | path: 'cardOperatorId'">
					<label>AMIS Card Number*</label>
					<sm-search class="ui search"
						(valueChange)="onAmisCardValueChange($event)"
						[settings]="searchSettings">
						<div class="ui left icon input">
							<input class="prompt" type="text" placeholder="Search Amis Card Number">
							<i class="database icon"></i>
						</div>
					</sm-search>
				</div>

				<div class="field" app-field [error]="validation | path: 'other'">
					<label>Other Information</label>
					<div class="ui fluid input">
						<textarea [(ngModel)]="userModel.other" app-trim></textarea>
					</div>
				</div>
			</div>
		</div>

		<app-error-message [errors]="errors"></app-error-message>

		<h4>All fields marked with * are mandatory</h4>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="close('userModal')">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="save()">
			Save
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal [isLoading]="modalIsLoading" #enableUserConfimationModal [isTiny]="true">
	<ng-container header>Enable User - {{selectedUser.userName}}</ng-container>

	<ng-container content>
		<p>Are you sure you want to enable user <b>{{selectedUser.userName}}</b>?</p>

		<app-error-message [errors]="errors"></app-error-message>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="close('enableUserConfimationModal')">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			(click)="enableOrDisable('Enable')">
			Enable
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal [isLoading]="modalIsLoading" #disableUserConfimationModal [isTiny]="true">
	<ng-container header>Disable User: {{selectedUser.userName}}</ng-container>

	<ng-container content>
		<div class="ui form">
			<div class="field" app-field [error]="disableValidation | path: 'endDate'">
				<label>End Date*</label>
				<sm-calendar class="ui calendar"
					[settings]="calendarSettings"
					[(value)]="disableUserModel.endDate">
					<div class="ui fluid input left icon">
						<i class="calendar icon"></i>
						<input type="text" placeholder="Date" />
					</div>
				</sm-calendar>
			</div>
		</div>

		<app-error-message [errors]="errors"></app-error-message>

		<h4>All fields marked with * are mandatory</h4>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="close('disableUserConfimationModal')">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="enableOrDisable('Disable')">
			Disable
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>

<sm-modal [isLoading]="modalIsLoading" #resetPasswordModal [isTiny]="true">
	<ng-container header>Reset Password for User: {{selectedUser.userName}}</ng-container>

	<ng-container content>
		<div class="ui form">
			<div class="field">
				<h4>
					<span [ngStyle]="{typed: {color: '#428bca', cursor: 'pointer'}, auto: {color: 'black'}}[passwordType]" (click)="changePasswordType('auto', 'resetPasswordModal')">Auto-generated password</span> |
					<span [ngStyle]="{typed: {color: 'black'}, auto: {color: '#428bca', cursor: 'pointer'}}[passwordType]" (click)="changePasswordType('typed', 'resetPasswordModal')">Type password</span>
				</h4>

				<div class="ui fluid input" *ngIf="passwordType==='auto'">
					<input type="text" placeholder="Temporary password will be set and sent by email" readonly>
				</div>

				<div class="field" app-field [error]="passwordResetValidation | path: 'password'" *ngIf="passwordType==='typed'">
					<div class="ui fluid input">
						<input type="text"
							style="-text-security:disc; -webkit-text-security:disc;"
							autoComplete="off"
							[(ngModel)]="resetPasswordModel.password">
					</div>
				</div>
			</div>

			<div class="field" app-field [error]="passwordResetValidation | path: 'email'">
				<label>Email address*</label>
				<div class="ui fluid input">
					<input type="text" [(ngModel)]="resetPasswordModel.email">
				</div>
			</div>
		</div>

		<app-error-message [errors]="errors"></app-error-message>

		<h4>All fields marked with * are mandatory</h4>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="close('resetPasswordModal')">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="resetPassword()">
			Reset Password
			<i class="save icon"></i>
		</button>
	</ng-container>
</sm-modal>