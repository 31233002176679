import { ExtraValidator } from '@nstep-common/utils';
import { MatrixAdministrationCell, MatrixAdministrationModel } from '@nstep-internal/pages';

export class MatrixAdministrationCellValidator extends ExtraValidator<MatrixAdministrationCell> {
	constructor() {
		super();

		this.ruleFor('amount')
			.notNull()
			.withMessage('Value must be a positive number of maximum 5 digits!')
			.inclusiveBetween(0, 99999)
			.withMessage('Value must be a positive number of maximum 5 digits!')
			.when(model => model.checked);
	}
}

export class MatrixAdministrationModelValidator extends ExtraValidator<MatrixAdministrationModel> {
	constructor() {
		super();

		this.ruleFor('entitlementCodeId')
			.must(entitlementCodeId => !!entitlementCodeId)
			.withMessage('Code is required');

		this.ruleFor('entitlementCodeGroup')
			.must(entitlementCodeGroup => !!entitlementCodeGroup)
			.withMessage('Group is required')
			.matches(/^.{2,100}$/)
			.withMessage('A valid input is required with the length between 2-100 characters!');

		this.ruleFor('entitlementCodeDescription')
			.must(entitlementCodeDescription => !!entitlementCodeDescription)
			.withMessage('Description is required')
			.matches(/^.{2,100}$/)
			.withMessage('A valid input is required with the length between 2-100 characters!');
	}
}