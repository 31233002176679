import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionComponent, CalendarComponent, DropdownComponent, ModalComponent, MultipleSelectComponent, SearchComponent, SimpleDropdownComponent } from '@nstep-common/semantic-ui';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		DropdownComponent,
		ModalComponent,
		AccordionComponent,
		CalendarComponent,
		MultipleSelectComponent,
		SearchComponent,
		SimpleDropdownComponent
	],
	exports: [
		DropdownComponent,
		ModalComponent,
		AccordionComponent,
		CalendarComponent,
		MultipleSelectComponent,
		SearchComponent,
		SimpleDropdownComponent
	]
})
export class SemanticUiModule { }