import { Injectable } from '@angular/core';
import { ApiService } from '@nstep-common/core';
import { ClientModel, ClientSummaryDto } from '@nstep-internal/pages';
import { Observable, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ClientsService {

	constructor(private apiService: ApiService) { }

	getClients(): Observable<ClientSummaryDto[]> {
		return this.apiService.get([ClientSummaryDto], 'clients');
	}

	getClient(id: number): Observable<ClientModel> {
		return this.apiService.get(ClientModel, `clients/${id}`);
	}

	createClient(model: ClientModel): Observable<ClientModel> {
		return this.apiService.post(ClientModel, 'clients', model)
			.pipe(tap(() => this.invalidateCache()));
	}

	updateClient(model: ClientModel): Observable<any> {
		return this.apiService.putNoContent(`clients/${model.id}`, model)
			.pipe(tap(() => this.invalidateCache(model.id)));
	}

	deleteClient(id: number): Observable<any> {
		return this.apiService.deleteNoContent(`clients/${id}`)
			.pipe(tap(() => this.invalidateCache(id)));
	}

	toggleClient(id: number): Observable<any> {
		return this.apiService.postNoContent(`clients/${id}/toggle`)
			.pipe(tap(() => this.invalidateCache(id)));
	}

	invalidateCache(id: number | null = null) {
		this.apiService.invalidateCache('clients');

		if (id) {
			this.apiService.invalidateCache(`clients/${id}`);
		}
	}
}
