import { ExtraValidator } from '@nstep-common/utils';
import { NonRationedTransactionModel } from '@nstep-internal/pages';

export class NonRationedTransactionModelValidator extends ExtraValidator<NonRationedTransactionModel> {
	constructor() {
		super();

		this.ruleFor('from')
			.must(from => !!from)
			.withMessage('Please provide the start date')
			.must((value, model) => {

				if (!model.to) {
					return true;
				}

				if (model.from && model.to) {
					return new Date(model.from.toDateString()) <= new Date(model.to.toDateString());
				}

				return false;
			})
			.withMessage('Starting date cannot be greater than the end date');

		this.ruleFor('to')
			.must(to => !!to)
			.withMessage('Please provide the end date');
	}
}