import { ExtraValidator } from '@nstep-common/utils';
import { ClientModel } from '@nstep-internal/pages';

export class ClientValidator extends ExtraValidator<ClientModel> {
	constructor() {
		super();

		this.ruleFor('name')
			.notEmpty()
			.withMessage('Name is required')
			.minLength(5)
			.withMessage('Name length needs to be at least 5');

		this.ruleFor('clientId')
			.notEmpty()
			.withMessage('Client Id is required')
			.length(36, 36)
			.withMessage('Client secret length needs to be 36');

		this.ruleFor('clientSecret')
			.notEmpty()
			.withMessage('Client secret is required')
			.length(36, 36)
			.withMessage('Client secret length needs to be 36');

		this.ruleFor('posLocationId')
			.notNull()
			.withMessage('POS location is required')
			.when(m => m.type == 1);

		this.ruleFor('registerUrl')
			.must(url => !!url)
			.withMessage('Register URL is required')
			.when(m => m.type == 2 && m.allowUserImpersonation == true);

		this.ruleFor('loginCallbackUrl')
			.must(url => !!url)
			.withMessage('Login Callback URL is required')
			.must(url => !url || url.indexOf('{authorizationCode}') != -1)
			.withMessage('Login Callback URL requires a {authorizationCode} placeholder.')
			.when(m => m.type == 2 && m.allowUserImpersonation == true);
	}
}