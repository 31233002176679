import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApiService, GroupedCardBalanceOnline } from '@nstep-common/core';
import { BalanceHistoryModel } from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BalanceHistoryService {

	constructor(private apiService: ApiService) { }

	getCardBalanceHistory(balanceHistoryModel: BalanceHistoryModel): Observable<GroupedCardBalanceOnline[]> {
		const formatedDate = formatDate(balanceHistoryModel.date!, 'yyyy-MM-dd', 'en');
		return this.apiService.get([GroupedCardBalanceOnline], `balance-history/${balanceHistoryModel.cardNumber}/${formatedDate}`);
	}
}
