import { Component, Input } from '@angular/core';

@Component({
	selector: '[app-loading]',
	template: `
		<ng-content *ngIf="!enabled"></ng-content>
		
		<div *ngIf="enabled" class="ui basic segment" [style.height]="height" style="width: 100%">
			<div class="ui active inverted dimmer">
				<div class="ui {{size}} text blue slow double loader">{{text}}</div>
			</div>
		</div>`
})
export class LoadingComponent {
	@Input() size = 'medium';
	@Input() text = 'Loading';
	@Input() height = '250px';
	@Input() enabled = true;
}