import { FilterComparisonOperator, FilterDescriptor, FilterOperator, RoleDto, SortDescriptor, SortDirection } from "@nstep-common/core";
import { JsonProperty } from "@nstep-common/utils";

export enum ReportingViews {
	Main = 0,
	Report = 1
}

export enum GridEndpoints {
	Headquarters = 'headquarters',
	EntitlementCodes = 'entitle/codes',
	EntitlementTypes = 'entitle/types',
	EntitlementUnits = 'entitle/units',
	PosLocations = 'pos/locations',
	TransactionStates = 'transaction/states',
	TransactionTypes = 'transaction/types',
	RationedTypes = 'rationedtypes'
}

export enum FilterType {
	Basic = 'Basic',
	Bool = 'Bool',
	List = 'List'
}

export class ReportSettingsShortDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String })
	name!: string;

	@JsonProperty({ type: String, nullable: true })
	description!: string | null;

	@JsonProperty({ type: Number })
	nationId!: number;

	@JsonProperty({ type: String })
	nationName!: string;

	@JsonProperty({ type: Number })
	templateId!: number

	@JsonProperty({ type: String })
	templateName!: string;

	//cand este decomentat crapa fe-ul pe public
	//@JsonProperty({ type: RoleDto, array: true })
	roles: RoleDto[] = [];
}


export class FilterDto {
	operator!: FilterComparisonOperator;

	@JsonProperty({ type: String })
	value!: string;

	constructor(data: Partial<FilterDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ReportSettingsColumnDto {
	@JsonProperty({ type: Number })
	order!: number;

	@JsonProperty({ type: Boolean })
	show!: boolean;

	@JsonProperty({ type: String })
	name!: string

	sortDirection: SortDirection | null = null;

	@JsonProperty({ type: Number, nullable: true })
	sortOrder: number | null = null;

	filterOperator: FilterOperator | null = null;

	@JsonProperty({ type: FilterDto, array: true })
	filters: FilterDto[] = [];

	constructor(data: Partial<ReportSettingsColumnDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ReportSettingsDetailsDto {
	@JsonProperty({ type: Boolean })
	canSort!: boolean;

	@JsonProperty({ type: Boolean })
	canFilter!: boolean;

	@JsonProperty({ type: Boolean })
	canHideColumns!: boolean;

	@JsonProperty({ type: Boolean })
	canExport!: boolean;

	@JsonProperty({ type: Boolean })
	canReorderColumns!: boolean;

	@JsonProperty({ type: ReportSettingsColumnDto, array: true })
	columns: ReportSettingsColumnDto[] = [];

	constructor(data: Partial<ReportSettingsDetailsDto> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ReportSettingsDto extends ReportSettingsShortDto {
	@JsonProperty({ type: ReportSettingsDetailsDto })
	reportSettingsDetails!: ReportSettingsDetailsDto;
}

export class ReportColumn {
	orderNumber!: number;
	name!: string;
	key!: string;
	isDate: boolean = false;
	isShown: boolean = true;
	sortDirection: SortDirection | null = null;
	sortOrder: number | null = null;
	filterType: FilterType = FilterType.Basic;
	validOperators!: string[];
	agregateOperator: FilterOperator | null = null;
	assignedFilters: Filter[] = [];
	endpoint: string | null = null;

	allChecked: boolean | null = null;
	selectedItemsCount: number | null = null;
	hasFilterApplied: boolean = false;

	constructor(data: Partial<ReportColumn> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class Filter {
	operator!: FilterComparisonOperator;
	value!: string;
	text!: string;
	isChecked!: boolean;
	dateValue: Date | null = null;
	showInput: boolean = true;

	constructor(data: Partial<Filter> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ReportColumnData {
	reportColumns: ReportColumn[] = [];
	sortList: SortDescriptor[] = [];
	filterList: FilterDescriptor[] = [];

	constructor(data: Partial<ReportColumnData> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class CellData {
	key!: string;
	value!: any;

	constructor(data: Partial<CellData> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ReportBalanceDto {

	@JsonProperty({ type: String })
	cardNumber!: string;

	@JsonProperty({ type: Date })
	startDate!: Date;

	@JsonProperty({ type: Date })
	endDate!: Date;

	@JsonProperty({ type: Date })
	lastModifiedOn!: Date;

	@JsonProperty({ type: String })
	headquarter!: string;

	@JsonProperty({ type: String })
	entitlementCode!: string;

	@JsonProperty({ type: String })
	entitlementType!: string;

	@JsonProperty({ type: String, nullable: true })
	measurementUnit: string | null = null;

	@JsonProperty({ type: String, nullable: true })
	measurementUnitDescription: string | null = null;

	@JsonProperty({ type: String })
	rationedType!: string;

	@JsonProperty({ type: Number, nullable: true })
	balance: number | null = null;
}

export class ReportNRTransactionDto {

	@JsonProperty({ type: String, nullable: true })
	cardNumber: string | null = null;

	@JsonProperty({ type: Date })
	cardStartDate!: Date;

	@JsonProperty({ type: Date })
	cardEndDate!: Date;

	@JsonProperty({ type: String, nullable: true })
	operatorCardNumber: string | null = null;

	@JsonProperty({ type: String })
	posLocation!: string;

	@JsonProperty({ type: String })
	entitlementType!: string;

	@JsonProperty({ type: Boolean })
	validity!: boolean;

	@JsonProperty({ type: Date })
	timestamp!: Date;

	@JsonProperty({ type: String, nullable: true })
	deviceName: string | null = null;

	@JsonProperty({ type: String, nullable: true })
	deviceIdentifier: string | null = null;
}

export class ReportTransactionHistoryDto {

	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: Number, nullable: true })
	bulkId: number | null = null;

	@JsonProperty({ type: String })
	cardNumber!: string;

	@JsonProperty({ type: String })
	headquarter!: string;

	@JsonProperty({ type: String })
	entitlementCode!: string;

	@JsonProperty({ type: String })
	entitlementType!: string;

	@JsonProperty({ type: String, nullable: true })
	measurementUnit: string | null = null;

	@JsonProperty({ type: Number, nullable: true })
	transactionAmount: number | null = null;

	@JsonProperty({ type: Date, nullable: true })
	timestamp: Date | null = null;

	@JsonProperty({ type: String, nullable: true })
	posLocation: string | null = null;

	@JsonProperty({ type: String, nullable: true })
	operatorCardNumber: string | null = null;

	@JsonProperty({ type: String, nullable: true })
	deviceTransactionId: string | null = null;

	@JsonProperty({ type: String })
	transactionStatus!: string;

	@JsonProperty({ type: String })
	transactionType!: string;

	@JsonProperty({ type: Date, nullable: true })
	startDate: Date | null = null;

	@JsonProperty({ type: Date, nullable: true })
	expirationDate: Date | null = null;

	@JsonProperty({ type: String, nullable: true })
	notes: string | null = null;

	@JsonProperty({ type: Date, nullable: true })
	cardStartDate: Date | null = null;

	@JsonProperty({ type: Date, nullable: true })
	cardEndDate: Date | null = null;
}

export class ReportColumnSortDirection {
	name!: string;
	sortDirection: SortDirection | null = null;

	constructor(data: Partial<ReportColumnSortDirection> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class FilterChangeModel {
	col!: ReportColumn;
	clearColumnFilter: boolean = false;

	constructor(data: Partial<FilterChangeModel> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ReportSettings {
	templateId!: number;
	canSort!: boolean;
	canFilter!: boolean;
	canHideColumns!: boolean;
	canExport!: boolean;
	canReorderColumns!: boolean;

	constructor(data: Partial<ReportSettings> | null = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ReportSettingDefaultDataDto {
	nations: { [key: number]: string; } = {};
	reportTemplates: DefaultTemplateData[] = []
	roles: { [key: number]: string; } = {};
}

export class DefaultHeader {
	name!: string;
	filterType!: FilterType;
	validFilters: FilterComparisonOperator[] = [];
	isDate!: boolean;
	endpoint: string | null = null;
}

export class DefaultTemplateData {
	id!: number;
	name!: string;
	headers: DefaultHeader[] = [];
}