import { debounce, isArray, isEmpty, isObject } from 'lodash';

export function expandedLog(obj: any, groupLabel: string, isCollapsed = true) {
	const objType: string = isArray(obj) ? '[]' : typeof obj;
	const childType = isArray(obj) && obj.length ? typeof obj[0] : null;

	(isCollapsed ? console.groupCollapsed : console.group)(`${groupLabel} (${isArray(obj) ? childType : ''}${objType == 'array' ? '[]' : objType})`);

	if (isArray(obj)) {
		for (let i = 0; i < obj.length; i++) {
			expandedLog(obj[i], `[${i}]`);
		}
	}
	else if (isObject(obj)) {
		for (const key of Object.keys(obj)) {
			if ((obj as any)[key] && isObject((obj as any)[key]) && !isEmpty((obj as any)[key]) && (!childType || childType == 'object')) {
				expandedLog((obj as any)[key], key);
				continue;
			}

			let value = (obj as any)[key];
			if (isObject((obj as any)[key]) && isEmpty((obj as any)[key])) {
				value = '{}';
			}

			console.info(`%c ${key} (${typeof (obj as any)[key]}):`, 'font-weight: bold', typeof (obj as any)[key] == 'string' ? `"${value}"` : value);
		}
	}

	console.groupEnd();
}

export function Debounce(ms: number) {
	return function (target: any, key: any, descriptor: any) {
		const oldFunc = descriptor.value;
		const newFunc = debounce(oldFunc, ms, {
			leading: false,
			trailing: true
		});

		descriptor.value = function () {
			// eslint-disable-next-line prefer-rest-params
			return newFunc.apply(this, arguments as any);
		};
	};
}

export function flattenObject(obj: any, path = '', res: any = {}, parentArray = false) {
	for (let key in obj) {
		let propName = path;

		if (parentArray) {
			propName += `[${key}]`;
		}
		else if (path) {
			propName += `.${key}`;
		}
		else {
			propName = key;
		}

		if (isObject(obj[key])) {
			flattenObject(obj[key], propName, res, isArray(obj[key]));
		} else if (obj[key]) {
			res[propName] = obj[key];
		}
	}

	return res;
}

export function nameof<T>(key: keyof T): string {
	return key as string;
}
