import { Injectable } from '@angular/core';
import { ApiService, PagedQueryParameter, PagedResponse } from '@nstep-common/core';
import {
	AmisCardDto,
	OperatorCardDto,
	PosLocationAssociationDto,
	PosLocationDto,
	PosLocationInformationDto,
	PosOperatorDto, PosTypeAssociationDto,
	PosoperatorContactDto,
	ShortPosLocationInformationDto,
	SubRoleDto
} from '@nstep-internal/pages';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PosLocationService {

	constructor(private apiService: ApiService) { }

	getOperatorCards(query: string): Observable<OperatorCardDto[]> {
		return this.apiService.get([OperatorCardDto], `pos-locations/pos-operators/cards?query=${query}`);
	}

	getPosLocations(model: PagedQueryParameter): Observable<PagedResponse<PosLocationInformationDto>> {
		return this.apiService.getPage([PosLocationInformationDto], 'pos-locations', model);
	}

	getAllPosLocations(): Observable<ShortPosLocationInformationDto[]> {
		return this.apiService.get([ShortPosLocationInformationDto], 'pos-locations/all');
	}

	getLocationInfoAssociation(): Observable<ShortPosLocationInformationDto[]> {
		return this.apiService.get([ShortPosLocationInformationDto], 'pos-locations/info-association');
	}

	getHqAssociations(id: number): Observable<PosLocationAssociationDto[]> {
		return this.apiService.get([PosLocationAssociationDto], `pos-locations/${id}/hq-associations`);
	}

	clearHqAssociationsCache(id: number): void {
		this.apiService.invalidateCache(`pos-locations/${id}/hq-associations`);
	}

	getSubRole(id: number): Observable<SubRoleDto[]> {
		return this.apiService.get([SubRoleDto], `pos-locations/${id}/sub-role`);
	}

	clearSubRoleCache(id: number): void {
		this.apiService.invalidateCache(`pos-locations/${id}/sub-role`);
	}

	getPosTypeAssociations(id: number): Observable<PosTypeAssociationDto[]> {
		return this.apiService.get([PosTypeAssociationDto], `pos-locations/types/${id}`);
	}

	clearPosTypeAssociationsCache(id: number): void {
		this.apiService.invalidateCache(`pos-locations/types/${id}`);
	}

	getPosOperators(id: number): Observable<PosOperatorDto[]> {
		return this.apiService.get([PosOperatorDto], `pos-locations/${id}/pos-operators`);
	}

	clearPosOperatorsCache(id: number): void {
		this.apiService.invalidateCache(`pos-locations/${id}/pos-operators`);
	}

	getAmisCards(query: string): Observable<AmisCardDto[]> {
		return this.apiService.get([AmisCardDto], `pos-locations/amis-cards/${query}`);
	}

	savePosLocation(model: PosLocationInformationDto): Observable<PosLocationInformationDto> {
		return this.apiService.post(PosLocationInformationDto, 'pos-locations', model);
	}

	updatePosLocation(model: PosLocationInformationDto): Observable<PosLocationInformationDto> {
		return this.apiService.put(PosLocationInformationDto, `pos-locations/${model.id}`, model);
	}

	saveSubrole(posLocationInformationId: number, subroles: SubRoleDto[]): Observable<PosLocationInformationDto> {
		return this.apiService.post(PosLocationInformationDto, `pos-locations/${posLocationInformationId}/sub-role`, subroles);
	}

	assignPosLocationToHeadquarter(posLocationId: number, headquarterId: number, startDate: string): Observable<PosLocationDto> {
		return this.apiService.post(PosLocationDto, `pos-locations/${posLocationId}/hq-associations/${headquarterId}/${startDate}`);
	}

	assignTypeToPOSLocation(posLocationId: number, headquarterId: number, model: any): Observable<any> {
		return this.apiService.postNoContent(`pos-locations/types/${posLocationId}/${headquarterId}`, model);
	}

	revokeTypeFromPOSLocation(posLocationId: number, entitlementTypeId: number): Observable<any> {
		return this.apiService.deleteNoContent(`pos-locations/types/${posLocationId}/${entitlementTypeId}`);
	}

	updatePosLocationHqAssociation(model: PosLocationDto): Observable<PosLocationDto> {
		return this.apiService.put(PosLocationDto, 'pos-locations/location', model);
	}

	enrollPosOperator(model: PosOperatorDto): Observable<PosOperatorDto> {
		return this.apiService.post(PosOperatorDto, 'pos-locations/operator', model);
	}

	updatePosOperator(model: PosOperatorDto): Observable<PosOperatorDto> {
		return this.apiService.put(PosOperatorDto, 'pos-locations/operator', model);
	}

	assignPosOperatorContact(posOperatorId: number, model: PosoperatorContactDto): Observable<PosoperatorContactDto> {
		return this.apiService.post(PosoperatorContactDto, `pos-locations/${posOperatorId}/contact`, model);
	}

	updatePosOperatorContact(posOperatorId: number, model: PosoperatorContactDto): Observable<PosoperatorContactDto> {
		return this.apiService.put(PosoperatorContactDto, `pos-locations/${posOperatorId}/contact`, model);
	}

	deletePosOperatorContact(posOperatorId: number): Observable<any> {
		return this.apiService.deleteNoContent(`pos-locations/${posOperatorId}/contact`);
	}

	disablePosLocation(id: number, endDate: string): Observable<any> {
		return this.apiService.deleteNoContent(`pos-locations/${id}/${endDate}`);
	}

	clearPosLocationsCache(): void {
		this.apiService.invalidateCache('pos-locations');
	}
}
