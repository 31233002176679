import { Routes } from '@angular/router';
import { authGuard } from '@nstep-common/core';
import { LayoutComponent, routePermissions } from '@nstep-internal/shared';

import {
	AdminAdjustmentsComponent,
	ArchiveComponent,
	BalanceHistoryComponent,
	EntitlementCodesComponent,
	EntitlementTypesComponent,
	EntitlementUnitsComponent,
	ExportComponent,
	ImportHistoryComponent,
	LoginComponent,
	NonRationedTransactionsComponent,
	PosDevicesComponent,
	PosLocationsComponent,
	RolesComponent,
	EntitlementMatrixComponent,
	UpdateTransactionsComponent,
	UsersComponent,
	ClientsComponent,
	ReportingComponent,
	ReportsManagementComponent
} from '@nstep-internal/pages';

export const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
	},
	{
		path: '',
		component: LayoutComponent,
		canActivate: [authGuard(routePermissions)],
		children: [
			{
				path: 'entitlements/codes',
				canActivate: [authGuard(routePermissions)],
				component: EntitlementCodesComponent
			},
			{
				path: 'entitlements/types',
				canActivate: [authGuard(routePermissions)],
				component: EntitlementTypesComponent
			},
			{
				path: 'entitlements/units',
				canActivate: [authGuard(routePermissions)],
				component: EntitlementUnitsComponent
			},
			{
				path: 'entitlements/matrix',
				canActivate: [authGuard(routePermissions)],
				component: EntitlementMatrixComponent
			},
			{
				path: 'reports/non-rationed-transactions',
				canActivate: [authGuard(routePermissions)],
				component: NonRationedTransactionsComponent
			},
			{
				path: 'reports/reporting',
				canActivate: [authGuard(routePermissions)],
				component: ReportingComponent
			},
			{
				path: 'reports/management',
				canActivate: [authGuard(routePermissions)],
				component: ReportsManagementComponent
			},
			{
				path: 'pos/locations',
				canActivate: [authGuard(routePermissions)],
				component: PosLocationsComponent
			},
			{
				path: 'pos/devices',
				canActivate: [authGuard(routePermissions)],
				component: PosDevicesComponent
			},
			{
				path: 'membership/users',
				canActivate: [authGuard(routePermissions)],
				component: UsersComponent
			},
			{
				path: 'membership/clients',
				canActivate: [authGuard(routePermissions)],
				component: ClientsComponent
			},
			{
				path: 'membership/roles',
				canActivate: [authGuard(routePermissions)],
				component: RolesComponent
			},
			{
				path: 'rations/admin-adjustments',
				canActivate: [authGuard(routePermissions)],
				component: AdminAdjustmentsComponent
			},
			{
				path: 'rations/balance-history',
				canActivate: [authGuard(routePermissions)],
				component: BalanceHistoryComponent
			},
			{
				path: 'system/import',
				canActivate: [authGuard(routePermissions)],
				component: ImportHistoryComponent
			},
			{
				path: 'system/export',
				canActivate: [authGuard(routePermissions)],
				component: ExportComponent
			},
			{
				path: 'system/update',
				canActivate: [authGuard(routePermissions)],
				component: UpdateTransactionsComponent
			},
			{
				path: 'system/archive',
				canActivate: [authGuard(routePermissions)],
				component: ArchiveComponent
			},
			{
				path: '**',
				redirectTo: ''
			}
		]
	}
];