<div class="ui stackable inverted blue borderless menu"
	style="border-radius: 0; margin-bottom: 35px;">
	<div class="ui fluid container"
		[style.padding-left]="!isLargeMonitor ? (isRegularMonitor ? '100px' : '20px') : '200px'"
		[style.padding-right]="!isLargeMonitor ? (isRegularMonitor ? '100px' : '20px') : '200px'">
		<div class="item">
			NATO System for Tracking Entitlements & Privileges

			<ng-content select="[title]"></ng-content>
		</div>

		<div class="ui simple dropdown right floated item">
			<i class="user outline icon"></i>{{currentUser}} <i class="dropdown icon"></i>
			<div class="menu">
				<button class="item"
					(click)="settingsModal.toggle()">
					<i class="cog icon"></i>Settings
				</button>
				<button class="item"
					(click)="logout()">
					<i class="door open icon"></i>Log Out
				</button>
			</div>
		</div>
	</div>
</div>

<div class="ui fluid container"
	[style.padding-left]="!isLargeMonitor ? (isRegularMonitor ? '100px' : '20px') : '200px'"
	[style.padding-right]="!isLargeMonitor ? (isRegularMonitor ? '100px' : '20px') : '200px'"
	style="padding-bottom: 50px;">

	<div class="ui two column stackable padded grid"
		style="box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);">
		<div class="left attached stretched column"
			style="width: 250px; padding: 0">
			<div class="ui left attached horizontally fitted segment">
				<img class="ui small image"
					src="assets/images/logo.png"
					style="width: 130px; display: block; margin: 10px auto" />

				<ng-content select="[menu]"></ng-content>

				<br />
			</div>
		</div>

		<div class="right attached stretched column"
			style="width: calc(100% - 250px); padding: 0">
			<div class="ui right attached padded segment">
				<ng-content select="[content]"></ng-content>
			</div>
		</div>
	</div>
</div>

<sm-modal #settingsModal [isMini]="askFor2F"
	(closeEvent)="closeSaveSettings()"
	[isLoading]="waitingForServer">
	<ng-container header>
		<i class="user cog icon"></i> User Settings
	</ng-container>
	<ng-container content>
		<div class="ui equal width form">
			<ng-container *ngIf="!askFor2F">
				<div class="fields">
					<div class="field" app-field [error]="validations | path: 'email'">
						<label>Email Address</label>
						<input type="text" [(ngModel)]="settings.email">
					</div>

					<div class="field" app-field [error]="validations | path: 'telephone'">
						<label>Telephone</label>
						<input type="text" [(ngModel)]="settings.telephone">
					</div>
				</div>

				<div class="fields">
					<div class="field" app-field [error]="validations | path: 'newPassword'">
						<label>New Password</label>
						<input type="text"
							style="-text-security:disc; -webkit-text-security:disc;"
							autoComplete="off"
							name="newPassword"
							[(ngModel)]="settings.newPassword">
					</div>

					<div class="field" app-field [error]="validations | path: 'repeatPassword'">
						<label>Repeat Password</label>
						<input type="text"
							style="-text-security:disc; -webkit-text-security:disc;"
							autoComplete="off"
							name="repeatPassword"
							[(ngModel)]="settings.repeatPassword">
					</div>
				</div>

				<div class="ui attached segment">
					<h4 class="ui header">
						<i class="user shield icon"></i>
						<div class="content">
							Two Factor Authentication
							<div class="sub header">
								<div class="ui small toggle checkbox">
									<input type="checkbox"
										[(ngModel)]="settings.isTwoFactorEnabled" />
									<label>
										<strong class="ui success text">
											<span class="ui success text" *ngIf="settings.isTwoFactorEnabled">Enabled</span>
											<span class="ui error text" *ngIf="!settings.isTwoFactorEnabled">Disabled</span>
										</strong>
									</label>
								</div>
							</div>
						</div>
					</h4>
				</div>

				<div class="ui attached segment"
					*ngIf="settings.isTwoFactorEnabled">
					<h4 class="ui header">
						<i class="alternate big mobile icon"></i>
						<div class="content">
							Authenticator
							<div class="sub header">
								<p *ngIf="!settings.hasAuthenticator">
									No authenticator has been registered for your NSTEP account.<br />

									<button class="ui primary tertiary button"
										style="padding: 0 !important;"
										(click)="resetAuthenticator()">
										Click here
									</button> to add one.
								</p>

								<p *ngIf="settings.hasAuthenticator">
									There is an authenticator already registered for your NSTEP account, you can find its details below.<br />

									<button class="ui primary tertiary button"
										style="padding: 0 !important;"
										(click)="resetAuthenticator()">
										Click here
									</button> if you want to reset it.
								</p>
							</div>
						</div>
					</h4>
				</div>

				<div class="ui attached segment"
					*ngIf="settings.isTwoFactorEnabled && settings.hasAuthenticator">
					<p>We recommend installing "Google Authenticator" and following these steps:</p>
				</div>

				<div class="ui attached segment"
					*ngIf="settings.isTwoFactorEnabled && settings.hasAuthenticator">
					<div class="ui two column stackable grid">
						<div class="ui vertical divider">Or</div>
						<div class="row">
							<div class="column">
								<ol>
									<li>Open "Google Authenticator" app.</li>
									<li>Authenticate in the app if desired or necessary.</li>
									<li>Click the "+" button in the right corner.</li>
									<li>Select "Scan a QR code".</li>
									<li>Point the camera at the QR image below.</li>
								</ol>

								<div style="text-align: center;">
									<qr-code [value]="settings.authenticatorUri"
										size="300"
										errorCorrectionLevel="M" />
								</div>
							</div>
							<div class="column">
								<ol>
									<li>Open "Google Authenticator" app.</li>
									<li>Authenticate in the app if desired or necessary.</li>
									<li>Click the "+" button in the right corner.</li>
									<li>Select "Enter a setup key".</li>
									<li>Enter "Account name", e.g. "NSTEP".</li>
									<li>Select the field "Key" and input the values below.</li>
									<li>Finish by pressing "Add".</li>
								</ol>

								<br />

								<div style="text-align: center;">
									<div class="ui big labels">
										<div class="ui label" *ngFor="let part of settings.sharedKey">
											<strong>{{part}}</strong>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="askFor2F">
				<div class="field" app-field [error]="validations | path: 'authenticatorCode'">
					<label>
						Please enter code from authenticator:<br />
					</label>
					<input type="text" [(ngModel)]="settings.authenticatorCode" placeholder="xxxxxx">
				</div>
			</ng-container>
		</div>

		<app-error-message [errors]="errors"></app-error-message>
	</ng-container>

	<ng-container actions>
		<button class="ui basic right labeled icon button"
			(click)="closeSaveSettings()">
			Cancel
			<i class="cancel icon"></i>
		</button>
		<button class="ui primary right labeled icon button"
			[disabled]="!isValid"
			(click)="saveSettings()">
			Save
			<i class="save icon"></i>
		</button>
	</ng-container>

</sm-modal>