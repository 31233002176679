import { JsonProperty } from "@nstep-common/utils";

export class RoleModel {
	id!: number;
	name!: string;
	description!: string | null;
	isDisabled!: boolean;
	claims: string[] = [];

	constructor(data?: Partial<RoleModel> | null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class RoleWithClaimsDto {
	@JsonProperty({ type: Number })
	id!: number;

	@JsonProperty({ type: String, nullable: true })
	name: string | null = null;

	@JsonProperty({ type: String, nullable: true })
	description: string | null = null;

	@JsonProperty({ type: Boolean })
	isDisabled!: boolean;

	//@JsonProperty()
	claims: string[] = [];

	constructor(data?: Partial<RoleWithClaimsDto> | null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}