import { Injectable } from '@angular/core';
import { ApiService, PasswordSettingsModel } from "@nstep-common/core";
import { Any } from '@nstep-common/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SettingsService {
	constructor(private apiService: ApiService) { }

	getServerSettings(): Observable<Any> {
		return this.apiService.get(Any, 'settings/server');
	}

	getPasswordSettings(): Observable<PasswordSettingsModel> {
		return this.apiService.get(PasswordSettingsModel, 'settings/password');
	}
}