import { Injectable } from '@angular/core';
import {
	ApiService,
	ExportDataRequest,
	PagedQueryParameter,
	PagedResponse,
	ReportBalanceDto,
	ReportNRTransactionDto,
	ReportTransactionHistoryDto
} from '@nstep-common/core';
import { Any } from '@nstep-common/utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ReportingService {
	reportTemplateDataType: { [key: number]: { new(): any } } = {
		1: ReportBalanceDto,
		2: ReportNRTransactionDto,
		3: ReportTransactionHistoryDto
	};

	constructor(private apiService: ApiService) { }

	getReportTemplate(templateId: number, model: PagedQueryParameter): Observable<PagedResponse<any>> {
		const mapType = this.reportTemplateDataType[templateId];
		return this.apiService.postPage([mapType], `reporting/${templateId}`, model);
	}

	exportReportTemplate(reportId: number, model: ExportDataRequest): Observable<any> {
		return this.apiService.post(Any, `reporting/${reportId}/export`, model, { observe: 'response', responseType: 'blob' });
	}
}
